// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DsYZ0Wo_HlTAOk136ZW9{padding-right:81px}.DsYZ0Wo_HlTAOk136ZW9 .doblde14DSFXj9tOELZN{align-items:center;display:flex;gap:15px;padding-bottom:12px}.DsYZ0Wo_HlTAOk136ZW9 ._N7s6ybg8xgklcMtwdbo{width:400px}.DsYZ0Wo_HlTAOk136ZW9 .Eq5c6MrvPqk_iLPk0TyM{margin-bottom:32px;padding-left:12px;width:388px}.DsYZ0Wo_HlTAOk136ZW9 .Eq5c6MrvPqk_iLPk0TyM .N4ZOTaokmp2dyPFD7uii{margin-bottom:12px}.DsYZ0Wo_HlTAOk136ZW9 .Eq5c6MrvPqk_iLPk0TyM .N4ZOTaokmp2dyPFD7uii::marker{color:var(--color-white)}.DsYZ0Wo_HlTAOk136ZW9 .U3xX_nhYnlmMs3uRpVqm{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.DsYZ0Wo_HlTAOk136ZW9 .iJ_4mo9H3ijOkUf1RSJz{margin-bottom:16px}.DsYZ0Wo_HlTAOk136ZW9 .uVNDGXSe6hC6ANuX0N1r{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/magic-quad-section/magic-quad-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".magicRoot {\n  padding-right: 81px;\n\n  & .magicHeader {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .magicDescription {\n    width: 400px;\n  }\n\n  & .magicList {\n    margin-bottom: 32px;\n    padding-left: 12px;\n    width: 388px;\n\n    & .magicListItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .magicDivider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n\n  & .magicLearnMore {\n    margin-bottom: 16px;\n  }\n\n  & .magicLinkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magicRoot": `DsYZ0Wo_HlTAOk136ZW9`,
	"magicHeader": `doblde14DSFXj9tOELZN`,
	"magicDescription": `_N7s6ybg8xgklcMtwdbo`,
	"magicList": `Eq5c6MrvPqk_iLPk0TyM`,
	"magicListItem": `N4ZOTaokmp2dyPFD7uii`,
	"magicDivider": `U3xX_nhYnlmMs3uRpVqm`,
	"magicLearnMore": `iJ_4mo9H3ijOkUf1RSJz`,
	"magicLinkText": `uVNDGXSe6hC6ANuX0N1r`
};
export default ___CSS_LOADER_EXPORT___;
