// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hRBuSWIh3CLjp3Qh_NGf .GPMGFk0O1wn14SLnIMHu{align-items:center;display:flex;gap:15px;padding-bottom:12px}.hRBuSWIh3CLjp3Qh_NGf .cOz8uQsYnBjZFfZHW8qW{padding-bottom:32px;width:400px}.hRBuSWIh3CLjp3Qh_NGf .kL2_uR1ES8CaMH7ZEqAf{background-color:var(--color-white);height:1px;margin-bottom:12px;opacity:.2}.hRBuSWIh3CLjp3Qh_NGf ._Gi_OALaXFyP_w8TjLaA{margin-bottom:16px}.hRBuSWIh3CLjp3Qh_NGf .n32APvYgg6jAPtaumzDn{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/peer-insights-section/peer-insights-section.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,mBAAA,CACA,WAAA,CAGF,4CACE,mCAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".root {\n  & .header {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .description {\n    padding-bottom: 32px;\n    width: 400px;\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 1px;\n    margin-bottom: 12px;\n    opacity: 0.2;\n  }\n\n  & .learnMore {\n    margin-bottom: 16px;\n  }\n\n  & .linkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `hRBuSWIh3CLjp3Qh_NGf`,
	"header": `GPMGFk0O1wn14SLnIMHu`,
	"description": `cOz8uQsYnBjZFfZHW8qW`,
	"divider": `kL2_uR1ES8CaMH7ZEqAf`,
	"learnMore": `_Gi_OALaXFyP_w8TjLaA`,
	"linkText": `n32APvYgg6jAPtaumzDn`
};
export default ___CSS_LOADER_EXPORT___;
