import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { defaultToZero, InitProdState, UUID } from "gx-npm-lib";
import { TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import ChecklistSVG from "../../../../assets/images/overview/planning/checklistV2.svg";
import RequirementsSVG from "../../../../assets/images/overview/planning/requirementsV2.svg";
import VendorsSVG from "../../../../assets/images/overview/planning/vendorsV2.svg";
import { GCOM_3694__updateDefaultEvaluationName } from "../../../../lib/feature-flags";
import { OverviewContext } from "../../overview.context";
import NumberOfNumberV2 from "./number-of-number-v2.component";
import PlanningStatusCardV2 from "./planning-status-card-v2.component";
import styles from "./set-up-card-v2.styles";

const useStyles = makeStyles(() => styles);
const PlanningStatusViewV2Component = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { checklistCompletedCount, checklistCount, requirementCount, products } = useContext(OverviewContext);
  const isDefaultEvalNameFFOn = useFeatureFlag(GCOM_3694__updateDefaultEvaluationName);
  const addedProductStates = [InitProdState.AWARDED, InitProdState.IN_EVALUATION];
  const productsAdded = products.filter((product) => addedProductStates.includes(product.state)).length;

  const handleNavigation = (name: string) => {
    navigateToUrl(`/s/evaluation/${initiativeId}/${name}`);
  };
  return (
    <div>
      <div className={classNames("gx-planning-view-root", classes.statusViewRoot)}>
        <PlanningStatusCardV2
          btnTitle={t("GO TO CHECKLIST")}
          cardName="checklist"
          displayNumber={<NumberOfNumberV2 numOne={checklistCompletedCount} numTwo={checklistCount} />}
          imageSrc={ChecklistSVG}
          onNavigation={() => handleNavigation("checklist")}
          subTitle={t("Items completed")}
          title={t("Checklist")}
        />
        <PlanningStatusCardV2
          btnTitle={t("GO TO REQUIREMENTS")}
          cardName="requirements"
          displayNumber={
            <TypographyComponent styling={"h4"} boldness={"medium"} color={"carbon"}>
              {defaultToZero(requirementCount)}
            </TypographyComponent>
          }
          imageSrc={RequirementsSVG}
          onNavigation={() => handleNavigation("requirements")}
          subTitle={t("Requirements")}
          title={t("Requirements")}
        />
        <PlanningStatusCardV2
          btnTitle={t("GO TO VENDOR LIST")}
          cardName="vendors"
          displayNumber={<NumberOfNumberV2 numOne={productsAdded} numTwo={products.length} />}
          imageSrc={VendorsSVG}
          onNavigation={() => handleNavigation("products")}
          subTitle={isDefaultEvalNameFFOn ? t("Vendors added to evaluation") : t("Vendors added to eval")}
          title={t("Vendors")}
        />
      </div>
    </div>
  );
};
export default PlanningStatusViewV2Component;
