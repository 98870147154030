import { colorPalette } from "gx-npm-common-styles";
import { MarketGuideIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import { TextLink } from "../../../textLink";
import { TypographyComponent } from "../../../typography/typography.component";
import styles from "./market-guide-section.styles.module.scss";

type MarketGuideSectionProps = { resId: number; templateName: string };
const MarketGuideSectionComponent = ({ resId = -1, templateName = "" }: MarketGuideSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.marketRoot}>
      <TypographyComponent color="berry" rootClassName={styles.marketHeader} styling="h5">
        <MarketGuideIcon fillPath={colorPalette.interactions.berry.hex} />
        {t("Market Guide")}
      </TypographyComponent>
      <TypographyComponent color="quartz" rootClassName={styles.marketParagraph} styling="p2">
        {t(
          "Market Guides (MGs) deliver a clear definition and framing of the market, market dynamics and an " +
            "overview of market participants. They deliver leaders who are considering an investment with actionable " +
            "insights on use cases spurring the market, offerings and market direction."
        )}
      </TypographyComponent>
      <div className={styles.marketDivider} />
      <TypographyComponent color="stone" element="p" rootClassName={styles.marketLearnMore}>
        {t("Learn more")}
      </TypographyComponent>
      <div>
        <TextLink
          href={`https://www.gartner.com/document/${resId}?ref=buysmartresearch`}
          rootClassName={styles.marketTextLink}
          target="_blank"
          text={`${t("Market Guide for")} ${templateName}`}
          variant="dark"
        />
      </div>
    </div>
  );
};

export default MarketGuideSectionComponent;
