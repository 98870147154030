import { RibbonBulbIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "../../../typography/typography.component";
import styles from "./slide-in-header.styles.module.scss";

const SlideInHeaderComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <RibbonBulbIcon />
      <TypographyComponent color="white" styling="p4">
        {t("Gartner research behind this market")}
      </TypographyComponent>
    </div>
  );
};

export default SlideInHeaderComponent;
