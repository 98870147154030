import React, { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import { ScoringMode } from "gx-npm-lib";

import TableCarouselHeaderRowCol from "../../../../../../ui/table-carousel-v2/header/table-carousel-header-row-col.component";
import ScorecardRequirementItems from "./scorecard-requirement-items/scorecard-requirement-items.component";
import { Requirement, Product } from "../../../../../../app.types";
import styles from "./scorecard-requirements.module.scss";
import ScorecardRequirement from "./scorecard-requirement/scorecard-requirement.component";

type ScorecardRequirementsProps = {
  requirements: Requirement[];
  onAvatarClick: () => void;
  isOwner: boolean;
  scoringMode: ScoringMode;
  requirementsOpenIdxList: number[];
  displayedProducts: Product[];
  onClickExpandButton: (index: number) => void;
  onDockedCategory: (requirementIdx: number) => void;
};

const ScorecardRequirementsComponent = ({
  requirements,
  onAvatarClick,
  isOwner,
  scoringMode,
  displayedProducts = [],
  requirementsOpenIdxList,
  onClickExpandButton,
  onDockedCategory,
}: ScorecardRequirementsProps) => {
  const rowRefs = useRef<(HTMLDivElement | null)[]>([]);
  const buffer = 150;
  const visibilityDelayMs = 900;
  const scrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const displayedProductCount = displayedProducts.length;
  const handleScroll = useCallback(() => {
    onDockedCategory(-1);

    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }

    scrollTimeout.current = setTimeout(() => {
      let closestIdx = -1;
      let closestDistance = Infinity;

      // Iterate over each row to find the one closest to the top of the window within a specified buffer zone.
      // If a row is found within the buffer, update the closest index and distance.
      // This helps determine which requirement should be considered "docked" based on scroll position.
      rowRefs.current.forEach((row, index) => {
        if (row) {
          const { top } = row.getBoundingClientRect();
          const distance = Math.abs(top - buffer);

          if (distance < closestDistance && top <= buffer) {
            closestDistance = distance;
            closestIdx = index;
          }
        }
      });

      if (closestIdx !== -1) {
        onDockedCategory(closestIdx);
      }
    }, visibilityDelayMs);
  }, [buffer, onDockedCategory]);

  useEffect(() => {
    const handleScrollWithRAF = () => {
      requestAnimationFrame(handleScroll);
    };
    window.addEventListener("scroll", handleScrollWithRAF, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScrollWithRAF);
    };
  }, [handleScroll]);

  return (
    <>
      {requirements.map((requirement, index, arr) => {
        const { id, items } = requirement;

        return (
          <TableCarouselHeaderRowCol
            key={id.toString() || index.toString()}
            rootClass={classNames(
              "gx-req-root-head-row",
              styles.reqRootHeadRow,
              styles.reqScoringAssignment,
              arr.length - 1 === index && "gx-last-req-root"
            )}
          >
            <div>
              <div ref={(el) => (rowRefs.current[index] = el)}>
                <ScorecardRequirement
                  requirement={requirement}
                  requirements={requirements}
                  requirementsOpenIdxList={requirementsOpenIdxList}
                  index={index}
                  isOwner={isOwner}
                  onClickExpandButton={onClickExpandButton}
                  scoringMode={scoringMode}
                  onAvatarClick={onAvatarClick}
                  displayedProductsCount={displayedProductCount}
                />
              </div>
              <ScorecardRequirementItems
                index={index}
                reqItems={items}
                requirementsOpenIdxList={requirementsOpenIdxList}
                displayProductsCount={displayedProductCount}
              />
              {index === 0 && displayedProductCount === 0 && <div className="right-border-top" />}
              {index === requirements.length - 1 && displayedProductCount === 0 && (
                <div className="right-border-bottom" />
              )}
            </div>
          </TableCarouselHeaderRowCol>
        );
      })}
    </>
  );
};

export default ScorecardRequirementsComponent;
