import { getAsyncRequest, UUID } from "gx-npm-lib";
import { createStateSubscription, destroyStateSubscription } from "gx-npm-messenger-util";
import { SlideInOverlay, SnackbarBanner, useFeatureFlag } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import TeamManagement from "./sections/team-management.component";
import { TeamManagementAppContext } from "./app.context";
import { TeamMembersResponse } from "./app.types";
import { TeamManagementTabs } from "./lib";
import { GCOM_3694__updateDefaultEvaluationName } from "./lib/feature-flags";
import styles from "./app.styles.module.scss";

const App = () => {
  const { t } = useTranslation();
  const {
    errorMessage,
    initiativeId,
    isDrawerOpen,
    isErrorSnackBarOpen,
    selectedTab,
    setDrawerOpen,
    setErrorMessage,
    setIsErrorSnackBarOpen,
    setLoading,
    setInitiativeId,
    setSelectedTab,
    setTeamMembers,
    setHasSelectedScoringAssignment,
  } = useContext(TeamManagementAppContext);
  const { initiativeId: initiativeIdFromParams } = useParams();
  const isDefaultEvalNameFFOn = useFeatureFlag(GCOM_3694__updateDefaultEvaluationName);
  const [sourcePage, setSourcePage] = useState<string>("EVAL");
  const [sourcePageV2, setSourcePageV2] = useState<string>(t("EVALUATION") || "");

  useEffect(() => {
    const handleStateUpdate = (updatedState: { sourcePage: string; target: TeamManagementTabs }) => {
      if (!updatedState) {
        return;
      }
      setSelectedTab(updatedState.target ? updatedState.target : TeamManagementTabs.TEAM_MANAGEMENT);
      setSourcePage(updatedState.sourcePage);
      setSourcePageV2(updatedState.sourcePage);
    };

    const subscriptionTemplate = createStateSubscription("EVAL_HEADER_SOURCE_PAGE", handleStateUpdate);
    return () => {
      destroyStateSubscription(subscriptionTemplate);
    };
  }, [setSelectedTab]);

  useEffect(() => {
    setInitiativeId(initiativeIdFromParams as UUID);
  }, [initiativeIdFromParams, setInitiativeId]);

  useEffect(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  useEffect(() => {
    if (!initiativeId) {
      setLoading(false);
      return;
    }
    if (selectedTab !== TeamManagementTabs.TEAM_MANAGEMENT) {
      return;
    }

    (async () => {
      setLoading(true);
      try {
        const url = `/api/v3/initiatives/${initiativeId}/team`;
        const response: TeamMembersResponse = await getAsyncRequest(url);
        if (response.status !== 200 || !Array.isArray(response.data?.data?.teamMembers)) {
          throw new Error(response.data?.systemMessage?.message);
        }
        setTeamMembers(response.data.data.teamMembers);
        setHasSelectedScoringAssignment(response.data.data.hasSelectedScoringAssignment);
      } catch (err) {
        setErrorMessage("ERROR");
        setIsErrorSnackBarOpen(true);
      }
      setLoading(false);
    })();
  }, [
    initiativeId,
    selectedTab,
    setErrorMessage,
    setHasSelectedScoringAssignment,
    setIsErrorSnackBarOpen,
    setLoading,
    setTeamMembers,
  ]);

  const onClose = () => {
    setDrawerOpen(false);
    const pathName = window.location.pathname;
    const prevPath = pathName.substring(0, pathName.lastIndexOf("/"));
    setTimeout(() => navigateToUrl(prevPath), 1000);
  };

  return (
    <SlideInOverlay
      isOverlayNavBar={true}
      rootClassName={styles.secondarySlideInOverlay}
      onClose={onClose}
      isOpen={isDrawerOpen}
      textBackBtn={isDefaultEvalNameFFOn ? ` ${sourcePageV2}` : sourcePage}
    >
      <TeamManagement />
      <SnackbarBanner
        isDefaultErrorMessage={errorMessage === "ERROR"}
        isOpen={isErrorSnackBarOpen}
        message={errorMessage}
        setIsOpen={() => setIsErrorSnackBarOpen(false)}
        type="ERROR"
      />
    </SlideInOverlay>
  );
};

export default App;
