// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vl4MqgEfxt7dpfBHpnhg{padding-right:81px}.vl4MqgEfxt7dpfBHpnhg .jXssGYjb2QWRV2l8_VtQ{align-items:center;display:flex;gap:15px;padding-bottom:12px}.vl4MqgEfxt7dpfBHpnhg .jMNpLSVM19Arb8MbVvQS{width:400px}.vl4MqgEfxt7dpfBHpnhg .m3BzCkWvuGIsnH7PRasG{margin-bottom:32px;padding-left:12px;width:388px}.vl4MqgEfxt7dpfBHpnhg .m3BzCkWvuGIsnH7PRasG .WKMT1JYd_bgIg5nVWmsW{margin-bottom:12px}.vl4MqgEfxt7dpfBHpnhg .m3BzCkWvuGIsnH7PRasG .WKMT1JYd_bgIg5nVWmsW::marker{color:var(--color-white)}.vl4MqgEfxt7dpfBHpnhg .R4uj4rWUiE7EPpVB8H_Q{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.vl4MqgEfxt7dpfBHpnhg .UX2karQzbxe8L0IZnl1j{margin-bottom:16px}.vl4MqgEfxt7dpfBHpnhg .HiJokAVYpS55teW1ElDZ{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/magic-quad-section/magic-quad-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".magicRoot {\n  padding-right: 81px;\n\n  & .magicHeader {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .magicDescription {\n    width: 400px;\n  }\n\n  & .magicList {\n    margin-bottom: 32px;\n    padding-left: 12px;\n    width: 388px;\n\n    & .magicListItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .magicDivider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n\n  & .magicLearnMore {\n    margin-bottom: 16px;\n  }\n\n  & .magicLinkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magicRoot": `vl4MqgEfxt7dpfBHpnhg`,
	"magicHeader": `jXssGYjb2QWRV2l8_VtQ`,
	"magicDescription": `jMNpLSVM19Arb8MbVvQS`,
	"magicList": `m3BzCkWvuGIsnH7PRasG`,
	"magicListItem": `WKMT1JYd_bgIg5nVWmsW`,
	"magicDivider": `R4uj4rWUiE7EPpVB8H_Q`,
	"magicLearnMore": `UX2karQzbxe8L0IZnl1j`,
	"magicLinkText": `HiJokAVYpS55teW1ElDZ`
};
export default ___CSS_LOADER_EXPORT___;
