import { Collapse, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { getAsyncRequest, InitState, useUserState } from "gx-npm-lib";
import React, { Fragment, useContext, useEffect, useState } from "react";
import BrandingHeader from "./ui/branding-header/branding-header.component";
import ClosedSurveyHeader from "./ui/closed-survey-header/closed-survey-header.component";
import Snackbar from "./ui/snack-bar/snack-bar.component";
import SurveyHeader from "./ui/survey-header/survey-header.component";
import { SurveyHeaderContextProvider } from "./ui/survey-header/survey-header.context";
import TabMenu from "./ui/tab-menu/tab-menu.component";
import { AppContext } from "./app.context";
import styles from "./app.styles";
import { SurveyPreviewData, SurveyPreviewHeaderResponseDTO } from "./app.types";
import PreviewError from "./ui/preview-error/preview-error.component";
import PreviewBanner from "./ui/preview-banner/preview-banner.component";
import { NewAppPaths } from "./app.constants";
import OnboardingDialog from "./ui/onboarding-dialog/onboarding-dialog.component";

const useStyles = makeStyles(() => styles);
const LOCAL_STORE_PREVIEW_KEY = "survey_setup";

const AppPreview = ({ isSetupMode }: { isSetupMode: boolean }) => {
  const classes = useStyles();
  const userState = useUserState();

  const {
    isScrolling,
    initId,
    initState,
    setInitState,
    setIsHeaderDataLoaded,
    setIsScrolling,
    setIsSnackbarOpen,
    setSurveyId,
    setSurveyHasRequestedDocs,
    setSurveyRequestedByCompanyName,
    setSurveyRequestedByEmail,
    setSurveyRequestedByUserName,
    setSurveyStatus,
    setIsPreviewMode,
  } = useContext(AppContext);

  const [isSurveyError, setIsSurveyError] = useState(false);

  useEffect(() => {
    setIsPreviewMode(true);
    const handleScroll = () => {
      setIsScrolling(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setIsPreviewMode, setIsScrolling]);

  useEffect(() => {
    // Preview launched from inside setup

    if (!isSetupMode) {
      return;
    }
    const initialQuestionnaireDataForHeader = localStorage.getItem(LOCAL_STORE_PREVIEW_KEY);

    const updateContextWithLocalStore = (storageData: string) => {
      const parsedData: SurveyPreviewData = JSON.parse(storageData);
      setSurveyRequestedByCompanyName(parsedData.companyName);
      setSurveyHasRequestedDocs(parsedData.requestedDocuments?.length > 0);
      setIsSurveyError(!parsedData.isValid);
      setSurveyRequestedByEmail(userState.email || "");
      setSurveyRequestedByUserName(userState.firstName + " " + userState.lastName || "");
    };

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === LOCAL_STORE_PREVIEW_KEY) {
        const questionnaireData = localStorage.getItem(LOCAL_STORE_PREVIEW_KEY);
        if (questionnaireData) {
          updateContextWithLocalStore(questionnaireData);
        } else {
          setIsSurveyError(true);
        }
      }
    };

    if (initialQuestionnaireDataForHeader) {
      updateContextWithLocalStore(initialQuestionnaireDataForHeader);
    } else {
      setIsSurveyError(true);
    }

    setIsHeaderDataLoaded(true);
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [
    isSetupMode,
    setIsHeaderDataLoaded,
    setSurveyHasRequestedDocs,
    setSurveyRequestedByCompanyName,
    setSurveyRequestedByEmail,
    setSurveyRequestedByUserName,
    userState,
  ]);

  useEffect(() => {
    // Preview launched from outside setup

    if (isSetupMode || !initId) {
      return;
    }
    (async () => {
      const url = `api/v2/initiatives/${initId}/survey-preview/header`;
      const response = await getAsyncRequest(url);
      if (response?.status !== 200 || !response?.data?.data || typeof response?.data?.data !== "object") {
        setIsSnackbarOpen(true);
        return;
      }
      const payload: SurveyPreviewHeaderResponseDTO = response.data.data;
      setInitState(payload.initState || InitState.ACTIVE);
      setIsHeaderDataLoaded(true);
      setSurveyHasRequestedDocs(payload.hasRequestedDocuments);
      setSurveyId(payload.surveyId || "");
      setSurveyRequestedByCompanyName(payload.requestedByCompanyName || "");
      setSurveyRequestedByEmail(payload.requestedByEmail || "");
      setSurveyRequestedByUserName(payload.requestedByUserName || "");
      setSurveyStatus(payload.surveyStatus);
      setIsSurveyError(!payload.surveyId || !payload.hasRequirements);
    })();
  }, [
    initId,
    isSetupMode,
    setInitState,
    setIsHeaderDataLoaded,
    setIsSnackbarOpen,
    setSurveyHasRequestedDocs,
    setSurveyId,
    setSurveyRequestedByCompanyName,
    setSurveyRequestedByEmail,
    setSurveyRequestedByUserName,
    setSurveyStatus,
  ]);

  return (
    <Fragment>
      <div className={classNames(classes.headerContent, classes.headerShiftedForPreview)}>
        <PreviewBanner isSetupMode={isSetupMode} />
        <ClosedSurveyHeader />
        <BrandingHeader />
        <div className={classNames(classes.collapseContainer, isScrolling && classes.collapsedScrolling)}>
          <Collapse in={!isScrolling}>
            <SurveyHeaderContextProvider>
              <SurveyHeader />
            </SurveyHeaderContextProvider>
          </Collapse>
          <TabMenu />
        </div>
      </div>
      <div
        className={classNames(
          classes.staticHeaderSpacer,
          [InitState.ARCHIVED, InitState.ARCHIVED].includes(initState) && classes.closedStatusSpacer,
          !location.pathname.includes(NewAppPaths.QUESTIONNAIRE_OVERVIEW_TAB) && isScrolling && classes.scrollingSpacer,
          classes.previewBtmMargin
        )}
      />
      <Snackbar />
      {isSurveyError && <PreviewError />}
      <OnboardingDialog />
    </Fragment>
  );
};

export default AppPreview;
