import { colorPalette } from "gx-npm-common-styles";
import { StarV2Icon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import ResearchSectionComponent from "../research-section/research-section.component";

type PeerInsightsSectionV2Props = { piMarketSeoName: string; templateName: string };
const PeerInsightsSectionV2Component = ({ piMarketSeoName, templateName }: PeerInsightsSectionV2Props) => {
  const { t } = useTranslation();
  return (
    <ResearchSectionComponent
      description={t(
        "Peer Insights is Gartner's peer-driven platform where thousands of enterprise leaders connect " +
          "with each other to help guide technology and business decisions. You can access peer technology " +
          "rating and reviews by verified users, network, share knowledge, get advice, and stay on top of " +
          "current trends in this technology space."
      )}
      href={`https://www.gartner.com/reviews/market/${piMarketSeoName}?ref=buysmartpi`}
      icon={<StarV2Icon fillPath={colorPalette.interactions.berry.hex} height="18px" width="19px" />}
      templateName={templateName}
      title={t("Peer Insights")}
    />
  );
};

export default PeerInsightsSectionV2Component;
