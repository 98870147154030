// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W1h0FWOWTcc53HGaMIVi{padding-right:81px}.W1h0FWOWTcc53HGaMIVi .l7_lebsbsJdRRkU5v_vo{align-items:center;display:flex;gap:15px;padding-bottom:12px}.W1h0FWOWTcc53HGaMIVi .XWzyOAX4aCguTWKpiidI{width:400px}.W1h0FWOWTcc53HGaMIVi .KOHtIL1GmoWPEi1JQ9NF{margin-bottom:32px;padding-left:12px;width:388px}.W1h0FWOWTcc53HGaMIVi .KOHtIL1GmoWPEi1JQ9NF .XseatQUnYRotB6_e6i1x{margin-bottom:12px}.W1h0FWOWTcc53HGaMIVi .KOHtIL1GmoWPEi1JQ9NF .XseatQUnYRotB6_e6i1x::marker{color:var(--color-white)}.W1h0FWOWTcc53HGaMIVi .i8c9_j2CpX_eVtajvjHg{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.W1h0FWOWTcc53HGaMIVi .djrVSyFPkxv6RjPRUW9J{margin-bottom:16px}.W1h0FWOWTcc53HGaMIVi .OYi4HQA65tO69zu6jAnK{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/magic-quad-section/magic-quad-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".magicRoot {\n  padding-right: 81px;\n\n  & .magicHeader {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .magicDescription {\n    width: 400px;\n  }\n\n  & .magicList {\n    margin-bottom: 32px;\n    padding-left: 12px;\n    width: 388px;\n\n    & .magicListItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .magicDivider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n\n  & .magicLearnMore {\n    margin-bottom: 16px;\n  }\n\n  & .magicLinkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magicRoot": `W1h0FWOWTcc53HGaMIVi`,
	"magicHeader": `l7_lebsbsJdRRkU5v_vo`,
	"magicDescription": `XWzyOAX4aCguTWKpiidI`,
	"magicList": `KOHtIL1GmoWPEi1JQ9NF`,
	"magicListItem": `XseatQUnYRotB6_e6i1x`,
	"magicDivider": `i8c9_j2CpX_eVtajvjHg`,
	"magicLearnMore": `djrVSyFPkxv6RjPRUW9J`,
	"magicLinkText": `OYi4HQA65tO69zu6jAnK`
};
export default ___CSS_LOADER_EXPORT___;
