import classNames from "classnames";
import { useUserState, useCaptureEventsV2 } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../app.constants";
import { MarketProfileAppContext } from "../../app.context";
import MarketFeaturesTableComponent from "./market-features-table/market-features-table.component";
import styles from "./market-overview-section.styles.module.scss";

const MarketOverviewSectionComponent = () => {
  const { t } = useTranslation();
  const { hasUserState } = useUserState();
  const { description, gcomCcDocCd, gcomCcResId, isLoading, templateId, name } = useContext(MarketProfileAppContext);
  const [ccUrl, setCcUrl] = useState("");
  const captureEvents = useCaptureEventsV2();
  const hasTableView = gcomCcDocCd > 0;

  useEffect(() => {
    if (gcomCcResId < 1) {
      return;
    }
    setCcUrl(`https://www.gartner.com/document/${gcomCcResId}?ref=buysmartresearch`);
  }, [gcomCcResId]);

  const handlePoweredByLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const eventType = ClientEvent.MARKET_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED;
    const metaData = { templateId: templateId.toString(), templateName: name };
    captureEvents([{ eventType, metaData }]);
    window.open(ccUrl, "_blank", "noopener");
  };

  return (
    <section className={classNames(styles.sectionContainer)}>
      <div className={styles.cardV2}>
        <div className={styles.aboutHeader}>
          <TypographyComponent boldness="medium" color="carbon" rootClassName={styles.aboutParagraphV2} styling="p1">
            {t("About the market")}
          </TypographyComponent>

          {gcomCcResId !== -1 && (
            <div className={styles.poweredBy}>
              <TypographyComponent color="iron" rootClassName={styles.poweredByText} styling="p5">
                {t("Powered by")}
              </TypographyComponent>
              <a
                className={styles.poweredByLink}
                href={ccUrl}
                onClick={handlePoweredByLinkClick}
                rel="noopener"
                target="_blank"
              >
                <TypographyComponent
                  boldness="medium"
                  color="defaultCta"
                  rootClassName={styles.poweredByLinkText}
                  styling="p5"
                >
                  {t("Gartner Critical Capabilities")}
                </TypographyComponent>
              </a>
            </div>
          )}
        </div>
        <div className={classNames(styles.detailsContainer, !hasTableView && styles.detailsContainerNoTable)}>
          <TypographyComponent
            rootClassName={classNames(styles.marketDescription, !hasTableView && styles.marketDescriptionNoTable)}
            styling="p3"
          >
            {description}
          </TypographyComponent>
          <div className={styles.featuresDisplayContainer}>
            {hasTableView && hasUserState && !isLoading && <MarketFeaturesTableComponent />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MarketOverviewSectionComponent;
