import { ReactElementLike } from "prop-types";
import { useTranslation } from "react-i18next";
import { GCOM_3694__updateDefaultEvaluationName, GCOM_4297__emqUpdates } from "../../feature-flags";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  useFeatureFlag,
} from "../../featureFlags";
import { SlideInOverlay } from "../../slideInOverlay";
import { TypographyComponent } from "../../typography/typography.component";
import { ResearchVariant } from "../research-ribbon.constants";
import EmergingMarketQuadSectionComponent from "./emerging-market-quad-section/emerging-market-quad-section.component";
import MagicQuadSectionComponent from "./magic-quad-section/magic-quad-section.component";
import MagicQuadSectionV2Component from "./magic-quad-section-v2/magic-quad-section-v2.component";
import MarketGuideSectionComponent from "./market-guide-section/market-guide-section.component";
import MarketGuideSectionV2Component from "./market-guide-section-v2/market-guide-section-v2.component";
import PeerInsightsSectionComponent from "./peer-insights-section/peer-insights-section.component";
import PeerInsightsSectionV2Component from "./peer-insights-section-v2/peer-insights-section-v2.component";
import SlideInHeaderComponent from "./slide-in-header/slide-in-header.component";
import styles from "./research-slide-in-v2.styles.module.scss";

type ResearchSlideInV2Props = {
  backToText: string;
  isOpen: boolean;
  magicQuadResId?: number;
  marketGuideResId?: number;
  onClose: () => void;
  piMarketSeoName?: string;
  researchVariant: `${ResearchVariant}`;
  templateName?: string;
};
const ResearchSlideInV2Component = ({
  backToText = "",
  isOpen = false,
  magicQuadResId = -1,
  marketGuideResId = -1,
  onClose = () => {},
  piMarketSeoName = "",
  researchVariant = ResearchVariant.NONE,
  templateName = "",
}: ResearchSlideInV2Props) => {
  const { t } = useTranslation();
  const isMaqicQuad = magicQuadResId > 0;
  const isDefaultEvalNameFFOn = useFeatureFlag(GCOM_3694__updateDefaultEvaluationName);
  return (
    <SlideInOverlay
      darth={true}
      headerLogo={(<SlideInHeaderComponent />) as ReactElementLike}
      isOpen={isOpen}
      onClose={onClose}
      textBackBtn={isDefaultEvalNameFFOn ? ` ${backToText}` : t("eval")}
    >
      <section className={styles.root}>
        <TypographyComponent color="quartz" rootClassName={styles.primaryHeader} styling="h2">
          {templateName}
        </TypographyComponent>
        <div className={styles.divider} />
        <div>
          <div>
            <TypographyComponent color="berry" rootClassName={styles.secondaryHeader} styling="h3">
              {t("Gartner research behind this market")}
            </TypographyComponent>
            <TypographyComponent color="quartz" element="p" rootClassName={styles.paragraphs}>
              {t(
                "BuySmart combines the power of curated Gartner research, peer and expert insights, and intuitive " +
                  "workflow processes into markets. Each market is based on Gartner research that uses our proprietary " +
                  "methodologies and an independent operating model for objective business and technology insights."
              )}
            </TypographyComponent>
            <TypographyComponent color="quartz" element="p" rootClassName={styles.paragraphs}>
              {t(
                "This market has been created from a variety of published Gartner research documents and data sources."
              )}
            </TypographyComponent>
          </div>
          <div className={styles.researchContent}>
            <FeatureFlagBooleanContainer flagName={GCOM_4297__emqUpdates}>
              <FeatureFlagBooleanOff>
                {isMaqicQuad && <MagicQuadSectionComponent resId={magicQuadResId} templateName={templateName} />}
                {!isMaqicQuad && <MarketGuideSectionComponent resId={marketGuideResId} templateName={templateName} />}
                <PeerInsightsSectionComponent piMarketSeoName={piMarketSeoName} templateName={templateName} />
              </FeatureFlagBooleanOff>
              <FeatureFlagBooleanOn>
                {researchVariant === ResearchVariant.EMQ && (
                  <EmergingMarketQuadSectionComponent resId={magicQuadResId} templateName={templateName} />
                )}
                {researchVariant === ResearchVariant.MQ && (
                  <MagicQuadSectionV2Component resId={magicQuadResId} templateName={templateName} />
                )}
                {researchVariant === ResearchVariant.MARKET_GUIDE && (
                  <MarketGuideSectionV2Component resId={marketGuideResId} templateName={templateName} />
                )}
                <PeerInsightsSectionV2Component piMarketSeoName={piMarketSeoName} templateName={templateName} />
              </FeatureFlagBooleanOn>
            </FeatureFlagBooleanContainer>
          </div>
        </div>
      </section>
    </SlideInOverlay>
  );
};

export default ResearchSlideInV2Component;
