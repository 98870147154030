// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D_c8fNOXJV9b8l8hqL_J{padding-right:81px}.D_c8fNOXJV9b8l8hqL_J .Ae2_4nNQrHOnjCh6Xhcw{align-items:center;display:flex;gap:15px;padding-bottom:12px}.D_c8fNOXJV9b8l8hqL_J .AeWxq_AMilnWWScdzjcl{width:400px}.D_c8fNOXJV9b8l8hqL_J .UFa37UKDgOszdOJMbGBJ{margin-bottom:32px;padding-left:12px;width:388px}.D_c8fNOXJV9b8l8hqL_J .UFa37UKDgOszdOJMbGBJ .dFkYbGL3cVr5J36n3sqD{margin-bottom:12px}.D_c8fNOXJV9b8l8hqL_J .UFa37UKDgOszdOJMbGBJ .dFkYbGL3cVr5J36n3sqD::marker{color:var(--color-white)}.D_c8fNOXJV9b8l8hqL_J .x2OTMIeRoCnJRoM1_mrD{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.D_c8fNOXJV9b8l8hqL_J .pobNEanl4uGHYN6awpRu{margin-bottom:16px}.D_c8fNOXJV9b8l8hqL_J .Vm_Opbm06f5YOmQside5{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/research-section/research-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".root {\n  padding-right: 81px;\n\n  & .header {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .description {\n    width: 400px;\n  }\n\n  & .list {\n    margin-bottom: 32px;\n    padding-left: 12px;\n    width: 388px;\n\n    & .listItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n\n  & .learnMore {\n    margin-bottom: 16px;\n  }\n\n  & .linkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `D_c8fNOXJV9b8l8hqL_J`,
	"header": `Ae2_4nNQrHOnjCh6Xhcw`,
	"description": `AeWxq_AMilnWWScdzjcl`,
	"list": `UFa37UKDgOszdOJMbGBJ`,
	"listItem": `dFkYbGL3cVr5J36n3sqD`,
	"divider": `x2OTMIeRoCnJRoM1_mrD`,
	"learnMore": `pobNEanl4uGHYN6awpRu`,
	"linkText": `Vm_Opbm06f5YOmQside5`
};
export default ___CSS_LOADER_EXPORT___;
