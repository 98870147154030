import { useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { useCaptureEventsV2, useScrolling, UUID } from "gx-npm-lib";
import { GartnerFooter } from "gx-npm-ui";
import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ClientEvent } from "../../app.constants";
import FileDragAndDropUploadComponent from "../../sections/file-drag-and-drop-upload/file-drag-and-drop-upload.component";
import FileHubHeaderComponent from "../../sections/file-hub-header/file-hub-header.component";
import FileHubLegalBannerComponent from "../../sections/file-hub-legal-banner/file-hub-legal-banner.component";
import FileListComponent from "../../sections/file-list/file-list.component";
import RecommendedCardsComponent from "../../sections/recommended-cards/recommended-cards.component";
import styles from "./stand-alone-view.styles.module.scss";

const StandAloneViewComponent = () => {
  const isScrolling = useScrolling();
  const isStackedView = useMediaQuery("(max-width:1239px)");
  const captureEvents = useCaptureEventsV2();
  const { initId = "" } = useParams<{ initId: UUID }>();

  useEffect(() => {
    if (!initId) {
      return;
    }
    const eventType = ClientEvent.INITIATIVE_FILE_HUB_PAGE_VIEWED;
    captureEvents([{ eventType, metaData: { initiativeId: initId } }]);
  }, [initId, captureEvents]);

  return (
    <section className={styles.standAloneRoot}>
      <div className={styles.headerWrapper}>
        <FileHubHeaderComponent />
      </div>
      <div className={classNames(styles.standAloneContent, isScrolling && styles.scrolling)}>
        <div className={classNames(styles.leftColumn)}>
          <FileDragAndDropUploadComponent />
          {!isStackedView && <RecommendedCardsComponent />}
        </div>
        <div className={styles.rightColumn}>
          <FileListComponent />
          {!isStackedView && <FileHubLegalBannerComponent />}
        </div>
        {isStackedView && (
          <Fragment>
            <RecommendedCardsComponent />
            <FileHubLegalBannerComponent />
          </Fragment>
        )}
      </div>
      <div className={styles.footerWrapper}>
        <GartnerFooter hasNavbar={false} />
      </div>
    </section>
  );
};

export default StandAloneViewComponent;
