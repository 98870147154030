// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gi7Rl8pdzC7DMkCQK7Zc{padding-right:80px}.gi7Rl8pdzC7DMkCQK7Zc .rTYzRFRtyz90Z9fMNsZM{align-items:center;display:flex;gap:15px;padding-bottom:12px}.gi7Rl8pdzC7DMkCQK7Zc .MEzQgFgZrkG9y_e46e1X{margin-bottom:56px;width:400px}.gi7Rl8pdzC7DMkCQK7Zc .H344Ishxo8Xx2TorK_47{background-color:var(--color-white);height:1px;margin-bottom:12px;opacity:.2}.gi7Rl8pdzC7DMkCQK7Zc .aE1O95SdbNtUsEJF_K6g{margin-bottom:16px}.gi7Rl8pdzC7DMkCQK7Zc ._cPuvs_iBbVTBID2w4a1{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/market-guide-section/market-guide-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kBAAA,CACA,WAAA,CAGF,4CACE,mCAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".marketRoot {\n  padding-right: 80px;\n\n  & .marketHeader {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .marketParagraph {\n    margin-bottom: 56px;\n    width: 400px;\n  }\n\n  & .marketDivider {\n    background-color: var(--color-white);\n    height: 1px;\n    margin-bottom: 12px;\n    opacity: 0.2;\n  }\n\n  & .marketLearnMore {\n    margin-bottom: 16px;\n  }\n\n  & .marketTextLink {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketRoot": `gi7Rl8pdzC7DMkCQK7Zc`,
	"marketHeader": `rTYzRFRtyz90Z9fMNsZM`,
	"marketParagraph": `MEzQgFgZrkG9y_e46e1X`,
	"marketDivider": `H344Ishxo8Xx2TorK_47`,
	"marketLearnMore": `aE1O95SdbNtUsEJF_K6g`,
	"marketTextLink": `_cPuvs_iBbVTBID2w4a1`
};
export default ___CSS_LOADER_EXPORT___;
