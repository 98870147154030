import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { PopoverMenu, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import styles from "./response-option-popover.styles";
import { AppContext } from "../../../../../../app.context";
import { GCOM_4107_questionnaireResponseAlignment } from "../../../../../../lib/feature-flag";

const useStyles = makeStyles(() => styles);
type ScoringPopoverProps = {
  responseOptionIndex: number;
  onSelectResponseOption: (responseOptionIndex: number) => void;
  rootClassName?: string;
};

const ResponseOptionPopover: React.FC<ScoringPopoverProps> = ({
  onSelectResponseOption = () => {},
  rootClassName = "",
  responseOptionIndex = -1,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isGCOM4107Ff = useFeatureFlag(GCOM_4107_questionnaireResponseAlignment);

  const handleClick = (value: number) => {
    if (value !== responseOptionIndex) {
      onSelectResponseOption(value);
    }
  };

  const { options, isProductResponsesView } = useContext(AppContext);
  const menuItems = options.map((item, index) => ({
    ...item,
    index: index,
  }));

  return (
    <div
      className={classNames(
        rootClassName,
        classes.popoverWrapper,
        isGCOM4107Ff && isProductResponsesView && classes.productResponsesView
      )}
    >
      {responseOptionIndex === -1 && isProductResponsesView && (
        <TypographyComponent rootClassName={classes.noSelectionViewOnly} styling={"p2"}>
          {t("No response")}
        </TypographyComponent>
      )}
      {responseOptionIndex !== -1 && isProductResponsesView && (
        <TypographyComponent boldness={"medium"} rootClassName={classes.selectedViewOnly} styling={"p2"}>
          {menuItems[responseOptionIndex].name}
        </TypographyComponent>
      )}
      {!isProductResponsesView && (
        <PopoverMenu
          noCurrentSelectionText={t("Please select")}
          isBehindAppBar={false}
          isClosedOnScroll={true}
          menuItems={menuItems}
          onClick={handleClick}
          rootClassName="gx-survey-responses-scoring-popover"
          selectedIndex={responseOptionIndex}
          isDynamicWidth={false}
        />
      )}
    </div>
  );
};
export default ResponseOptionPopover;
