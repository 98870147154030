import { Collapse } from "@mui/material";
import classNames from "classnames";
import { useCaptureEventsV2, useUserState } from "gx-npm-lib";
import { Loader, MiniButton, TypographyComponent } from "gx-npm-ui";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../app.constants";
import { MarketProfileAppContext } from "../../app.context";
import ExpandCollapseCircledArrowIcon from "../../icons/expand-collapse-circled-arrow.icon";
import MarketProductsHeader from "./market-products-header/market-products-header.component";
import MarketWeightedPriorityDrawerComponent from "./market-weighted-priority-drawer/market-weighted-priority-drawer.component";
import PrioritiesBannerComponent from "./priorities-banner/priorities-banner.component";
import ProductRow from "./product-row/product-row.component";
import styles from "./market-products.styles.module.scss";

const SESSION_MARKET_PRODUCTS_EXPANDED_KEY = "marketProductListExpanded";
const INITIAL_PRODUCTS_SHOWN_COUNT = 6;
const MarketProducts = () => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { isEntitled } = useUserState();
  const { gcomMqResId, gcomMgResId, isProductListLoading, products, templateId, name } =
    useContext(MarketProfileAppContext);
  const [magicQuadUrl, setMagicQuadUrl] = useState("");
  const [marketUrl, setMarketUrl] = useState("");
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (gcomMqResId < 1) {
      return;
    }
    setMagicQuadUrl(`https://www.gartner.com/document/${gcomMqResId}?ref=buysmartresearch`);
  }, [gcomMqResId]);

  useEffect(() => {
    if (gcomMgResId < 1) {
      return;
    }
    setMarketUrl(`https://www.gartner.com/document/${gcomMgResId}?ref=buysmartresearch`);
  }, [gcomMgResId]);

  useEffect(() => {
    if (!templateId) {
      return;
    }
    const sessionStorageExpanded = sessionStorage.getItem(`${SESSION_MARKET_PRODUCTS_EXPANDED_KEY}-${templateId}`);
    setIsExpanded(sessionStorageExpanded === "true");
  }, [templateId]);

  const handleClickExpandCollapse = () => {
    setIsExpanded(!isExpanded);
    sessionStorage.setItem(`${SESSION_MARKET_PRODUCTS_EXPANDED_KEY}-${templateId}`, (!isExpanded).toString());
  };

  const handleMQClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const eventType = ClientEvent.MARKET_PROFILE_POWERED_BY_MAGIC_QUADRANT_LINK_CLICKED;
    const metaData = { templateId: templateId.toString(), templateName: name };
    captureEvents([{ eventType: eventType, metaData: metaData }]);
    window.open(magicQuadUrl, "_blank", "noopener");
  };

  const handleMGClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const eventType = ClientEvent.MARKET_PROFILE_POWERED_BY_MARKET_GUIDE_LINK_CLICKED;
    const metaData = { templateId: templateId.toString(), templateName: name };
    captureEvents([{ eventType: eventType, metaData: metaData }]);
    window.open(marketUrl, "_blank", "noopener");
  };

  return (
    <section className={styles.contentContainer}>
      <div className={classNames(styles.content)}>
        <div className={styles.productHeader}>
          <TypographyComponent boldness="medium" styling="p1">
            {t("Vendors")}
            {` (${products.length})`}
          </TypographyComponent>

          {gcomMqResId !== -1 && (
            <div className={styles.poweredBy}>
              <TypographyComponent color="iron" rootClassName={styles.poweredByText} styling="p5">
                {t("Powered by")}
              </TypographyComponent>
              <a className={styles.poweredByLink} href={magicQuadUrl} onClick={(e) => handleMQClick(e)}>
                <TypographyComponent
                  boldness="medium"
                  color="defaultCta"
                  rootClassName={styles.poweredByLinkText}
                  styling="p5"
                >
                  {t("Gartner Magic Quadrant")}
                </TypographyComponent>
              </a>
            </div>
          )}
          {gcomMqResId === -1 && gcomMgResId !== -1 && (
            <div className={styles.poweredBy}>
              <TypographyComponent rootClassName={styles.poweredByText} styling="p5" color="iron">
                {t("Powered by")}
              </TypographyComponent>
              <a className={styles.poweredByLink} href={marketUrl} onClick={(e) => handleMGClick(e)}>
                <TypographyComponent
                  color="defaultCta"
                  boldness="medium"
                  rootClassName={styles.poweredByLinkText}
                  styling="p5"
                >
                  {t("Gartner Market Guide")}
                </TypographyComponent>
              </a>
            </div>
          )}
        </div>
        {isEntitled && gcomMqResId > 0 && <PrioritiesBannerComponent />}
        <div>
          <MarketProductsHeader />
          {isProductListLoading && (
            <div className={styles.loadingProductList}>
              <div className={styles.productsLoader}>
                <Loader />
                <TypographyComponent rootClassName={styles.loadingVendorsText} color="coal" type="p2">
                  {t("Loading vendors")}
                </TypographyComponent>
              </div>
            </div>
          )}
          {!isProductListLoading && (
            <ul>
              {products.slice(0, INITIAL_PRODUCTS_SHOWN_COUNT).map((product, index) => (
                <ProductRow key={product.name} index={index} {...product} />
              ))}
              <Collapse aria-label={"expandable list section"} in={isExpanded}>
                {products.slice(INITIAL_PRODUCTS_SHOWN_COUNT).map((product, index) => (
                  <ProductRow key={product.name} index={index + INITIAL_PRODUCTS_SHOWN_COUNT} {...product} />
                ))}
              </Collapse>
              {products.length > 6 && (
                <div className={classNames(styles.buttonWrapper, isExpanded && styles.expanded)}>
                  <MiniButton
                    onClick={handleClickExpandCollapse}
                    rootClassName={classNames(styles.expandCollapseBtn, isExpanded && styles.isExpanded)}
                  >
                    <ExpandCollapseCircledArrowIcon />
                    {!isExpanded ? t("Show more vendors") : t("Show less vendors")}
                  </MiniButton>
                </div>
              )}
            </ul>
          )}
        </div>
      </div>
      <MarketWeightedPriorityDrawerComponent />
    </section>
  );
};

export default MarketProducts;
