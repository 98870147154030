// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xoC8CxTYa7DVwUhiOj3H{margin-left:0;margin-right:24px}.iNkeAlaMd95tz_AMVKZR{margin-top:13px}`, "",{"version":3,"sources":["webpack://./src/sections/header-v2/components/share-button/share-button.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,iBAAA,CAGF,sBACE,eAAA","sourcesContent":[".shareButton {\n  margin-left: 0;\n  margin-right: 24px;\n}\n\n.peopleIconClass {\n  margin-top: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareButton": `xoC8CxTYa7DVwUhiOj3H`,
	"peopleIconClass": `iNkeAlaMd95tz_AMVKZR`
};
export default ___CSS_LOADER_EXPORT___;
