import { getAsyncRequest } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTokenParam from "../../../hooks/token-param.hook";
import SignUpButton from "./sign-up-form-button/sign-up-form-button.component";
import SignUpFormFields from "./sign-up-form-fields/sign-up-form-fields.component";
import SignUpFormHeader from "./sign-up-form-header/sign-up-form-header.component";
import SignUpFormOptions from "./sign-up-form-options/sign-up-form-options.component";
import SignUpProfileFields from "./sign-up-profile-fields/sign-up-profile-fields.component";
import { SignUpFormContext } from "./sign-up-form.context";
import styles from "./sign-up-form-v2.styles.module.scss";
import { GCOM_4110__guestLoginEnhancements } from "../../../lib/feature-flags";
import classNames from "classnames";

const SignUpFormV2Component = () => {
  const { t } = useTranslation();
  const { hasEvaluatedToken, isValidToken, surveyId } = useTokenParam();
  const { page, setErrorText, setGcomData } = useContext(SignUpFormContext);
  const [showDivider, setShowDivider] = useState(true);
  const isffGCOM4110ON = useFeatureFlag(GCOM_4110__guestLoginEnhancements);

  useEffect(() => {
    if (!hasEvaluatedToken || !isValidToken || surveyId) {
      return;
    }
    (async () => {
      try {
        const response = await getAsyncRequest(isffGCOM4110ON ? "/api/v4/data/profile" : "/api/v3/data/profile");
        if (response?.status !== 200 || !response.data?.data) {
          throw new Error("Failed to fetch profile data");
        }
        setGcomData({
          companySize: response.data.data.companySize || [],
          functions: response.data.data.functions || [],
          industries: response.data.data.industries || [],
          countries: isffGCOM4110ON ? response.data.data.countries : [],
        });
      } catch (error) {
        setErrorText("Failed to fetch profile data. Please try again.");
      }
    })();
  }, [hasEvaluatedToken, isValidToken, surveyId, setErrorText, setGcomData, isffGCOM4110ON]);

  return (
    <div className={classNames(styles.signUpForm, isffGCOM4110ON && styles.signupFormGCOM4110)}>
      <SignUpFormHeader />
      <form noValidate autoComplete="off" className={isffGCOM4110ON ? styles.form : ""}>
        {page === 1 ? <SignUpFormFields /> : <SignUpProfileFields setShowDivider={setShowDivider} />}
        <FeatureFlagBooleanContainer flagName={GCOM_4110__guestLoginEnhancements}>
          <FeatureFlagBooleanOn>
            {page === 2 && showDivider && <div className={styles.divider} />}
            <div className={page === 2 ? styles.page2Footer : ""}>
              <TypographyComponent
                boldness="regular"
                color="poisonCherry"
                styling="p4"
                rootClassName={page === 1 ? styles.page1RequiredText : ""}
              >
                {t("*Required")}
              </TypographyComponent>
              {page === 1 && <SignUpFormOptions />}
              <SignUpButton />
            </div>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent boldness="regular" color="poisonCherry" rootClassName={styles.required} styling="p4">
              {t("*Required")}
            </TypographyComponent>
            {page === 1 && <SignUpFormOptions />}
            <SignUpButton />
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </form>
    </div>
  );
};

export default SignUpFormV2Component;
