import { colorPalette } from "gx-npm-common-styles";
import { MarketGuideIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import ResearchSectionComponent from "../research-section/research-section.component";

type MarketGuideSectionV2Props = { resId: number; templateName: string };
const MarketGuideSectionV2Component = ({ resId = -1, templateName = "" }: MarketGuideSectionV2Props) => {
  const { t } = useTranslation();
  return (
    <ResearchSectionComponent
      description={t(
        "Market Guides (MGs) deliver a clear definition and framing of the market, market dynamics and an " +
          "overview of market participants. They deliver leaders who are considering an investment with actionable " +
          "insights on use cases spurring the market, offerings and market direction."
      )}
      href={`https://www.gartner.com/document/${resId}?ref=buysmartresearch`}
      icon={<MarketGuideIcon fillPath={colorPalette.interactions.berry.hex} />}
      templateName={templateName}
      title={t("Market Guide")}
    />
  );
};

export default MarketGuideSectionV2Component;
