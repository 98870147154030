import React, { FC } from "react";
import { Requirement, RequirementItem } from "../../../../../../app.types";
import { Collapse } from "@material-ui/core";

type ScorecardFooterRowProps = {
  requirements: Requirement[];
  requirementsOpenIdxList: number[];
};

type RequirementFooterRowItemProps = {
  items: RequirementItem[];
  index: number;
  requirementsOpenIdxList: number[];
};

const RequirementFooterRowItem: FC<RequirementFooterRowItemProps> = ({ items, index, requirementsOpenIdxList }) => {
  if (items.length < 1) {
    return (
      <div className="gx-req-root-footer gx-set-element-height-footer">
        <div className="gx-footer-divider" />
      </div>
    );
  }

  return (
    <>
      <div className="gx-req-root-footer gx-set-element-height-footer">
        <div className="gx-footer-divider" />
      </div>
      <Collapse className="gx-req-item-footer-container" in={requirementsOpenIdxList.includes(index)}>
        <ul className="gx-req-item-list-footer">
          {items.map(({ id }) => (
            <li key={id} className="gx-req-item-footer gx-set-element-height-footer">
              <div className="gx-footer-divider" />
            </li>
          ))}
        </ul>
      </Collapse>
    </>
  );
};

const ScorecardFooterRow: FC<ScorecardFooterRowProps> = ({ requirements = [], requirementsOpenIdxList = [] }) => {
  return (
    <>
      {requirements.map((requirement, index) => (
        <RequirementFooterRowItem
          items={requirement.items}
          key={requirement.id}
          index={index}
          requirementsOpenIdxList={requirementsOpenIdxList}
        />
      ))}
    </>
  );
};

export default ScorecardFooterRow;
