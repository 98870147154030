// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WU4CPgI4uglMjQ5wZYvK{margin-bottom:8px}.WU4CPgI4uglMjQ5wZYvK .gx-text-awarded{position:relative;top:-25px;line-height:26px}.dT6_fMpqWzm7fO_yjqMT{position:relative;top:-4px}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/evaluation-scorecard/scorecard-products/scorecard-product-status/scorecard-product-status.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,uCACE,iBAAA,CACA,SAAA,CACA,gBAAA,CAIJ,sBACE,iBAAA,CACA,QAAA","sourcesContent":[".overallScoreDisplay {\n  margin-bottom: 8px;\n\n  & :global(.gx-text-awarded) {\n    position: relative;\n    top: -25px;\n    line-height: 26px;\n  }\n}\n\n.dashPlaceholder {\n  position: relative;\n  top: -4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overallScoreDisplay": `WU4CPgI4uglMjQ5wZYvK`,
	"dashPlaceholder": `dT6_fMpqWzm7fO_yjqMT`
};
export default ___CSS_LOADER_EXPORT___;
