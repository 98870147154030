import classNames from "classnames";
import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import { GartnerFooter } from "gx-npm-ui";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ClientEvent } from "../../app.constants";
import FileDragAndDropUploadComponent from "../../sections/file-drag-and-drop-upload/file-drag-and-drop-upload.component";
import FileHubLegalBannerComponent from "../../sections/file-hub-legal-banner/file-hub-legal-banner.component";
import FileListComponent from "../../sections/file-list/file-list.component";
import styles from "./product-tab-view.styles.module.scss";

const ProductTabViewComponent = () => {
  const captureEvents = useCaptureEventsV2();
  const { initId = "", initProdId = "" } = useParams<{ initId: UUID; initProdId: UUID }>();

  useEffect(() => {
    if (!initId || !initProdId) {
      return;
    }
    const eventType = ClientEvent.INITIATIVE_FILE_HUB_PAGE_VIEWED;
    captureEvents([{ eventType, metaData: { initiativeId: initId, initProductId: initProdId } }]);
  }, [initId, initProdId, captureEvents]);

  return (
    <section className={styles.productTabRoot}>
      <div className={styles.productTabContent}>
        <div className={classNames(styles.leftColumn)}>
          <FileDragAndDropUploadComponent />
        </div>
        <div className={styles.rightColumn}>
          <FileListComponent />
          <div className={classNames(styles.legalBanner)}>
            <FileHubLegalBannerComponent />
          </div>
        </div>
      </div>
      <div>
        <GartnerFooter hasNavbar={false} />
      </div>
    </section>
  );
};

export default ProductTabViewComponent;
