import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonLoader, Dialog, IconButton, ProductLogo, TypographyComponent } from "gx-npm-ui";
import { handleEvent } from "gx-npm-lib";
import { ArrowRight, MultiplyIcon, SelectedVendorIcon, SelectedVendorIconSmall } from "gx-npm-icons";
import styles from "./award-product-dialog.styles";

type ActionDialogProps = {
  awardedProductName: string;
  awardedProductImageLoc: string;
  isLoading: boolean;
  isOpen: boolean;
  selectionImageLoc: string;
  selectionName: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

const useStyles = makeStyles(() => styles);
const AwardProductDialog = ({
  awardedProductName,
  awardedProductImageLoc,
  isOpen = false,
  isLoading = false,
  selectionImageLoc,
  selectionName,
  onClose,
  onConfirm,
}: ActionDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const labelTitle = awardedProductName ? t("Change your selected vendor?") : t("Confirm vendor selection");

  return (
    <Dialog
      maxDialogWidth="698px"
      open={isOpen}
      rootClassName={classNames("gx-award-dialog")}
      disableRestoreFocus={true}
      title={
        <div>
          <TypographyComponent
            styling={"h4"}
            boldness={"medium"}
            color={"carbon"}
            rootClassName={classNames(classes.title, awardedProductName && classes.selectedTitle)}
          >
            {labelTitle}
            <IconButton onClick={() => handleEvent(onClose)}>
              <MultiplyIcon title="Cancel" />
            </IconButton>
          </TypographyComponent>
        </div>
      }
      body={
        <Fragment>
          {awardedProductName && (
            <div className={classNames(classes.leftItems)}>
              <div className={classNames(classes.awardedVendorIcons)}>
                <ProductLogo imageHeight="56px" imageWidth="56px" logo={awardedProductImageLoc} />
              </div>

              <TypographyComponent
                rootClassName={classes.currentSelectTitle}
                styling={"p3"}
                boldness={"medium"}
                color={"iron"}
              >
                {t("Current selection")}
              </TypographyComponent>

              <div className={classNames(classes.rightArrow)}>
                <ArrowRight />
              </div>
              <div className={classNames(classes.newAwardedVendorIcons)}>
                <SelectedVendorIcon />
                <ProductLogo logo={selectionImageLoc} imageWidth="70px" imageHeight="70px" />
              </div>

              <TypographyComponent
                rootClassName={classes.changeSelectionText}
                styling={"p2"}
                boldness={"regular"}
                color={"iron"}
              >
                {t("You can change your selection later, if needed.")}
              </TypographyComponent>
            </div>
          )}
          {!awardedProductName && (
            <div className={classNames(classes.confirmVendor)}>
              <div className={classNames(classes.confirmSelectedVendorIcons)}>
                <SelectedVendorIconSmall />
                <ProductLogo logo={selectionImageLoc} imageWidth="56px" imageHeight="56px" />
              </div>
              <TypographyComponent styling={"h5"} boldness={"medium"} color={"carbon"}>
                {selectionName}
              </TypographyComponent>
              <TypographyComponent
                rootClassName={classes.selectTitle}
                styling={"p2"}
                boldness={"regular"}
                color={"coal"}
              >
                {t("You can change your selection later, if needed.")}
              </TypographyComponent>
            </div>
          )}
        </Fragment>
      }
      footer={
        <div className={classNames(classes.footer)}>
          <div>
            <Button onClick={() => handleEvent(onClose)} rootClassName="btn-tertiary">
              {t("CANCEL")}
            </Button>
          </div>
          <div className={classNames(classes.footerConfirmBtn)}>
            <ButtonLoader btnClass="btn-primary" handleButtonClick={onConfirm} isLoading={isLoading}>
              {t("CONFIRM AND VIEW SUMMARY")}
            </ButtonLoader>
          </div>
        </div>
      }
    />
  );
};
export default AwardProductDialog;
