import { Autocomplete, Paper } from "@mui/material";
import { TextField, TypographyBoldness, TypographyComponent, TypographyStyling } from "gx-npm-ui";
import styles from "./autocomplete.styles.module.scss";
import { SyntheticEvent } from "react";
import { ArrowIcon } from "gx-npm-icons";

type AutocompleteComponentProps = {
  label: string;
  options: string[];
  isRequired?: boolean;
  handleChange: (e: SyntheticEvent<Element, Event>, val: string | null) => void;
  listOptionStyling?: TypographyStyling;
  listOptionBoldness?: TypographyBoldness;
  rootClassName?: string;
  blurOnSelect?: boolean;
  value?: string | null;
};
const AutocompleteComponent = ({
  label = "",
  options = [],
  isRequired = false,
  handleChange = () => null,
  listOptionStyling = "p3",
  listOptionBoldness = "medium",
  rootClassName = "",
  blurOnSelect = true,
  value,
}: AutocompleteComponentProps) => {
  return (
    <div className={styles.root}>
      <Autocomplete
        value={value}
        disableClearable={!!value}
        renderInput={(params) => <TextField {...params} label={label} required={isRequired} />}
        options={options}
        onChange={(e, val) => handleChange(e, val)}
        className={rootClassName}
        blurOnSelect={blurOnSelect}
        popupIcon={<ArrowIcon />}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <TypographyComponent styling={listOptionStyling} boldness={listOptionBoldness}>
                {option}
              </TypographyComponent>
            </li>
          );
        }}
        classes={{
          listbox: styles.listBox,
        }}
        PaperComponent={({ children }) => (
          <Paper
            classes={{
              root: styles.dropdownPaperComponent,
            }}
          >
            {children}
          </Paper>
        )}
      />
    </div>
  );
};

export default AutocompleteComponent;
