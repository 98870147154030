import classNames from "classnames";
import styles from "./scorecard-requirement.module.scss";
import { ListHeaderExpandButton } from "../../../../../../../ui/dragAndDropList/header";
import { TypographyComponent } from "gx-npm-ui";
import EvaluationScorecardAssignmentsAvatar from "../../../evaluation-scorecard-assignments-avatar.component";
import React from "react";
import { Requirement } from "../../../../../../../app.types";
import { useTranslation } from "react-i18next";
import { ScoringMode } from "gx-npm-lib";

type ScorecardRequirementProps = {
  requirement: Requirement;
  index: number;
  displayedProductsCount: number;
  requirements: Requirement[];
  requirementsOpenIdxList: number[];
  onClickExpandButton: (index: number) => void;
  onAvatarClick?: () => void;
  isOwner: boolean;
  scoringMode: ScoringMode;
};

const ScorecardRequirement = ({
  requirement,
  index,
  displayedProductsCount,
  requirements,
  requirementsOpenIdxList,
  onClickExpandButton,
  onAvatarClick,
  isOwner,
  scoringMode,
}: ScorecardRequirementProps) => {
  const { t } = useTranslation();

  if (!requirement) {
    return null;
  }

  return (
    <div
      className={classNames(
        "gx-get-element-height",
        displayedProductsCount === 0 && "right-border",
        (index === 0 || index === requirements.length - 1) && styles.noRightBorder,
        requirementsOpenIdxList.includes(index) && styles.reqRootCategoryOpen,
        styles.reqRootCategory
      )}
    >
      <ListHeaderExpandButton
        expand={requirementsOpenIdxList.includes(index)}
        setExpand={() => onClickExpandButton(index)}
      />
      <div className={styles.reqRootCategoryNameWeightWrapper}>
        <TypographyComponent boldness={"medium"} rootClassName={styles.reqRootCategoryName} styling={"p2"}>
          {requirement.name || <span>{t("Untitled category")}</span>}
        </TypographyComponent>
        <TypographyComponent boldness={"semi"} rootClassName={styles.reqRootCategoryWeight} styling={"p4"}>
          {requirement.weight}%
        </TypographyComponent>
      </div>
      <EvaluationScorecardAssignmentsAvatar
        categoriesV2={requirements.map((req: Requirement) => ({
          evaluators: req.evaluators || [],
          id: req.id,
        }))}
        id={requirement.id}
        isOwner={isOwner}
        mode={scoringMode}
        onClick={onAvatarClick}
      />
    </div>
  );
};

export default ScorecardRequirement;
