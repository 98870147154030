import { TypographyComponent } from "gx-npm-ui";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import InfoOutlineIcon from "../file-list/info-outline.icon";
import styles from "./file-hub-legal-banner.styles.module.scss";

const INTERCOM_HELP_HREF = `https://intercom.help/gartner-buysmart-help-center/en/articles/7211528-buysmart-data-privacy-security-policy`;
const FileHubLegalBannerComponent = () => {
  const { t } = useTranslation();
  const handleClickFQLink = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(INTERCOM_HELP_HREF, "_blank", "noopener");
  };
  return (
    <div className={styles.info}>
      <div className={styles.infoIcon}>
        <InfoOutlineIcon />
      </div>
      <TypographyComponent boldness="regular" color="coal" styling="p5">
        {t(
          "Documents uploaded by vendors are not reviewed by Gartner Experts and do not necessarily represent the views or opinions of Gartner. Check out our FAQ on storing client documents"
        )}
        <a href={INTERCOM_HELP_HREF} onClick={handleClickFQLink}>
          <TypographyComponent
            boldness="medium"
            color="defaultCta"
            element="span"
            rootClassName={styles.linkText}
            styling="p5"
          >
            {t("here.")}
          </TypographyComponent>
        </a>
      </TypographyComponent>
    </div>
  );
};

export default FileHubLegalBannerComponent;
