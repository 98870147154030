import { Collapse, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import {
  defaultDateRangesToNull,
  InitUserRole,
  useCaptureEventsV2,
  useUserInitAccess,
  useUserState,
  useWebSocket,
} from "gx-npm-lib";
import { NotifyWhenStickyDetachV2Component, Switch, TooltipV2, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import PropTypes from "prop-types";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EvaluationStateContext } from "../../context";
import { actionChecklistTransaction, loadChecklistData } from "../../context/actions/checklistActions";
import { operations } from "../../context/actions/operationTypes";
import { DeleteEntityDialog } from "../../ui/dialogs";
import { DragAndDropList, Fade } from "../../ui/dragAndDropList";
import { ListFooter, ListFooterButtonAdd } from "../../ui/dragAndDropList/footer";
import {
  ListAvatar,
  ListDatePicker,
  ListItemButtonDelete,
  ListItemsContainer,
  ListItemTextArea,
} from "../../ui/dragAndDropList/body";
import { ListHeader, ListHeaderExpandButton } from "../../ui/dragAndDropList/header";
import { getExpandCollapse, persistExpandCollapse, setExpandCollapse } from "../../ui/dragAndDropList/lib";
import { TabSectionLoader } from "../../ui/loader";
import { MemorizedChecklistListItem } from "./components/checklistListItem";
import ListButtonStatus from "./components/listButtonStatus";
import styles from "./checklist.styles";
import interpretChecklistWsMessages from "./ws-interpreter.lib";
import ChecklistHeaderV2Component from "./checklist-header-v2/checklist-header-v2.component";
import ChecklistAssignmentComponent from "./components/checklist-assignment/checklist-assignment.component";
import SmallUsersIcon from "./icons/small-users.icon";
import SmallDateCalendarIcon from "./icons/small-date-calendar.icon";
import { ClientEvent } from "../../app.constants";
import { GCOM_3438__fixChecklistAlignement } from "../../lib/feature-flags";

const ADD_DELAY_MS = 250;
const DEFAULT_DATE = "1970-01-01T00:00:00.000Z";
const FADE_DURATION_MS = 700;
const PAGE_HEADER_HEIGHT_IN_PX = 48;

const propTypes = { initiativeId: PropTypes.string };
const useStyles = makeStyles(() => styles);
const CheckListSection = ({ initiativeId }) => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const [state, dispatch] = useContext(EvaluationStateContext);
  const [addIndexes, setAddIndexes] = useState({ newTaskFocus: false, addTaskAnimation: false });
  const [addPhase, setAddPhase] = useState(-1);
  const [addTask, setAddTask] = useState(-1);
  const [deleteIndexes, setDeleteIndexes] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteTaskAnimation, setDeleteTaskAnimation] = useState(0);
  const [expandTimes, setExpandTimes] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [latestWSMessage, setLatestWSMessage] = useState({});
  const [sessionStorageId, setSessionStorageId] = useState("");
  const [isMyAssignedTaskToggled, setIsMyAssignedTaskToggled] = useState(false);
  const [refElement, setRefElement] = useState();
  const [activeChecklist, setActiveChecklist] = useState();
  const [wsMessage] = useWebSocket();
  const { email } = useUserState();
  const { role } = useUserInitAccess(initiativeId);
  const captureEvents = useCaptureEventsV2();
  const isFf3438On = useFeatureFlag(GCOM_3438__fixChecklistAlignement);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    const id = `initiatives-checklist-expand-collapse-${initiativeId}`;
    setSessionStorageId(id);
    loadChecklistData(null, dispatch, { initiativeId });
  }, [dispatch, initiativeId]);

  useEffect(() => {
    persistExpandCollapse(sessionStorageId, state.checklist.list);
  }, [sessionStorageId, state.checklist.list]);

  useEffect(() => {
    if (!wsMessage || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    setLatestWSMessage({ ...wsMessage });
  }, [initiativeId, wsMessage]);

  useEffect(() => {
    if (latestWSMessage.hasBeenInterpreted) {
      return;
    }
    if (latestWSMessage.event === "RELOAD_CHECKLIST_DATA") {
      loadChecklistData(null, dispatch, { initiativeId, isReloading: true });
    } else {
      interpretChecklistWsMessages(state, dispatch, latestWSMessage);
    }
    setLatestWSMessage((prev) => {
      return { ...prev, hasBeenInterpreted: true };
    });
  }, [dispatch, initiativeId, latestWSMessage, state]);

  const handleDeleteOpen = (indexes) => {
    setDeleteIndexes(indexes);
    setDeleteOpen(true);
  };
  const handleDeleteClose = (response) => {
    setDeleteOpen(false);
    if (response) {
      setDeleteTaskAnimation(true);
    }
  };

  const handleStatusButtonClick = (phaseIndex, taskIndex) => {
    const complete = !state.checklist.list?.[phaseIndex]?.taskList?.[taskIndex]?.complete;
    handleTaskFieldUpdate(phaseIndex, taskIndex, "complete", complete);
  };

  const handleDragFinish = (phaseIndex, result) => {
    const indexSource = result.source.index;
    const data = {
      childId: state.checklist.list[phaseIndex].taskList[indexSource].id,
      indexDest: result.destination.index,
      indexSource,
      initiativeId,
      operation: operations.childReorder,
      parentId: state.checklist.list[phaseIndex].id,
      parentIndex: phaseIndex,
    };
    actionChecklistTransaction(state, dispatch, data);
  };

  const handleTaskFieldUpdate = (phaseIndex, taskIndex, key, value) => {
    const data = {
      childId: state.checklist.list[phaseIndex].taskList[taskIndex].id,
      childIndex: taskIndex,
      parentId: state.checklist.list[phaseIndex].id,
      parentIndex: phaseIndex,
      initiativeId,
      key,
      operation: operations.childEdit,
      value,
    };
    actionChecklistTransaction(state, dispatch, data);
  };

  const handleAddChecklistPhaseTask = (index) => {
    const tasksCnt = state.checklist.list?.[index]?.taskList?.length;
    const newAddIndexes = {
      addTaskAnimation: true,
      phaseIndex: index,
      newTaskFocus: true,
      taskIndex: tasksCnt || 0,
    };
    const data = {
      childIndex: state.checklist.list[index].taskList.length,
      parentId: state.checklist.list[index].id,
      parentIndex: index,
      initiativeId,
      operation: operations.childAdd,
    };
    actionChecklistTransaction(state, dispatch, data);
    setIsAdding(true);
    setAddPhase(index);
    setAddTask(tasksCnt || 0);
    setTimeout(() => {
      setAddPhase(-1);
      setAddTask(-1);
      setIsAdding(false);
      setAddIndexes(newAddIndexes);
    }, ADD_DELAY_MS);
  };

  const handleDeleteChecklistPhaseTask = (phaseIndex, taskIndex) => {
    const data = {
      childId: state.checklist.list[phaseIndex].taskList[taskIndex].id,
      childIndex: taskIndex,
      parentId: state.checklist.list[phaseIndex].id,
      parentIndex: phaseIndex,
      initiativeId,
      operation: operations.childDelete,
    };
    actionChecklistTransaction(state, dispatch, data);
  };

  const handleHeaderUpdate = (index, key, value) => {
    const data = {
      key,
      parentId: state.checklist.list[index].id,
      parentIndex: index,
      value,
      initiativeId,
      operation: operations.parentEdit,
    };
    actionChecklistTransaction(state, dispatch, data);
  };

  const handleBlur = () => {
    setAddIndexes((prevState) => ({
      ...prevState,
      newTaskFocus: false,
    }));
  };

  const handleExit = (fadeIn, fadeOut) => {
    if (fadeOut) {
      setDeleteTaskAnimation(false);
      handleDeleteChecklistPhaseTask(deleteIndexes?.phaseIndex, deleteIndexes?.taskIndex);
    } else if (fadeIn) {
      setAddIndexes((prevState) => ({
        ...prevState,
        addTaskAnimation: false,
      }));
    }
  };

  const handleMyAssignedTaskToggle = () => {
    setIsMyAssignedTaskToggled((prev) => !prev);
    captureEvents([
      {
        eventType: ClientEvent.INITIATIVE_CHECKLIST_MY_ASSIGNED_TASKS_TOGGLED,
        metaData: { initiativeId },
      },
    ]);
  };

  const getToolTipText = (task) => {
    if (role === InitUserRole.VIEWER) {
      return t("This action is only available to evaluation owners and contributors.");
    }
    return task.dueDate !== DEFAULT_DATE ? t("Edit due date") : t("Add a due date");
  };

  const handleExpand = () => {
    // trigger a recheck for ignore class on headers on expand/collapse action for NotifyWhenStickyDetachV2Component
    setExpandTimes((prev) => [...prev, Date.now()]);
  };

  return (
    <Fragment>
      <NotifyWhenStickyDetachV2Component
        dependencyData={[...state.checklist.list, ...expandTimes]}
        reference={containerRef}
        topOffsetInPx={PAGE_HEADER_HEIGHT_IN_PX}
      >
        <div className={classNames(classes.container)} ref={containerRef}>
          <div className={classNames(classes.checklistHeader)}>
            <TypographyComponent boldness={"medium"} color="carbon" styling="h3">
              {t("Evaluation checklist")}
            </TypographyComponent>

            <div style={{ clear: "both" }} />
            <div className={classes.myTasksAndLabelWrapper}>
              <Switch
                checked={isMyAssignedTaskToggled}
                onToggle={handleMyAssignedTaskToggle}
                rootClassName="gx-my-assigned-tasks-switch"
              />
              <TypographyComponent color="coal" styling="p3">
                {t("My assigned tasks")}
              </TypographyComponent>
            </div>
          </div>
          {state.checklist.isLoading && <TabSectionLoader />}
          {state.checklist.list?.map((phase, phaseIndex) => {
            return (
              <DragAndDropList
                key={phase.id || phaseIndex}
                defaultExpand={getExpandCollapse(sessionStorageId, phaseIndex)}
                handleExpand={(val) => {
                  setExpandCollapse(sessionStorageId, phaseIndex, val);
                }}
              >
                <ListHeader index={phaseIndex} sticky={48}>
                  <ListHeaderExpandButton onExpand={handleExpand} />
                  <ChecklistHeaderV2Component
                    dateRange={defaultDateRangesToNull(phase.dateRange, "1970-01-01")}
                    initId={initiativeId}
                    name={phase.name}
                    onUpdateDateRange={(range) => handleHeaderUpdate(phaseIndex, "dateRange", range)}
                    tasksCount={phase.taskList?.length || 0}
                    tasksCompleted={phase.taskList?.filter((task) => task.complete).length || 0}
                  />
                </ListHeader>
                <Collapse>
                  <div className={classNames(classes.itemsHeader)}>
                    <TypographyComponent
                      rootClassName={classNames(classes.itemName)}
                      styling={"p4"}
                      boldness={"medium"}
                      color={"iron"}
                    >
                      {t("Item name")}
                    </TypographyComponent>
                    <TypographyComponent
                      rootClassName={classNames(classes.description)}
                      styling={"p4"}
                      boldness={"medium"}
                      color={"iron"}
                    >
                      {t("Description")}
                    </TypographyComponent>
                    <div className={classes.assigneeColumn}>
                      <SmallUsersIcon />
                      <TypographyComponent styling={"p4"} boldness={"medium"} color={"iron"}>
                        {t("Assignee(s)")}
                      </TypographyComponent>
                    </div>
                    <div className={classNames(classes.dueDateColumn)}>
                      <SmallDateCalendarIcon />
                      <TypographyComponent styling={"p4"} boldness={"medium"} color={"iron"}>
                        {t("Due date")}
                      </TypographyComponent>
                    </div>
                  </div>
                  {isMyAssignedTaskToggled &&
                    !phase.taskList.some((task) =>
                      task.assignedUsers.some((assignedUser) => assignedUser.email === email)
                    ) && (
                      <div className={classes.noTasksAssignedContainer}>
                        <TypographyComponent rootClassName={"assigned-text"} color={"iron"} styling={"p3"}>
                          {t("There are no items assigned to you.")}
                        </TypographyComponent>
                      </div>
                    )}
                  <ListItemsContainer
                    draggable
                    itemList={phase.taskList}
                    onDragComplete={() => setIsDragging(false)}
                    onDragFinish={(_items, result) => handleDragFinish(phaseIndex, result)}
                    onDragStart={() => setIsDragging(true)}
                  >
                    {phase.taskList?.map((task, taskIndex) => {
                      if (isMyAssignedTaskToggled && !task.assignedUsers?.some((x) => x.email === email)) {
                        return null;
                      }
                      if (addPhase === phaseIndex && addTask === taskIndex) {
                        // early return while waiting for add completion
                        return null;
                      }
                      let autoFocus = false;
                      let fadeIn = false;
                      if (addIndexes?.phaseIndex === phaseIndex && addIndexes?.taskIndex === taskIndex) {
                        if (addIndexes.addTaskAnimation) {
                          fadeIn = true;
                        } else if (addIndexes.newTaskFocus) {
                          autoFocus = true;
                        }
                      }
                      const fadeOut =
                        deleteTaskAnimation &&
                        deleteIndexes?.phaseIndex === phaseIndex &&
                        deleteIndexes?.taskIndex === taskIndex;
                      return (
                        <Fade
                          key={taskIndex}
                          duration={FADE_DURATION_MS}
                          fadeOut={!!fadeOut}
                          fadeIn={!!fadeIn}
                          onExit={() => handleExit(fadeIn, fadeOut)}
                        >
                          <MemorizedChecklistListItem
                            draggable
                            index={taskIndex}
                            isComplete={task.complete}
                            isViewOnly={role === InitUserRole.VIEWER}
                            onBlur={() => handleBlur()}
                            taskId={task.id}
                            _activeChecklistId={activeChecklist?.id}
                            _description={task.description}
                            _name={task.name}
                            _ownerName={task.ownerName}
                            _dueDate={task.dueDate}
                            _assignedUsers={task.assignedUsers}
                          >
                            <ListButtonStatus
                              isComplete={task.complete}
                              isViewOnly={role === InitUserRole.VIEWER}
                              onClick={() => handleStatusButtonClick(phaseIndex, taskIndex)}
                              taskId={task.id}
                            />
                            <ListItemTextArea
                              autoFocus={autoFocus}
                              isViewOnly={role === InitUserRole.VIEWER}
                              handleAutoSave={(newText) => {
                                handleTaskFieldUpdate(phaseIndex, taskIndex, "name", newText);
                              }}
                              isDragging={isDragging}
                              maxAllowedChars={0}
                              placeholder={t("Enter in task name")}
                              rootBaseClassName={classNames("gx-checklist-text-area", isFf3438On && "ff3438")}
                              rootClassName="checklist-title-field"
                              type="checklist"
                              value={task.name}
                              width="25%"
                            />
                            <ListItemTextArea
                              isViewOnly={role === InitUserRole.VIEWER}
                              handleAutoSave={(newText) => {
                                handleTaskFieldUpdate(phaseIndex, taskIndex, "description", newText);
                              }}
                              isDragging={isDragging}
                              maxAllowedChars={0}
                              placeholder={t("Enter in task description")}
                              rootClassName="checklist-desc-field"
                              value={task.description}
                              rootBaseClassName={classNames("gx-checklist-text-area", isFf3438On && "ff3438")}
                              type="checklist"
                            />

                            <ListAvatar
                              isSelected={activeChecklist?.id === task.id}
                              width={"160px"}
                              disabled={role === InitUserRole.VIEWER}
                              rootClassName={classNames("checklist-owner-avatar-list", isFf3438On && "ff3438")}
                              onClick={(event) => {
                                task.assignedUsers = task.assignedUsers || [];
                                setActiveChecklist(() => task);
                                setRefElement(event.currentTarget || undefined);
                              }}
                              avatarList={task.assignedUsers || []}
                              toolTipRootClassName={classes.avatarTooltip}
                            />
                            <ListDatePicker
                              handleAutoSave={(newText) => {
                                handleTaskFieldUpdate(phaseIndex, taskIndex, "dueDate", newText);
                              }}
                              isViewOnly={role === InitUserRole.VIEWER}
                              placeholder={role !== InitUserRole.VIEWER ? t("Add date") : t("")}
                              dueDate={task.dueDate === DEFAULT_DATE ? null : task.dueDate}
                              popperRootClassName={classes.datePopper}
                              rootClassName={"gx-due-date-picker"}
                              toolTipText={getToolTipText(task)}
                              toolTipRootClassName={classes.dateTooltip}
                            />
                            {role === InitUserRole.VIEWER ? (
                              <div className="button-filler" />
                            ) : (
                              <ListItemButtonDelete onClick={() => handleDeleteOpen({ phaseIndex, taskIndex })} />
                            )}
                          </MemorizedChecklistListItem>
                        </Fade>
                      );
                    })}
                  </ListItemsContainer>
                  <ListFooter>
                    <TooltipV2
                      deactivate={role !== InitUserRole.VIEWER}
                      placement="top"
                      rootClassName={classes.addButtonTooltip}
                      title={t("This action is only available to evaluation owners and contributors.")}
                    >
                      <div>
                        {!isMyAssignedTaskToggled && (
                          <ListFooterButtonAdd
                            disabled={isAdding || role === InitUserRole.VIEWER}
                            label={t("Add new task")}
                            onClick={() => handleAddChecklistPhaseTask(phaseIndex)}
                          />
                        )}
                      </div>
                    </TooltipV2>
                  </ListFooter>
                </Collapse>
              </DragAndDropList>
            );
          })}
          <DeleteEntityDialog
            entityName="task"
            isOpen={deleteOpen}
            name={state.checklist.list?.[deleteIndexes?.phaseIndex]?.taskList?.[deleteIndexes?.taskIndex]?.name}
            onClick={(e) => handleDeleteClose(e)}
          />
        </div>
      </NotifyWhenStickyDetachV2Component>
      <ChecklistAssignmentComponent
        activeChecklist={activeChecklist}
        initiativeId={initiativeId}
        refElement={refElement}
        onClose={() => setActiveChecklist(null)}
      />
    </Fragment>
  );
};

CheckListSection.propTypes = propTypes;
export default CheckListSection;
