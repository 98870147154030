export enum AppCustomEvent {
  SUCCESSFUL_FILE_UPLOADED = "SUCCESSFUL_FILE_UPLOADED",
}

export enum ClientEvent {
  INITIATIVE_FILE_HUB_BULK_UPLOAD = "INITIATIVE_FILE_HUB_BULK_UPLOAD",
  INITIATIVE_FILE_HUB_FILE_DELETED = "INITIATIVE_FILE_HUB_FILE_DELETED",
  INITIATIVE_FILE_HUB_FILE_DOWNLOADED = "INITIATIVE_FILE_HUB_FILE_DOWNLOADED",
  INITIATIVE_FILE_HUB_FILE_PRODUCT_UPDATED = "INITIATIVE_FILE_HUB_FILE_PRODUCT_UPDATED",
  INITIATIVE_FILE_HUB_PAGE_VIEWED = "INITIATIVE_FILE_HUB_PAGE_VIEWED",
  INITIATIVE_FILE_HUB_RECOMMENDED_DOCUMENT_DOWNLOADED = "INITIATIVE_FILE_HUB_RECOMMENDED_DOCUMENT_DOWNLOADED",
  INITIATIVE_FILE_HUB_REJECTED_UPLOAD = "INITIATIVE_FILE_HUB_REJECTED_UPLOAD",
  INITIATIVE_FILE_HUB_SUCCESSFUL_UPLOAD = "INITIATIVE_FILE_HUB_SUCCESSFUL_UPLOAD",
}

export enum RejectedUploadReason {
  FILE_NAME_LENGTH_EXCEEDED_LIMIT = "FILE_NAME_LENGTH_EXCEEDED_LIMIT",
  FILE_SIZE_EXCEEDED_LIMIT = "FILE_SIZE_EXCEEDED_LIMIT",
  FILE_TYPE_NOT_ALLOWED = "FILE_TYPE_NOT_ALLOWED",
}
