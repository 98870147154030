import { colorPalette } from "gx-npm-common-styles";
import { MQTemplateResearchIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import { TextLink } from "../../../textLink";
import { TypographyComponent } from "../../../typography/typography.component";
import styles from "./magic-quad-section.styles.module.scss";

type MagicQuadSectionProps = { resId: number; templateName: string };
const MagicQuadSectionComponent = ({ resId = -1, templateName = "" }: MagicQuadSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.magicRoot}>
      <TypographyComponent color="berry" rootClassName={styles.magicHeader} styling="h5">
        <MQTemplateResearchIcon
          fill={colorPalette.interactions.berry.hex}
          stroke={colorPalette.interactions.berry.hex}
        />
        {t("Magic Quadrant")}
      </TypographyComponent>
      <TypographyComponent color="quartz" element="p" rootClassName={styles.magicDescription}>
        {t(
          "Magic Quadrants (MQs) offer visual snapshots and in-depth analyses that provide " +
            "insight into a market's direction, maturity and participants. They help you:"
        )}
      </TypographyComponent>
      <ul className={styles.magicList}>
        <li className={styles.magicListItem}>
          <TypographyComponent color="quartz" styling="p2">
            {t("Map provider strengths and challenges against specific business requirements.")}
          </TypographyComponent>
        </li>
        <li className={styles.magicListItem}>
          <TypographyComponent color="quartz" styling="p2">
            {t(
              "Gain fast insight into market imperatives and providers’ ability to deliver " +
                "on what you require today and in the future."
            )}
          </TypographyComponent>
        </li>
        <li className={styles.magicListItem}>
          <TypographyComponent color="quartz" styling="p2">
            {t(
              "Understand how a market’s providers are competitively positioned, and " +
                "the strategies they are using to compete."
            )}
          </TypographyComponent>
        </li>
      </ul>
      <div className={styles.magicDivider} />
      <TypographyComponent color="stone" element="p" rootClassName={styles.magicLearnMore}>
        {t("Learn more")}
      </TypographyComponent>
      <div>
        <TextLink
          rootClassName={styles.magicLinkText}
          href={`https://www.gartner.com/document/${resId}?ref=buysmartresearch`}
          target="_blank"
          text={`${t("Magic Quadrant for")} ${templateName}`}
          variant={"dark"}
        />
      </div>
    </div>
  );
};

export default MagicQuadSectionComponent;
