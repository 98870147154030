import classNames from "classnames";
import { MiniButton } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import ExpandCollapseCircledArrowIcon from "../../../../../icons/expand-collapse-circled-arrow.icon";
import styles from "./expand-collapse-features-button.styles.module.scss";

type ExpandCollapseFeaturesButtonComponentProps = {
  isExpanded: boolean;
  onClick: (prev: boolean) => void;
};

const ExpandCollapseFeaturesButtonComponent = ({ isExpanded, onClick }: ExpandCollapseFeaturesButtonComponentProps) => {
  const { t } = useTranslation();
  return (
    <MiniButton
      onClick={() => onClick(!isExpanded)}
      rootClassName={classNames(styles.expandCollapseBtn, isExpanded && styles.isExpanded)}
    >
      <ExpandCollapseCircledArrowIcon />
      {isExpanded ? t("Show less features") : t("Show more features")}
    </MiniButton>
  );
};

export default ExpandCollapseFeaturesButtonComponent;
