// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OTglKXBvOhn81wzD297o .gmIswXFKCDt0EAnPLdio{align-items:center;display:flex;gap:15px;padding-bottom:12px}.OTglKXBvOhn81wzD297o .ryZy3bDVdlsnugtQqpbw{padding-bottom:32px;width:400px}.OTglKXBvOhn81wzD297o .UIx9XqAWx5jf02C7uLyg{background-color:var(--color-white);height:1px;margin-bottom:12px;opacity:.2}.OTglKXBvOhn81wzD297o .Ew0xWjCoJVgQnBuqmhDq{margin-bottom:16px}.OTglKXBvOhn81wzD297o .kfvKGLd9zpKfEPQRe5q5{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/peer-insights-section/peer-insights-section.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,mBAAA,CACA,WAAA,CAGF,4CACE,mCAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".root {\n  & .header {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .description {\n    padding-bottom: 32px;\n    width: 400px;\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 1px;\n    margin-bottom: 12px;\n    opacity: 0.2;\n  }\n\n  & .learnMore {\n    margin-bottom: 16px;\n  }\n\n  & .linkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `OTglKXBvOhn81wzD297o`,
	"header": `gmIswXFKCDt0EAnPLdio`,
	"description": `ryZy3bDVdlsnugtQqpbw`,
	"divider": `UIx9XqAWx5jf02C7uLyg`,
	"learnMore": `Ew0xWjCoJVgQnBuqmhDq`,
	"linkText": `kfvKGLd9zpKfEPQRe5q5`
};
export default ___CSS_LOADER_EXPORT___;
