// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VO4i8PxdUxWQnTCYah2Q{width:100%}.kHLfQe6cfJdKzZuR_yl9{padding-left:63px;margin:0;list-style-type:none}.kHLfQe6cfJdKzZuR_yl9 .sUXt8NGoWTakzjW1QAv0{line-height:24px;box-shadow:inset 0 -1px 0 var(--color-neutrals-silver);padding:16px 32px 16px 0}.kHLfQe6cfJdKzZuR_yl9 .sUXt8NGoWTakzjW1QAv0 .jDlxsMOKxYFz8RXJBGUJ{font-style:italic}.kHLfQe6cfJdKzZuR_yl9.RPg98nbsLMH8lodkQBJK{border-right:var(--color-neutrals-silver) 1px solid}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/evaluation-scorecard/scorecard-requirements/scorecard-requirement-items/scorecard-requirement-items.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,oBAAA,CAEA,4CACE,gBAAA,CACA,sDAAA,CACA,wBAAA,CAEA,kEACE,iBAAA,CAKN,2CACE,mDAAA","sourcesContent":[".reqItemNameContainer {\n  width: 100%;\n}\n\n.reqItemList {\n  padding-left: 63px;\n  margin: 0;\n  list-style-type: none;\n\n  .reqItem {\n    line-height: 24px;\n    box-shadow: inset 0 -1px 0 var(--color-neutrals-silver);\n    padding: 16px 32px 16px 0;\n\n    .italic {\n      font-style: italic;\n    }\n  }\n}\n\n.reqItemList.rightBorder {\n  border-right: var(--color-neutrals-silver) 1px solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reqItemNameContainer": `VO4i8PxdUxWQnTCYah2Q`,
	"reqItemList": `kHLfQe6cfJdKzZuR_yl9`,
	"reqItem": `sUXt8NGoWTakzjW1QAv0`,
	"italic": `jDlxsMOKxYFz8RXJBGUJ`,
	"rightBorder": `RPg98nbsLMH8lodkQBJK`
};
export default ___CSS_LOADER_EXPORT___;
