import React, { Fragment, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ButtonLoader, useFeatureFlag } from "gx-npm-ui";
import styles from "./sign-up-form-button.styles.module.scss";
import { SignUpFormContext } from "../sign-up-form.context";
import { postAsyncRequest } from "gx-npm-lib";
import { publish } from "gx-npm-messenger-util";
import { useLocation } from "react-router-dom";
import { useGxtmParams } from "../../../../hooks/gxtm-params.hook";
import { constructValidRedirect } from "../../../../lib";
import * as reactDeviceDetect from "react-device-detect";
import { AppContext } from "../../../../app.context";
import { OnboardingUser } from "../../../../app.constants";
import MobileWarningOverlayComponent from "../../../../ui/mobile-warning-overlay/mobile-warning-overlay.component";
import OnboardingOverlayComponent from "../../../../ui/onboarding-overlay/onboarding-overlay.component";
import useTokenParam from "../../../../hooks/token-param.hook";
import { RegistrationProfileForm } from "../sign-up-form.types";
import classNames from "classnames";
import { GCOM_4110__guestLoginEnhancements } from "../../../../lib/feature-flags";

const urlPageHome = "/s/home";
const urlPageTasksDashboard = "/s/tasks-dashboard";

const SignUpButton = () => {
  const { t } = useTranslation();
  const {
    email,
    firstName,
    lastName,
    password,
    termsPolicyAgreement,
    isProcessing,
    setIsProcessing,
    isCompleteSignUpForm,
    isCompleteProfileForm,
    page,
    setPage,
    profile,
    setErrorText,
  } = useContext(SignUpFormContext);

  const {
    setShowMobileMessage,
    setRedirectUrl,
    setOnboardingUser,
    setShowOnboardingExperience,
    showOnboardingExperience,
    showMobileMessage,
  } = useContext(AppContext);
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const redirect = queryParams.get("redirect");
  const uriHash = redirect && location.hash ? location.hash : "";
  const { gxtmParams } = useGxtmParams();
  const isFF4110On = useFeatureFlag(GCOM_4110__guestLoginEnhancements);

  const { token, surveyId } = useTokenParam();

  const handleClick = async () => {
    setIsProcessing(true);
    const urlRegister = isFF4110On ? "api/v5/auth/register" : "api/v4/auth/register";
    const load: {
      email: string;
      firstName: string;
      lastName: string;
      password: string;
      termsPolicyAgreement: boolean;
      token: string;
      profile?: RegistrationProfileForm;
    } = {
      email,
      firstName,
      lastName,
      password,
      termsPolicyAgreement,
      token,
    };
    if (!surveyId) {
      load.profile = profile;
      if (!isFF4110On) {
        delete load.profile.countryCode;
        delete load.profile.state;
      }
    }

    try {
      const response = await postAsyncRequest(urlRegister, load);
      if ([200].includes(response.status)) {
        publish("WS_CONNECT");
        publish("SESSION_EVENT_LOAD");
        publish("USER_STATE_LOAD");

        let url = urlPageHome;
        if (surveyId) {
          url = urlPageTasksDashboard;
          setOnboardingUser(OnboardingUser.SURVEY_RECIPIENT);
        } else {
          setOnboardingUser(OnboardingUser.EVALUATOR);
        }
        if (gxtmParams) {
          url += "?" + gxtmParams;
        }
        if (redirect) {
          url = constructValidRedirect(redirect + uriHash);
        }
        setRedirectUrl(url);
        if (reactDeviceDetect.isMobile) {
          setShowMobileMessage(true);
        } else {
          setShowOnboardingExperience(true);
        }
      } else if (response.status === 422 && response.data?.message === "PASSWORD_REQUIREMENTS_UNSATISFIED") {
        setErrorText(t("Passwords cannot contain words that were in a previous password breach.") as string);
      } else {
        setErrorText(t("Oops... something went wrong. Please try again.") as string);
      }
    } catch (error) {
      setErrorText(t("Oops... something went wrong. Please try again.") as string);
    } finally {
      setIsProcessing(false);
    }
  };
  const closeMobileMessage = () => {
    setShowOnboardingExperience(true);
  };

  return (
    <Fragment>
      <div
        className={classNames(
          styles.signUpButtonContainer,
          isFF4110On && styles.signUpButtonContainerGCOM4110,
          isFF4110On && page === 1 && styles.topMargin
        )}
      >
        {page === 1 && !surveyId ? (
          <ButtonLoader
            ariaLabel={t("next step button")}
            btnClass="btn-primary"
            disabled={!isCompleteSignUpForm}
            isLoading={isProcessing}
            onClick={() => setPage(2)}
          >
            {t("Next: Complete Profile")}
          </ButtonLoader>
        ) : (
          <ButtonLoader
            ariaLabel={t("sign up button")}
            btnClass="btn-primary"
            disabled={surveyId ? !isCompleteSignUpForm : !isCompleteProfileForm}
            isLoading={isProcessing}
            onClick={handleClick}
            rootClassName={isFF4110On ? styles.btnGetStarted : ""}
          >
            {t("Get Started")}
          </ButtonLoader>
        )}
      </div>
      <MobileWarningOverlayComponent isOpen={showMobileMessage} onClose={closeMobileMessage} />
      <OnboardingOverlayComponent isOpen={showOnboardingExperience} />
    </Fragment>
  );
};

export default SignUpButton;
