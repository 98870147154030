import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider, FeatureFlagsAvailableContainer } from "gx-npm-ui";
import App from "./app.component";
import OnboardingComponent from "./sections/onboarding/onboarding.component";
import { HomeAppContextProvider } from "./app.context";

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <HomeAppContextProvider>
        <FeatureFlagsAvailableContainer>
          <BrowserRouter>
            <Routes>
              <Route path="/s/home" element={<App />} />
              <Route path="/s/home/onboarding" element={<OnboardingComponent />} />
            </Routes>
          </BrowserRouter>
        </FeatureFlagsAvailableContainer>
      </HomeAppContextProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
