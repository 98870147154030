import { Fragment } from "react";
import {
  StarMediumEmptyIcon,
  StarMediumFullIcon,
  StarMediumHalfIcon,
  StarSmallEmptyIcon,
  StarSmallFullIcon,
  StarSmallHalfIcon,
} from "../../../icons";
import { StarSize } from "../reviews-v2.constants";

type ReviewStarsProps = { rating: number; size?: StarSize };
const ReviewStarsComponent = ({ rating = 0, size = StarSize.MEDIUM }: ReviewStarsProps) => {
  if (!rating) {
    return <Fragment />;
  }
  const integerN = Math.floor(rating);
  const isSmall = size === StarSize.SMALL;
  const remainder = rating % 1;

  return (
    <Fragment>
      {[0, 1, 2, 3, 4].map((value) => {
        if (value <= integerN - 1) {
          return isSmall ? <StarSmallFullIcon key={value} /> : <StarMediumFullIcon key={value} />;
        } else if (value === integerN && remainder !== 0) {
          return isSmall ? <StarSmallHalfIcon key={value} /> : <StarMediumHalfIcon key={value} />;
        }
        return isSmall ? <StarSmallEmptyIcon key={value} /> : <StarMediumEmptyIcon key={value} />;
      })}
    </Fragment>
  );
};

export default ReviewStarsComponent;
