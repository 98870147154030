import { Fade, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { SelectedVendorIcon } from "gx-npm-icons";
import { InitProdState, InitStatus, InitUserRole, publishEvent, useCaptureEventsV2 } from "gx-npm-lib";
import { Button, Loader, ProductLogo, TooltipV2, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import { AvailableActions, ClientEvent, CustomEvents } from "../../app.constants";
import { useSnackbarContext } from "../../context/snack-bar-banner/snack-bar.context";
import { useWorkspaceHeaderContext } from "../../context/workspace-header.context";
import { saveProductRequestV3 } from "../../context/product/workspace-header-product-state.lib";
import useScrolling from "../../hooks/scrolling.hook";
import { GCOM_3694__updateDefaultEvaluationName } from "../../lib/feature-flags";
import ActionDialog from "./components/action-dialog/action-dialog.component";
import AwardProductDialog from "./components/award-product-dialog/award-product-dialog.component";
import ProductStateActionsPopoverMenu from "./components/product-state-actions-popover-menu/product-state-actions-popover-menu.component";
import SpinningSaveIndicator from "./components/spinning-save-indicator/spinning-save-indicator.component";
import styles from "./product-header.styles";

type ProductHeaderProps = { hasError?: boolean; isLoading?: boolean; isScreenedOut?: boolean };
const useStyles = makeStyles(() => styles);
const ProductHeader = ({ hasError = false, isLoading = false, isScreenedOut = false }: ProductHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    awardedProductImageLoc,
    awardedProductName,
    initId,
    initProdId,
    initStatus,
    productImageLoc,
    productName,
    productState,
    savingCount,
    setProductState,
    setSavingCount,
    userRole,
  } = useWorkspaceHeaderContext();
  const isScrolling = useScrolling();
  const captureEvent = useCaptureEventsV2();
  const { setShowSnackBar, setSnackbarIsError, setSnackBarMessage } = useSnackbarContext();

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [screenDialog, setScreenDialog] = useState(false);
  const [revertDialog, setRevertDialog] = useState(false);
  const [selectProduct, setSelectProduct] = useState(false);
  const [awardedProductDialog, setAwardedProductDialog] = useState(false);
  const [addBackToEvalDialog, setAddBackToEvalDialog] = useState(false);
  const isDefaultEvalNameFFOn = useFeatureFlag(GCOM_3694__updateDefaultEvaluationName);

  const handleNavigationSelection = useCallback(() => {
    const tab = "selection";
    const url = `/s/evaluation/${initId}/${tab}`;
    const showAnimation = localStorage.getItem(`showAnimationOnSelection-${initId}`);
    if (showAnimation === null) {
      localStorage.setItem(`showAnimationOnSelection-${initId}`, "true");
    }
    navigateToUrl(url);
  }, [initId]);

  useEffect(() => {
    const listener = (event: CustomEvent) => {
      setSavingCount(event.detail.count);
    };
    window.addEventListener(CustomEvents.SCORESHEET_SAVING_COUNT, listener);

    return () => {
      window.removeEventListener(CustomEvents.SCORESHEET_SAVING_COUNT, listener);
    };
  }, [setSavingCount]);

  useEffect(() => {
    if (selectProduct && productState === InitProdState.AWARDED) {
      handleNavigationSelection();
    }
  }, [handleNavigationSelection, productState, selectProduct]);

  const logoDimensions = isScrolling ? "32px" : "40px";

  const trackEvent = (action: AvailableActions) => {
    const trackedEvents: { [key: string]: ClientEvent } = {
      [AvailableActions.CHOOSE_PRODUCT]: ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_SELECT_VENDOR_CLICKED,
      [AvailableActions.SCREEN_OUT_PRODUCT]: ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_SCREENOUT_VENDOR_CLICKED,
      [AvailableActions.REMOVE_PRODUCT_FROM_EVAL]: ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_REMOVE_VENDOR_CLICKED,
    };
    captureEvent([
      {
        eventType: trackedEvents[action],
        metaData: {
          initiativeId: initId,
          initProductId: initProdId,
        },
      },
    ]);
  };

  const handleSuccess = (data: { productState: InitProdState; actionType: AvailableActions }) => {
    const { productState: InitProdStateuccess } = data;

    const snackBarMessage = {
      [InitProdState.IN_EVALUATION]: t("has been added to your evaluation."),
      [InitProdState.SCREENED_OUT]: t("has been screened out of your evaluation."),
      [InitProdState.AWARDED]: t("has been selected."),
      [InitProdState.LISTED]: t("has been deleted from your evaluation."),
    };

    publishEvent(CustomEvents.WORKSPACE_HEADER_ACTIONS, {
      productState: InitProdStateuccess,
    });

    setSnackBarMessage(
      data.actionType === AvailableActions.REVERT_AWARD
        ? `${productName} selection reverted.`
        : `${productName} ${snackBarMessage[InitProdStateuccess]}`
    );

    setProductState(InitProdStateuccess);
    setSnackbarIsError(false);
    setShowSnackBar(true);
  };

  const handleError = () => {
    setSnackbarIsError(true);
    setShowSnackBar(true);
  };

  const handleClickAddToEval = () => {
    if (isScreenedOut) {
      setAddBackToEvalDialog(true);
    } else {
      saveProductRequestV3({ initId, initProdId }, AvailableActions.ADD_PRODUCT_TO_EVAL, handleSuccess, handleError);
    }
  };

  const handleChooseProduct = () => {
    setAwardedProductDialog(true);
  };

  const handleChooseProductConfirm = () => {
    saveProductRequestV3({ initId, initProdId }, AvailableActions.CHOOSE_PRODUCT, handleSuccess, handleError);
    setAwardedProductDialog(false);
    setSelectProduct(true);
    trackEvent(AvailableActions.CHOOSE_PRODUCT);
  };

  const handleDeleteConfirmDialog = () => {
    saveProductRequestV3({ initId, initProdId }, AvailableActions.REMOVE_PRODUCT_FROM_EVAL, handleSuccess, handleError);
    setDeleteDialog(false);
    trackEvent(AvailableActions.REMOVE_PRODUCT_FROM_EVAL);
  };

  const handleScreenConfirmDialog = () => {
    saveProductRequestV3({ initId, initProdId }, AvailableActions.SCREEN_OUT_PRODUCT, handleSuccess, handleError);
    setScreenDialog(false);
    trackEvent(AvailableActions.SCREEN_OUT_PRODUCT);
  };

  const handleRevertConfirmDialog = () => {
    saveProductRequestV3({ initId, initProdId }, AvailableActions.REVERT_AWARD, handleSuccess, handleError);
    setRevertDialog(false);
    setSelectProduct(false);
  };

  const handleAddBackToEvalConfirmDialog = () => {
    saveProductRequestV3({ initId, initProdId }, AvailableActions.ADD_PRODUCT_TO_EVAL, handleSuccess, handleError);
    setAddBackToEvalDialog(false);
  };

  const handleClickStateActionsMenu = (type: AvailableActions) => {
    if (type === AvailableActions.ADD_PRODUCT_TO_EVAL) {
      handleClickAddToEval();
    }
    if (type === AvailableActions.SCREEN_OUT_PRODUCT) {
      setScreenDialog(true);
    }
    if (type === AvailableActions.CHOOSE_PRODUCT) {
      handleChooseProduct();
    }
    if (type === AvailableActions.REVERT_AWARD) {
      setRevertDialog(true);
    }
    if (type === AvailableActions.REMOVE_PRODUCT_FROM_EVAL) {
      setDeleteDialog(true);
    }
  };

  const renderProductActions = () => {
    const isViewerOrContributor = userRole === InitUserRole.VIEWER || userRole === InitUserRole.CONTRIBUTOR;
    const tooltipContent = isViewerOrContributor ? t("This action is only available to evaluation owners.") : "";
    if (productState === InitProdState.LISTED) {
      return (
        <TooltipV2
          title={tooltipContent}
          rootClassName={"gx-workspace-header-awarded-tool-tip"}
          placement="bottom"
          PopperProps={{ modifiers: { offset: { offset: "0px, 10px" } } }}
        >
          <div>
            <Button onClick={handleClickAddToEval} rootClassName="btn-secondary" disabled={isViewerOrContributor}>
              {isDefaultEvalNameFFOn ? t("Add to evaluation") : t("Add to eval")}
            </Button>
          </div>
        </TooltipV2>
      );
    }

    return (
      <div aria-label="vendor actions" className={classes.vendorActionsAdded}>
        {!isScrolling ? (
          <Fade in={!isLoading} timeout={{ enter: 500, exit: 500 }} style={{ transitionDelay: "300ms" }}>
            <span>
              <SpinningSaveIndicator isSaving={savingCount > 0} />
              <div className={classNames(classes.vendorActionsContainer)}>
                <ProductStateActionsPopoverMenu onClickMenu={handleClickStateActionsMenu} />
              </div>
            </span>
          </Fade>
        ) : (
          <SpinningSaveIndicator rootClassName={classNames("actionFadeIn")} isSaving={savingCount > 0} />
        )}
      </div>
    );
  };

  const renderMeta = () => {
    return (
      <div aria-label={t("currently selected vendor on scroll")} className={classes.meta}>
        <span className={classes.pipe} />

        {productName === awardedProductName ? (
          <TypographyComponent rootClassName={classes.status} styling={"p3"} color={"coal"} boldness={"medium"}>
            {t("Currently selected")}
          </TypographyComponent>
        ) : (
          <div className={classes.currentAwardedProduct}>
            <TypographyComponent rootClassName={classes.status} styling={"p3"} color={"coal"} boldness={"medium"}>
              {`${t("Currently selected vendor")}: `}
            </TypographyComponent>

            <span className={classes.awardedProductLogo}>
              <ProductLogo imageHeight="24px" imageWidth="24px" logo={awardedProductImageLoc} />
            </span>
            <TypographyComponent rootClassName={classes.status} styling={"p2"} color={"coal"} boldness={"medium"}>
              {awardedProductName}
            </TypographyComponent>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        classes.vendorHeader,
        isScreenedOut && "isScreenedOut",
        isScrolling && "scrolling",
        productState === InitProdState.AWARDED && "awarded"
      )}
    >
      {isLoading ? (
        <div className={classes.vendorHeaderLoader}>
          <Loader />
        </div>
      ) : (
        !hasError && (
          <>
            <div aria-label={t("vendor brand information")} className={classes.vendorBrand}>
              {productState === InitProdState.AWARDED && (
                <div className={classNames(classes.vendorIconAwarded)}>
                  <SelectedVendorIcon />
                </div>
              )}
              <ProductLogo imageWidth={logoDimensions} imageHeight={logoDimensions} logo={productImageLoc} />

              <TypographyComponent
                rootClassName={classes.vendorName}
                styling={"h4"}
                color={"carbon"}
                boldness={"medium"}
              >
                {productName}
              </TypographyComponent>

              {isScrolling && initStatus === InitStatus.SELECTED && renderMeta()}
            </div>
            <div className={classNames(classes.vendorActions)}>{renderProductActions()}</div>
          </>
        )
      )}
      <ActionDialog
        isOpen={deleteDialog}
        labels={{
          title: t("Delete vendor"),
          body1: { start: t("Are you sure you want to remove"), end: t("from your evaluation?") },
          body2: t(
            "This vendor will be removed from the Evaluation Overview, and all associated scores and comments will be permanently deleted."
          ),
          buttons: {
            cancel: t("Cancel"),
            confirm: t("Delete"),
          },
        }}
        name={productName}
        onClose={() => setDeleteDialog(false)}
        onConfirm={handleDeleteConfirmDialog}
      />
      <ActionDialog
        isOpen={addBackToEvalDialog}
        labels={{
          title: t("Add vendor back to evaluation?"),
          body1: {
            start: t("Adding"),
            end: t(
              "back to your evaluation will permanently delete any notes that were entered about why the vendor was screened out."
            ),
          },
          body2: "",
          buttons: {
            cancel: t("Cancel"),
            confirm: t("Add back to evaluation"),
          },
        }}
        name={productName}
        onClose={() => setAddBackToEvalDialog(false)}
        onConfirm={handleAddBackToEvalConfirmDialog}
      />
      <ActionDialog
        isOpen={screenDialog}
        labels={{
          title: t("Screen out vendor"),
          body1: { start: t("Are you sure you want to screen out"), end: t("from your evaluation?") },
          body2: t(
            'Screened out vendors and all associated scores and comments are always accessible from the Evaluation Overview page by clicking the toggle "Show screened-out vendors."'
          ),
          buttons: {
            cancel: t("Cancel"),
            confirm: t("Screen out"),
          },
        }}
        name={productName}
        onClose={() => setScreenDialog(false)}
        onConfirm={handleScreenConfirmDialog}
      />
      <ActionDialog
        isOpen={revertDialog}
        labels={{
          title: t("Revert this vendor selection?"),
          body1: { start: "", end: t("will no longer be marked as your currently selected vendor.") },
          body2: "",
          buttons: {
            cancel: t("Cancel"),
            confirm: t("Revert selection"),
          },
        }}
        name={productName}
        onClose={() => setRevertDialog(false)}
        onConfirm={handleRevertConfirmDialog}
      />
      <AwardProductDialog
        awardedProductImageLoc={awardedProductImageLoc}
        awardedProductName={awardedProductName}
        isLoading={isLoading}
        isOpen={awardedProductDialog}
        selectionImageLoc={productImageLoc}
        selectionName={productName}
        onClose={() => setAwardedProductDialog(false)}
        onConfirm={handleChooseProductConfirm}
      />
    </div>
  );
};

export default ProductHeader;
