import { ResearchRibbonV2Component, ResearchVariant, useFeatureFlag } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GCOM_4297__emqUpdates } from "../../../../lib/feature-flags";
import { HeaderV2Context } from "../../header-v2.context";

const InitResearchRibbonComponent = () => {
  const { t } = useTranslation();
  // todo pull mq type out of context once it is available
  const { magicQuadResId, marketGuideResId, piMarketSeoName, templateName } = useContext(HeaderV2Context);
  const [researchVariant, setResearchVariant] = useState<ResearchVariant>(ResearchVariant.NONE);
  const isEMQFFOn = useFeatureFlag(GCOM_4297__emqUpdates);
  const showAnimation = JSON.parse(localStorage.getItem("showRibbonAnimation") || "false");

  useEffect(() => {
    if (!isEMQFFOn) {
      return;
    }
    if (marketGuideResId > 0) {
      setResearchVariant(ResearchVariant.MARKET_GUIDE);
    } else if (magicQuadResId > 0 /* todo mq type check */) {
      setResearchVariant(ResearchVariant.MQ);
    } else if (marketGuideResId < 0 && magicQuadResId < 0 /* todo change  to be mq type check */) {
      setResearchVariant(ResearchVariant.EMQ);
    }
  }, [magicQuadResId, marketGuideResId, isEMQFFOn]);

  return (
    <ResearchRibbonV2Component
      autoExpand={showAnimation}
      backToText={t("EVALUATION")}
      magicQuadrantResId={magicQuadResId}
      marketGuideResId={marketGuideResId}
      piMarketSeoName={piMarketSeoName}
      researchVariant={researchVariant}
      templateName={templateName}
    />
  );
};

export default InitResearchRibbonComponent;
