// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WDUAlJ46vf13WhFmhYww{padding-right:80px}.WDUAlJ46vf13WhFmhYww .LxH9hKaSaDGgyyq9s2X_{align-items:center;display:flex;gap:15px;padding-bottom:12px}.WDUAlJ46vf13WhFmhYww .Dvbak3fi0STCDl1V2xvZ{margin-bottom:56px;width:400px}.WDUAlJ46vf13WhFmhYww .Fnit6VIQqAU2zce4A_aW{background-color:var(--color-white);height:1px;margin-bottom:12px;opacity:.2}.WDUAlJ46vf13WhFmhYww .GM8SjzlPVvLO1ENa8AlQ{margin-bottom:16px}.WDUAlJ46vf13WhFmhYww .PxqH5uYZPJ3qgVg6Cm88{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/market-guide-section/market-guide-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kBAAA,CACA,WAAA,CAGF,4CACE,mCAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".marketRoot {\n  padding-right: 80px;\n\n  & .marketHeader {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .marketParagraph {\n    margin-bottom: 56px;\n    width: 400px;\n  }\n\n  & .marketDivider {\n    background-color: var(--color-white);\n    height: 1px;\n    margin-bottom: 12px;\n    opacity: 0.2;\n  }\n\n  & .marketLearnMore {\n    margin-bottom: 16px;\n  }\n\n  & .marketTextLink {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketRoot": `WDUAlJ46vf13WhFmhYww`,
	"marketHeader": `LxH9hKaSaDGgyyq9s2X_`,
	"marketParagraph": `Dvbak3fi0STCDl1V2xvZ`,
	"marketDivider": `Fnit6VIQqAU2zce4A_aW`,
	"marketLearnMore": `GM8SjzlPVvLO1ENa8AlQ`,
	"marketTextLink": `PxqH5uYZPJ3qgVg6Cm88`
};
export default ___CSS_LOADER_EXPORT___;
