// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jo05B3zVBBJEfzo7EhtT{align-items:center;display:flex;background-color:rgba(0,0,169,.5);border:1px solid var(--color-neutrals-quartz);border-radius:60px;gap:10px;height:40px;padding-left:16px;width:295px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/slide-in-header/slide-in-header.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,iCAAA,CACA,6CAAA,CACA,kBAAA,CACA,QAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA","sourcesContent":[".root {\n  align-items: center;\n  display: flex;\n  background-color: rgba(0, 0, 169, 0.5);\n  border: 1px solid var(--color-neutrals-quartz);\n  border-radius: 60px;\n  gap: 10px;\n  height: 40px;\n  padding-left: 16px;\n  width: 295px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `jo05B3zVBBJEfzo7EhtT`
};
export default ___CSS_LOADER_EXPORT___;
