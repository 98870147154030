import classNames from "classnames";
import { ArrowCriticalIcon, ArrowFavorableIcon } from "gx-npm-icons";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { Fragment, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../lib/format-date";
import ReviewStarsComponent from "../review-stars/review-stars.component";
import { ReviewCategory, StarSize } from "../reviews-v2.constants";
import styles from "./review-item.styles.module.scss";

const addQueryParam = (url = "", queryParam: string) => {
  return url.includes("?") ? `${url}&${queryParam}` : `${url}?${queryParam}`;
};
type ReviewItemProps = {
  category: ReviewCategory;
  date: string;
  headline: string;
  id: number;
  onClick?: (event: MouseEvent<HTMLAnchorElement>, url: string) => void;
  rating: number;
  summary: string;
  url: string;
};
const ReviewItemComponent = ({
  category,
  date = "",
  headline = "",
  id = -1,
  onClick = (_e, _u) => null,
  rating = 0,
  summary = "",
  url = "",
}: ReviewItemProps) => {
  const { t } = useTranslation();
  const hasReview = id > 0;
  return (
    <div className={classNames(styles.root, category === ReviewCategory.CRITICAL && styles.noBorder)}>
      <div className={classNames(styles.heading, hasReview && styles.headingWithReview)}>
        {hasReview && (
          <Fragment>
            <ReviewStarsComponent rating={rating} size={StarSize.SMALL} />
            <TypographyComponent boldness="medium" color="gartnerBlue" rootClassName={styles.rating} styling="p2">
              {!isNaN(rating) && rating.toFixed(1)}
            </TypographyComponent>
            <TypographyComponent color="iron" rootClassName={styles.date} styling="p4">
              {formatDate(date)}
            </TypographyComponent>
          </Fragment>
        )}
        {!hasReview && <TypographyComponent styling="p3">{t("No reviews in this category")}</TypographyComponent>}
        <TypographyComponent rootClassName={styles.category}>
          {category === ReviewCategory.FAVORABLE && (
            <Fragment>
              <ArrowFavorableIcon />
              <TypographyComponent boldness="medium" color="darkPear" rootClassName={styles.catName} styling="p4">
                {t("Favorable review")}
              </TypographyComponent>
            </Fragment>
          )}
          {category === ReviewCategory.CRITICAL && (
            <Fragment>
              <ArrowCriticalIcon />
              <TypographyComponent boldness="medium" color="poisonCherry" rootClassName={styles.catName} styling="p4">
                {t("Critical review")}
              </TypographyComponent>
            </Fragment>
          )}
        </TypographyComponent>
      </div>
      {hasReview && (
        <div>
          <TypographyComponent boldness="semi" color="coal" rootClassName={styles.headline} styling="p3">
            {headline}
          </TypographyComponent>
          <TypographyComponent rootClassName={styles.summary} styling="p3">
            {summary}
          </TypographyComponent>
          <TextLink
            href={addQueryParam(url, "ref=buysmartpi")}
            onClick={(event) => onClick(event, addQueryParam(url, "ref=buysmartpi"))}
            target="_blank"
            text={t("Read full review")}
            variant="secondary"
          />
        </div>
      )}
    </div>
  );
};

export default ReviewItemComponent;
