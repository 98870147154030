import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { useUserState } from "gx-npm-lib";
import { ButtonLoader, TypographyComponent } from "gx-npm-ui";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../app.context";
import createBaseTemplateInitiative from "../../../../lib/create-base-template-initiative.lib";
import styles from "./base-template-card-v2.styles.module.scss";

const BaseTemplateCardV2Component = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    categoryId,
    categoryName,
    isOtherTemplateDisabled,
    isSearchData,
    searchTerm,
    templateData,
    setIsError,
    setIsOtherTemplateDisabled,
  } = useContext(AppContext);
  const { isEntitled } = useUserState();

  const setLoadingState = (state: boolean) => {
    setIsLoading(state);
    setIsOtherTemplateDisabled(state);
  };

  const handleClickStartEval = async () => {
    const searchResultsCount = templateData?.length || 0;
    const config = { categoryId, categoryName, isSearchData, name: "", searchResultsCount, searchTerm };
    await createBaseTemplateInitiative(config, () => setIsError(true), setLoadingState);
  };

  return (
    <Grid item md={12} lg={6} xl={6}>
      <div>
        <div aria-label={t("base template card")} className={styles.container}>
          <div className={styles.body}>
            <TypographyComponent boldness="medium" color="carbon" rootClassName={styles.techText} styling="h5">
              {t("Can’t find the market you need?")}
            </TypographyComponent>
            <TypographyComponent boldness="regular" color="coal" styling="p3">
              {t("Use our")}
              {` `}
              <TypographyComponent boldness="semi" color="coal" element="span">
                {t("base template")}
              </TypographyComponent>
              {` `}
              {t(
                "for any technology evaluation to access all the same features to manage your " +
                  "evaluation, including a comprehensive checklist, universal requirements, " +
                  "questionnaire, evaluation scorecard and selection summary."
              )}
            </TypographyComponent>
          </div>
          <div className={styles.footer}>
            <ButtonLoader
              btnClass="btn-tertiary"
              disabled={isOtherTemplateDisabled || !isEntitled}
              isLoading={isLoading}
              onClick={handleClickStartEval}
              rootClassName={classNames(
                styles.btnLoader,
                "gx-card-use-base-template",
                "gx-intercom-card-use-base-template"
              )}
            >
              {t("Start evaluation from scratch")}
            </ButtonLoader>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default BaseTemplateCardV2Component;
