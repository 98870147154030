import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";
import { SelectedVendorIcon, StarV2Icon } from "gx-npm-icons";
import { InitProdState, InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ListItem } from "../../../../../ui/dragAndDropList/body";
import { SELECTED_PRIORITY_WEIGHTS } from "../../../products.constants";
import { ProductsContext } from "../../../products.context";
import ButtonsCellComponent from "./cells/buttons-cell/buttons-cell.component";
import MagicQuadrantCellComponent from "./cells/magic-quadrant-cell/magic-quadrant-cell.component";
import { MqPosition } from "./cells/magic-quadrant-cell/magic-quadrant-cell.constants";
import PeerInsightsCellComponent from "./cells/peer-insights-cell/peer-insights-cell.component";
import PriorityScoreCellComponent from "./cells/priority-score-cell/priority-score-cell.component";
import ProductNameCellComponent from "./cells/product-name-cell/product-name-cell.component";
import { ProductListRowButtonAction } from "./product-list-row-v3.constants";
import styles from "./product-list-row-v3.styles.module.scss";
import ProposalReviewBadgeComponent from "./proposal-review-badge/proposal-review-badge.component";

const ProductListV3RowComponent = ({
  addedByUser = false,
  id = "",
  imageLoc = "",
  index = -1,
  isAdding = false,
  isRemoving = false,
  mqPosition = "",
  name = "",
  onAction = (_action: ProductListRowButtonAction) => {},
  peerRating = 0,
  peerReviewCount = 0,
  priorityScore = 0,
  state = InitProdState.LISTED,
  isEligiblePR = false,
}) => {
  const { initiativeId = "" } = useParams<{
    initiativeId: UUID;
  }>();
  const { t } = useTranslation();
  const { role } = useUserInitAccess(initiativeId);
  const { isMqBasedTemplate, priorities, products } = useContext(ProductsContext);
  const hasPrioritiesSelected = priorities.some((priority) => SELECTED_PRIORITY_WEIGHTS.includes(priority.weight));
  const isLast = index === products.length - 1;
  const isRowHighlighted = [InitProdState.IN_EVALUATION, InitProdState.AWARDED].includes(state);
  const isScreenedOut = state === InitProdState.SCREENED_OUT;

  return (
    <ListItem
      draggable={true}
      id={id}
      index={index}
      isViewOnly={[InitUserRole.VIEWER, InitUserRole.CONTRIBUTOR].includes(role as InitUserRole)}
      rootClassName={classNames(styles.root, isLast && styles.listRow, isScreenedOut && styles.screenedOut)}
    >
      {isRowHighlighted && <div className={styles.highlightedRow} />}
      {InitProdState.AWARDED === state && (
        <div className={styles.selectedVendorIcon}>
          <SelectedVendorIcon height="84" title={null as unknown as string} viewBox="0 17 40 84" width="40" />
        </div>
      )}
      {isEligiblePR && (
        <ProposalReviewBadgeComponent rootClassName={styles.prBadgeOffset} isRowHighlighted={isRowHighlighted} />
      )}
      <ProductNameCellComponent imageLoc={imageLoc} initProdId={id} name={name} isScreenedOut={isScreenedOut} />
      <div className={styles.rowRightColumns}>
        <div
          className={classNames(
            styles.middleColumn,
            addedByUser && isMqBasedTemplate && styles.doubleSizeColumn,
            hasPrioritiesSelected && styles.threeMiddleColumns
          )}
        >
          {addedByUser && (
            <TypographyComponent
              boldness={"medium"}
              color={"coal"}
              rootClassName={styles.addedByUserText}
              styling={"p3"}
            >
              {t("Added by you")}
            </TypographyComponent>
          )}
          {!addedByUser && isMqBasedTemplate && <MagicQuadrantCellComponent mqPosition={mqPosition as MqPosition} />}
        </div>
        {!addedByUser && (
          <div className={classNames(styles.middleColumn, hasPrioritiesSelected && styles.threeMiddleColumns)}>
            <PeerInsightsCellComponent rating={peerRating} reviewCount={peerReviewCount} />
          </div>
        )}
        {hasPrioritiesSelected && (
          <div className={classNames(styles.middleColumn, styles.threeMiddleColumns)}>
            {priorityScore > 0 && (
              <PriorityScoreCellComponent name={name} priorityScore={priorityScore} rank={index + 1} />
            )}
          </div>
        )}
        <div className={styles.lastColumn}>
          {state !== InitProdState.AWARDED && (
            <ButtonsCellComponent
              addedByUser={addedByUser}
              isAdding={isAdding}
              isRemoving={isRemoving}
              onClick={onAction}
              state={state}
              name={name}
            />
          )}
          {state === InitProdState.AWARDED && (
            <Fragment>
              <StarV2Icon fillPath={colorPalette.status.lemon.hex} rootClassName={styles.selectedIcon} />
              <TypographyComponent boldness="medium" styling="p3">
                {t("Selected vendor")}
              </TypographyComponent>
            </Fragment>
          )}
        </div>
      </div>
    </ListItem>
  );
};

export default ProductListV3RowComponent;
