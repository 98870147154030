import { Drawer } from "@material-ui/core";
import classNames from "classnames";
import { PriorityWeightOption, useCaptureEventsV2, UUID } from "gx-npm-lib";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../button";
import SnackbarBanner from "../snackbarBanner/snackbarBanner";
import { TypographyComponent } from "../typography/typography.component";
import ConfirmCancelDialogComponent from "./confirm-cancel-dialog/confirm-cancel-dialog.component";
import { CancelDialogAction } from "./confirm-cancel-dialog/confirm-cancel-dialog.constants";
import PrioritiesFormComponent from "./priorities-form/priorities-form.component";
import ProductFitResultsComponent from "./product-fit-results/product-fit-results.component";
import {
  WeightedPriorityDrawerContext,
  WeightedPriorityDrawerContextProvider,
} from "./weighted-priority-drawer.context";
import { ClientEvent, DrawerCloseAction, WeightedDrawerLocation } from "./weighted-priority-drawer.constants";
import { ScoredProduct, WeightedPriority } from "./weighted-priority-drawer.types";
import styles from "./weighted-priority-drawer.styles.module.scss";

type CloseUpdateParams = {
  priorities: WeightedPriority[];
  products: ScoredProduct[];
};

type WeightedPriorityDrawerProps = {
  isOpen: boolean;
  onClose?: (action: DrawerCloseAction, updates?: CloseUpdateParams) => void;
  priorities?: WeightedPriority[];
  products?: ScoredProduct[];
  updateScoresUrl?: string;
  gcomCcResId?: number;
  drawerLocation?: WeightedDrawerLocation;
  initiativeId?: UUID;
  templateId?: number;
  templateName?: string;
};
export const WeightedPriorityDrawerViewComponent = ({
  isOpen = false,
  onClose = (_action, _updates) => {},
  priorities = [],
  products = [],
  updateScoresUrl = "",
  gcomCcResId = -1,
  drawerLocation = WeightedDrawerLocation.MARKET_PROFILE,
  initiativeId = "",
  templateId = -1,
  templateName = "",
}: WeightedPriorityDrawerProps) => {
  const { t } = useTranslation();
  const [ccUrl, setCcUrl] = useState("");
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const {
    hasUpdateError,
    isFooterInScrollingState,
    scoredProducts,
    setHasUpdateError,
    setScoredProducts,
    setUpdateRankUrl,
    setWeightedPriorities,
    weightedPriorities,
  } = useContext(WeightedPriorityDrawerContext);
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (gcomCcResId < 1) {
      return;
    }
    setCcUrl(`https://www.gartner.com/document/${gcomCcResId}?ref=buysmartresearch`);
  }, [gcomCcResId]);

  useEffect(() => {
    setUpdateRankUrl(updateScoresUrl);
  }, [setUpdateRankUrl, updateScoresUrl]);

  useEffect(() => {
    if (!isOpen || products.length === 0) {
      setScoredProducts([]);
      return;
    }
    setScoredProducts(products.map((p) => ({ ...p })));
  }, [isOpen, products, setScoredProducts]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setWeightedPriorities(priorities);
  }, [priorities, isOpen, setWeightedPriorities]);

  const handleSave = async () => {
    onClose(DrawerCloseAction.SAVE_CHANGES, {
      priorities: weightedPriorities,
      products: scoredProducts,
    });
    setIsSaveSuccess(true);
  };

  const handleClose = () => {
    const weightedOptions = [PriorityWeightOption.LOW, PriorityWeightOption.MEDIUM, PriorityWeightOption.HIGH];
    const clonedWeightedPriorities = [...weightedPriorities.map((pw) => ({ id: pw.id, weight: pw.weight }))]
      .filter((pw) => weightedOptions.includes(pw.weight))
      .sort();
    const clonedOriginalPriorities = [...priorities.map((pw) => ({ id: pw.id, weight: pw.weight }))]
      .filter((pw) => weightedOptions.includes(pw.weight))
      .sort();

    if (JSON.stringify(clonedWeightedPriorities) === JSON.stringify(clonedOriginalPriorities)) {
      onClose(DrawerCloseAction.DISCARD_CHANGES);
      return;
    }
    setIsConfirmCancelOpen(true);
  };

  const handleCloseConfirm = (closeAction: CancelDialogAction) => {
    setIsConfirmCancelOpen(false);
    if (closeAction === CancelDialogAction.CONFIRMED_TO_CLOSE) {
      onClose(DrawerCloseAction.DISCARD_CHANGES);
    }
  };

  const handlePoweredByLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (drawerLocation === WeightedDrawerLocation.INITIATIVE_VENDOR_TAB) {
      const eventType = ClientEvent.INITIATIVE_POWERED_BY_CRITICAL_CAPABILITIES_SLIDE_IN_LINK_CLICKED;
      const metaData = {
        initiativeId: initiativeId,
      };
      captureEvents([{ eventType: eventType, metaData: metaData }]);
    } else if (drawerLocation === WeightedDrawerLocation.MARKET_PROFILE) {
      const eventType = ClientEvent.MARKET_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_SLIDE_IN_LINK_CLICKED;
      const metaData = {
        templateId: templateId?.toString(),
        templateName: templateName,
      };
      captureEvents([{ eventType: eventType, metaData: metaData }]);
    }
    window.open(ccUrl, "_blank", "noopener");
  };

  return (
    <Fragment>
      <Drawer
        anchor="bottom"
        className={styles.slideInDrawer}
        onClose={() => handleClose()}
        open={isOpen}
        transitionDuration={500}
      >
        <div className={styles.headerWrapper}>
          <TypographyComponent rootClassName={styles.header} boldness={"medium"} styling={"h4"}>
            {t("Set your priorities")}
          </TypographyComponent>
          {gcomCcResId !== -1 && (
            <div className={styles.poweredBy}>
              <TypographyComponent rootClassName={styles.poweredByText} color={"iron"} styling={"p5"}>
                {t("Powered by")}
              </TypographyComponent>
              <a
                className={styles.poweredByLink}
                href={ccUrl}
                onClick={handlePoweredByLinkClick}
                rel="noopener"
                target="_blank"
              >
                <TypographyComponent
                  boldness="medium"
                  color="defaultCta"
                  rootClassName={styles.poweredByLinkText}
                  styling="p5"
                >
                  {t("Gartner Critical Capabilities")}
                </TypographyComponent>
              </a>
            </div>
          )}
        </div>

        <div className={styles.content}>
          <PrioritiesFormComponent />
          <div className={styles.divider} />
          <ProductFitResultsComponent />
        </div>
        <div className={classNames(isFooterInScrollingState && styles.isScrolling, styles.footerBar)}>
          <div className={styles.buttonWrapper}>
            <Button onClick={() => onClose(DrawerCloseAction.DISCARD_CHANGES)} rootClassName="btn-tertiary">
              {t("cancel")}
            </Button>
            <Button onClick={handleSave} rootClassName={classNames(styles.saveButton, "btn-primary")}>
              {t("save priorities")}
            </Button>
          </div>
        </div>
      </Drawer>
      <SnackbarBanner
        isOpen={isSaveSuccess}
        message={
          weightedPriorities.some((item) => item.weight !== PriorityWeightOption.NONE)
            ? t("Your priorities have been updated.")
            : t("Your priorities have been removed.")
        }
        setIsOpen={setIsSaveSuccess}
        type="SUCCESS"
      />
      <ConfirmCancelDialogComponent isOpen={isConfirmCancelOpen} onClose={handleCloseConfirm} />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasUpdateError} setIsOpen={setHasUpdateError} />
    </Fragment>
  );
};

export const WeightedPriorityDrawerComponent = (prop: WeightedPriorityDrawerProps) => {
  return (
    <WeightedPriorityDrawerContextProvider>
      <WeightedPriorityDrawerViewComponent {...prop} />
    </WeightedPriorityDrawerContextProvider>
  );
};
