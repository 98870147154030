// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JRLUmtIG81yQRi2DsnPH{position:fixed;top:140px;z-index:1200;background:#fff;padding-left:32px;border-top:2px solid var(--color-neutrals-pearl);box-shadow:0 3px 3px 1px var(--color-neutrals-pearl)}.mqDNpwZji01ZJqBpBEEO{display:flex}.r5U4qb72L5cXtNHx314g{max-width:370px;margin-right:18px}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/evaluation-scorecard/scorecard-docked-category/scorecard-docked-category.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,SAAA,CACA,YAAA,CACA,eAAA,CACA,iBAAA,CACA,gDAAA,CACA,oDAAA,CAGF,sBACE,YAAA,CAGF,sBACE,eAAA,CACA,iBAAA","sourcesContent":[".dockedCategory {\n  position: fixed;\n  top: 140px;\n  z-index: 1200;\n  background: #fff;\n  padding-left: 32px;\n  border-top: 2px solid var(--color-neutrals-pearl);\n  box-shadow: 0 3px 3px 1px var(--color-neutrals-pearl);\n}\n\n.rowWrapper {\n  display: flex;\n}\n\n.requirementName {\n  max-width: 370px;\n  margin-right: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dockedCategory": `JRLUmtIG81yQRi2DsnPH`,
	"rowWrapper": `mqDNpwZji01ZJqBpBEEO`,
	"requirementName": `r5U4qb72L5cXtNHx314g`
};
export default ___CSS_LOADER_EXPORT___;
