import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ClientEvent } from "../../app.constants";
import DownloadIcon from "../download.icon";
import styles from "./recommended-cards.styles.module.scss";

const RecommendedCardsComponent = () => {
  const { t } = useTranslation();
  const { initId = "", initProdId = "" } = useParams<{ initId: UUID; initProdId: UUID }>();
  const captureEvents = useCaptureEventsV2();

  const handleClick = () => {
    const metaData = {
      initiativeId: initId,
      documentName: "RFP_Template.docx",
      ...(initProdId ? { initProductId: initProdId } : {}),
    };
    captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_RECOMMENDED_DOCUMENT_DOWNLOADED, metaData }]);
  };

  return (
    <div className={styles.rootRecommendedCards}>
      <div className={styles.resourceCard}>
        <TypographyComponent boldness="medium" color="gartnerBlue" element="h3" styling="p2">
          {t("Recommended resources")}
        </TypographyComponent>
        <div className={styles.linkWrapper}>
          <a
            aria-label={t("Download RFP template document")}
            className={styles.downloadLink}
            download="RFP_Template.docx"
            href="/assets/templates/RFP_Template.docx"
            onClick={handleClick}
          >
            <DownloadIcon />
            <TypographyComponent boldness="medium" color="defaultCta" styling="p3">
              {t("RFP Template.docx")}
            </TypographyComponent>
          </a>
        </div>
        <TypographyComponent boldness="regular" color="coal" styling="p3">
          {t(
            "A comprehensive, easy-to-use template that can be tailored to support your team's technology evaluation."
          )}
        </TypographyComponent>
      </div>
    </div>
  );
};

export default RecommendedCardsComponent;
