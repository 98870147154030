import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCoal = colorPalette.neutrals.coal.hex;
const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorIron = colorPalette.neutrals.iron.hex;
const colorLightBerry = colorPalette.interactions.lightBerry.hex;
const colorLemon = colorPalette.status.lemon.hex;
const colorLightLemon = colorPalette.status.lightLemon.hex;
const colorPearl = colorPalette.neutrals.pearl.hex;
const colorPoisonCherry = colorPalette.status.poisonCherry.hex;
const colorSilver = colorPalette.neutrals.silver.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;
const colorWhite = colorPalette.basic.white.hex;
const colorPewter = colorPalette.neutrals.pewter.hex;

const weightAmtSemiBold = weightPalette.semiBold.amount;
const weightVarSemiBold = weightPalette.semiBold.variation;

const productStateActionStyles = {
  root: {
    display: "flex" as string as "flex",
    marginLeft: 32,
    marginRight: 24,
    "& $selectedVendorIcon": {
      "& svg": { borderRadius: "4px 0px 0px 0px" },
      position: "absolute",
      transform: "translate(22px, 30px)",
    },
    "& $popoverMenu": {
      "& .listItem": {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
        height: "28px",
        minWidth: "250px",
      },
      "&.awarded": {
        backgroundColor: colorLightLemon,
        "& .gx-popover-menu-item a": {
          color: colorCarbon,
        },
        "& svg path": {
          fill: colorCarbon,
        },
      },
      "&.ineval": {
        backgroundColor: colorLightBerry,
        "& svg path": {
          fill: defaultCta,
        },
      },
      "&.screened-out": {
        backgroundColor: colorPearl,
        "& svg path": {
          fill: colorCoal,
        },
      },
      "&.screened-out, &.awarded, &.ineval": {
        "&.disabled": {
          backgroundColor: colorWhite,
          "& .gx-popover-menu-selected": {
            "& p": {
              color: colorIron,
            },
          },
          "& svg path": {
            fill: colorIron,
          },
        },
        "& .gx-popover-menu-selected": {
          "& p": {
            color: colorCarbon,
          },
        },
      },
      "&.select": {
        padding: "10px 12px",
        width: 220,
        "& .gx-popover-menu-selected": {
          width: "100%",
          "& p": {
            color: colorCoal,
            fontSize: "16px",
            fontVariationSettings: weightVarSemiBold,
            fontWeight: weightAmtSemiBold,
          },
        },
        "& .gx-popover-menu-list": {
          marginTop: 6,
          borderRadius: 4,
          border: `2px solid ${colorSilver}`,
          boxShadow: "0px 3px 3px 1px rgba(20, 19, 18, 0.10)",
          width: "auto",
        },
        "&.ineval, &.screened-out, &.awarded": {
          paddingInline: "20px",
          borderRadius: "30px",
          minWidth: "250px",
        },
        "&.disabled": {
          "&.awarded, &.ineval, &.screened-out": {
            border: `2px solid ${colorSilver}`,
          },
        },
        "&.awarded": {
          border: `2px solid ${colorLightLemon}`,
          "& .gx-popover-menu-selected": {
            "& p": {
              color: colorCarbon,
            },
          },
        },
        "&.ineval": {
          border: `2px solid ${colorLightBerry}`,
          "& .gx-popover-menu-selected": {
            "& p": {
              color: defaultCta,
            },
          },
          "&:hover:not(.disabled)": {
            border: `2px solid ${defaultCta}`,
          },
          "&.disabled": {
            "& svg path": {
              fill: colorIron,
            },
            "& .gx-popover-menu-selected": {
              "& p": {
                color: colorIron,
              },
            },
          },
        },
        "&.screened-out": {
          border: `2px solid ${colorPearl}`,
        },
        "&.screened-out:hover:not(.disabled)": {
          border: `2px solid ${colorCoal}`,
        },
        "&.awarded:hover:not(.disabled)": {
          border: `2px solid ${colorLemon}`,
          "& .gx-popover-menu-selected": {
            "& p": {
              color: colorCarbon,
            },
          },
        },
        "& .screenOutOption": {
          marginTop: "8px",
          "& svg path": {
            fill: colorPewter,
          },
        },
        "& .selectVendorOption": {
          marginBottom: "8px",
          "& svg path": {
            fill: colorLemon,
          },
        },
        "& .removeVendorOption": {
          display: "flex",
          alignItems: "center",
          borderTop: `${"1px solid " + colorSilver}`,
          height: "44px",
          "& svg path": {
            fill: colorPewter,
          },
          "&:hover": {
            "& svg path": {
              fill: colorPoisonCherry,
            },
          },
        },
        "& .addBackToEvalOption, & .revertVendorOption": {
          display: "flex",
          alignItems: "center",
          height: "44px",
          "& svg path": {
            fill: colorPewter,
          },
        },
        "&.open": {
          "&.awarded": {
            border: `2px solid ${colorLemon}`,
          },
          "&.ineval": {
            border: `2px solid ${defaultCta}`,
          },
          "&.screened-out": {
            border: `2px solid ${colorCoal}`,
          },
        },
      },
    },
  },
  popoverMenu: {},
  fontUpdates: {
    "& .popoverMenu": {
      "& .select": {
        "&.gx-popover-menu-selected p": {
          fontVariationSettings: weightPalette.medium.variation,
          fontWeight: weightPalette.medium.amount,
        },
      },
    },
  },
  selectedVendorIcon: {},
};

export default productStateActionStyles;
