import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { BaseTemplateIcon } from "gx-npm-icons";
import { useUserState } from "gx-npm-lib";
import { MiniButton, TooltipV2, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { RefObject, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../app.context";
import createBaseTemplateInitiative from "../../../../lib/create-base-template-initiative.lib";
import { GCOM_3694__updateDefaultEvaluationName } from "../../../../lib/feature-flags";
import { filterFooterStyles as styles } from "./filter-footer.style";

type FilterFooterProps = { forwardedRef: RefObject<HTMLDivElement> | null };
const useStyles = makeStyles(() => styles);
const FilterFooter = ({ forwardedRef }: FilterFooterProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDisabled, setDisabled] = useState(false);
  const { isSearchData, templateData, searchTerm, setIsError } = useContext(AppContext);
  const { isEntitled } = useUserState();
  const isDefaultEvalNameFFOn = useFeatureFlag(GCOM_3694__updateDefaultEvaluationName);

  const handleClick = async () => {
    const searchResultsCount = templateData?.length || 0;
    const config = { categoryId: 0, categoryName: "", isSearchData, searchResultsCount, searchTerm };
    await createBaseTemplateInitiative(config, () => setIsError(true), setDisabled);
  };

  return (
    <div className={classes.filterFooter} ref={forwardedRef}>
      <div className={classes.filterFooterDesc}>
        <TypographyComponent color="iron" styling="p3">
          {t("Can’t find the market you need?")}
        </TypographyComponent>
      </div>
      <TooltipV2
        placement="top"
        PopperProps={{ modifiers: { offset: { offset: "0, 10px" } } }}
        title={
          isEntitled
            ? t(
                "Use our base template for any technology evaluation to access all the same features " +
                  "to manage your evaluation, including a comprehensive checklist, universal " +
                  "requirements, questionnaire, evaluation scorecard and selection summary."
              )
            : t(
                "Your account does not have permission to create initiatives. " +
                  "Please contact your Gartner Account Manager for assistance."
              )
        }
      >
        <div>
          <MiniButton
            ariaLabel={isDefaultEvalNameFFOn ? undefined : "base template card"}
            disabled={isDisabled || !isEntitled}
            onClick={handleClick}
            rootClassName={classNames(
              "gx-filter-use-base-template",
              "gx-intercom-filter-use-base-template",
              "gx-start-from-scratch-button"
            )}
          >
            <BaseTemplateIcon />
            {isDefaultEvalNameFFOn ? t("Start evaluation from scratch") : t("Start eval from scratch")}
          </MiniButton>
        </div>
      </TooltipV2>
    </div>
  );
};

export default FilterFooter;
