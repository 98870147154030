import { colorPalette } from "gx-npm-common-styles";

const responseListStyles = {
  top: {
    display: "flex",
    justifyContent: "space-between",
    "&$productResponsesView": {
      gap: 120,
    },
  },
  topAlligned: {
    alignItems: "start",
  },
  listItemHeader: {
    display: "inline-flex",
    alignItems: "center",
    marginBottom: 4,
  },
  pipe: {
    borderRight: `1px ${colorPalette.neutrals.stone.hex} solid`,
    height: 22,
    marginLeft: 16,
    marginRight: 16,
  },
  title: {},
  description: {
    marginTop: 4,
  },
  left: {
    width: "60%",
    "&$productResponsesViewLeft": {
      maxWidth: 600,
      width: "unset",
    },
  },
  right: {
    alignItems: "center",
    display: "flex",
    marginLeft: "auto",
    "& p": { minWidth: 150 },
    "@media (max-width:1239px)": {
      marginTop: 0,
    },
    order: 2,
    "& .tool-tip": {
      display: "flex",
      width: "unset",
    },
  },
  bottom: {
    marginTop: 32,
    "& .gx-survey-responses-list-item-comments": {
      "& textarea": {
        height: 164,
        width: "calc(100% - 12px)",
        maxWidth: 600,
      },
    },
  },
  bottomMargin: {
    marginTop: 17,
  },
  name: {
    "& .italic": {
      fontStyle: "italic",
    },
  },
  commentsTitle: { marginBottom: 12 },
  commentsWrapper: { width: "60%" },
  productResponsesView: {},
  productResponsesViewLeft: {},
};

export default responseListStyles;
