import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import StandAloneViewComponent from "./views/stand-alone/stand-alone-view.component";
import ProductTabViewComponent from "./views/product-tab/product-tab-view.component";

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <BrowserRouter>
        <Routes>
          <Route path="/s/evaluation/:initId/files/all" element={<StandAloneViewComponent />} />
          <Route path="/s/evaluation/:initId/product/:initProdId/files" element={<ProductTabViewComponent />} />
        </Routes>
      </BrowserRouter>
    </FeatureFlagProvider>
  );
};

export default Root;
