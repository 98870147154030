// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ocMO9GB8CAdpNdhn0Pmg .casbB3jKFcwqZbG5SXR1{padding-bottom:32px}.ocMO9GB8CAdpNdhn0Pmg .DDCjLuVX4XOu4NaklZg_{background-color:var(--color-white);height:2px;margin-bottom:56px;opacity:.2}.ocMO9GB8CAdpNdhn0Pmg .Fn8meo6KvhSI8qaZSfGE{padding-bottom:12px}.ocMO9GB8CAdpNdhn0Pmg .NpKPegWB8zUwbIG2juhn{margin-bottom:12px;width:600px}.ocMO9GB8CAdpNdhn0Pmg .vsDU2g41oWtSAOvao5tZ{display:flex;padding-top:34px}@media(max-width: 1239px){.ocMO9GB8CAdpNdhn0Pmg .vsDU2g41oWtSAOvao5tZ{flex-wrap:wrap;gap:64px}}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/research-slide-in-v2.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,mBAAA,CAGF,4CACE,mCAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,mBAAA,CAGF,4CACE,kBAAA,CACA,WAAA,CAGF,4CACE,YAAA,CACA,gBAAA,CAEA,0BAJF,4CAKI,cAAA,CACA,QAAA,CAAA","sourcesContent":[".root {\n  & .primaryHeader {\n    padding-bottom: 32px;\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 2px;\n    margin-bottom: 56px;\n    opacity: 0.2;\n  }\n\n  & .secondaryHeader {\n    padding-bottom: 12px;\n  }\n\n  & .paragraphs {\n    margin-bottom: 12px;\n    width: 600px;\n  }\n\n  & .researchContent {\n    display: flex;\n    padding-top: 34px;\n\n    @media (max-width: 1239px) {\n      flex-wrap: wrap;\n      gap: 64px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ocMO9GB8CAdpNdhn0Pmg`,
	"primaryHeader": `casbB3jKFcwqZbG5SXR1`,
	"divider": `DDCjLuVX4XOu4NaklZg_`,
	"secondaryHeader": `Fn8meo6KvhSI8qaZSfGE`,
	"paragraphs": `NpKPegWB8zUwbIG2juhn`,
	"researchContent": `vsDU2g41oWtSAOvao5tZ`
};
export default ___CSS_LOADER_EXPORT___;
