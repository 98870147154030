import classNames from "classnames";
import { RibbonBulbIcon } from "gx-npm-icons";
import { KeyboardEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "../typography/typography.component";
import ResearchSlideInV2Component from "./research-slide-in-v2/research-slide-in-v2.component";
import styles from "./research-ribbon-v2.styles.module.scss";

type ResearchRibbonV2Props = {
  autoExpand: boolean;
  backToText: string;
  magicQuadrantResId: number;
  marketGuideResId: number;
  piMarketSeoName: string;
  templateName: string;
};
const ResearchRibbonV2Component = ({
  autoExpand = false,
  backToText = "",
  magicQuadrantResId = -1,
  marketGuideResId = -1,
  piMarketSeoName = "",
  templateName = "",
}: ResearchRibbonV2Props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShifting, setIsShifting] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      const autoCloseTimer = window.setTimeout(() => handleMouseLeave(), 3000);
      const shiftingTimer = window.setTimeout(() => setIsShifting(true), 300);
      const visibleTextTimer = window.setTimeout(() => setIsTextVisible(true), 250);
      return () => {
        clearTimeout(autoCloseTimer);
        clearTimeout(shiftingTimer);
        clearTimeout(visibleTextTimer);
      };
    }
  }, [isExpanded]);

  useEffect(() => {
    let autoCloseTimer: number;
    if (autoExpand) {
      localStorage.setItem("showRibbonAnimation", "false");
      setIsExpanded(true);
      autoCloseTimer = window.setTimeout(() => handleMouseLeave(), 3000);
    }
    return () => {
      clearTimeout(autoCloseTimer);
    };
  }, [autoExpand]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleOpenDrawer();
    }
  };

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    const timeout = window.setTimeout(() => setIsShifting(false), 300);
    setTimeout(() => setIsTextVisible(false), 300);
    setIsExpanded(false);
    return () => clearTimeout(timeout);
  };

  const handleOpenDrawer = () => {
    handleMouseLeave();
    setIsDrawerOpen(true);
  };

  return (
    <section>
      <div
        className={classNames(styles.container, isExpanded && styles.gxRibbonExpanded)}
        onClick={handleOpenDrawer}
        onKeyDown={handleKeyDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role="button"
        tabIndex={0}
      >
        {isShifting ? <RibbonBulbIcon width="32" height="32" /> : <RibbonBulbIcon />}

        <TypographyComponent
          boldness="semi"
          rootClassName={classNames(styles.ribbonCopy, isTextVisible && styles.show)}
          styling={"p4"}
        >
          {t("Gartner research behind this market")}
        </TypographyComponent>
      </div>
      <ResearchSlideInV2Component
        backToText={backToText}
        isOpen={isDrawerOpen}
        magicQuadResId={magicQuadrantResId}
        marketGuideResId={marketGuideResId}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
        piMarketSeoName={piMarketSeoName}
        templateName={templateName}
      />
    </section>
  );
};

export { ResearchRibbonV2Component };
