import classNames from "classnames";
import { InlineAlert, TextField, useFeatureFlag } from "gx-npm-ui";
import { ChangeEvent, Fragment, KeyboardEvent, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useTokenParam from "../../../../hooks/token-param.hook";
import { SignUpFormContext } from "../sign-up-form.context";
import ValidatePassword from "../validate-password/validate-password.component";
import styles from "./sign-up-form-fields.styles.module.scss";
import { GCOM_4110__guestLoginEnhancements } from "../../../../lib/feature-flags";

const SignUpFormFields = () => {
  const { t } = useTranslation();
  const {
    setEmail,
    email,
    firstName,
    lastName,
    password,
    isBreachedPassword,
    setFirstName,
    setLastName,
    setPassword,
    termsPolicyAgreement,
    setErrorText,
    errorText,
    isValidPwd,
    setIsCompleteSignUpForm,
  } = useContext(SignUpFormContext);
  const { email: tokenEmail } = useTokenParam();
  const isGCOM4110On = useFeatureFlag(GCOM_4110__guestLoginEnhancements);

  useEffect(() => {
    if (tokenEmail) {
      setEmail(tokenEmail);
    }
  }, [tokenEmail, setEmail]);

  useEffect(() => {
    if (email && firstName && lastName && isValidPwd && termsPolicyAgreement && !errorText) {
      setIsCompleteSignUpForm(true);
    } else {
      setIsCompleteSignUpForm(false);
    }
  }, [email, firstName, lastName, isValidPwd, termsPolicyAgreement, setIsCompleteSignUpForm, errorText]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, inputType: string) => {
    const targetValue = event.target.value;
    if (inputType === "password") {
      setPassword(targetValue);
    } else if (inputType === "firstName") {
      setFirstName(targetValue);
    } else if (inputType === "lastName") {
      setLastName(targetValue);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, inputType: string) => {
    if (inputType !== "password" || event?.key !== "Enter") {
      return;
    }
    event?.preventDefault();
    if (!firstName || !lastName || !password) {
      setErrorText(t("Please fill out all fields.") as string);
    } else {
      setErrorText("");
    }
  };

  return (
    <Fragment>
      <div className={styles.nameContainer}>
        <TextField
          label={t("First name")}
          onChange={(e) => handleChange(e, "firstName")}
          onKeyDown={(e) => handleKeyDown(e, "firstName")}
          placeholder={t("First name")}
          rootClassName={styles.txtFirstName}
          value={firstName}
          required
        />
        <TextField
          label="Last name"
          onChange={(e) => handleChange(e, "lastName")}
          onKeyDown={(e) => handleKeyDown(e, "lastName")}
          placeholder={t("Last name")}
          value={lastName}
          required
        />
      </div>
      <div className={styles.signUpCredFields}>
        <TextField
          disabled={true}
          label={t("Email address")}
          placeholder={t("Email address")}
          rootClassName={classNames(styles.txtEmail, isGCOM4110On && styles.txtEmailGCOM4110)}
          value={email}
          required={isGCOM4110On}
        />
        {isBreachedPassword && (
          <div className={classNames(styles.passwordErrorBannerContainer, "gx-error-inline-banner")}>
            <InlineAlert
              alertType={"error"}
              textMessage={t("Passwords cannot contain words that were in a previous password breach.")}
            />
          </div>
        )}
        <ValidatePassword />
      </div>
    </Fragment>
  );
};

export default SignUpFormFields;
