// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PDClg2gv8xFofrin5mlg{padding-right:81px}.PDClg2gv8xFofrin5mlg .qjU9oIq0LLInrxEaBTYY{align-items:center;display:flex;gap:15px;padding-bottom:12px}.PDClg2gv8xFofrin5mlg .Yyun5fEm3SkG3R_c9xfz{width:400px}.PDClg2gv8xFofrin5mlg .IEhecR4YqiKAVGee6mjK{margin-bottom:32px;padding-left:12px;width:388px}.PDClg2gv8xFofrin5mlg .IEhecR4YqiKAVGee6mjK .KddvsrAbHBd2j3VAZOz_{margin-bottom:12px}.PDClg2gv8xFofrin5mlg .IEhecR4YqiKAVGee6mjK .KddvsrAbHBd2j3VAZOz_::marker{color:var(--color-white)}.PDClg2gv8xFofrin5mlg .R03e5n2EpRTVa8PFMEg7{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.PDClg2gv8xFofrin5mlg .QEAWKiUOuPUyWwboSHBm{margin-bottom:16px}.PDClg2gv8xFofrin5mlg .YtgGaIanTdNj4AGYufdL{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/magic-quad-section/magic-quad-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".magicRoot {\n  padding-right: 81px;\n\n  & .magicHeader {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .magicDescription {\n    width: 400px;\n  }\n\n  & .magicList {\n    margin-bottom: 32px;\n    padding-left: 12px;\n    width: 388px;\n\n    & .magicListItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .magicDivider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n\n  & .magicLearnMore {\n    margin-bottom: 16px;\n  }\n\n  & .magicLinkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magicRoot": `PDClg2gv8xFofrin5mlg`,
	"magicHeader": `qjU9oIq0LLInrxEaBTYY`,
	"magicDescription": `Yyun5fEm3SkG3R_c9xfz`,
	"magicList": `IEhecR4YqiKAVGee6mjK`,
	"magicListItem": `KddvsrAbHBd2j3VAZOz_`,
	"magicDivider": `R03e5n2EpRTVa8PFMEg7`,
	"magicLearnMore": `QEAWKiUOuPUyWwboSHBm`,
	"magicLinkText": `YtgGaIanTdNj4AGYufdL`
};
export default ___CSS_LOADER_EXPORT___;
