// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qyevjNFTa941w4R8i0d9{min-width:172px;background-color:var(--color-white)}.qyevjNFTa941w4R8i0d9 .MuiCircularProgress-root{color:var(--color-status-poison-cherry)}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-list-v2/product-list-row-v3/cells/buttons-cell/buttons/remove-product-button.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,mCAAA,CAEA,gDACE,uCAAA","sourcesContent":[".removeBtnRoot {\n  min-width: 172px;\n  background-color: var(--color-white);\n\n  & :global(.MuiCircularProgress-root) {\n    color: var(--color-status-poison-cherry)\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeBtnRoot": `qyevjNFTa941w4R8i0d9`
};
export default ___CSS_LOADER_EXPORT___;
