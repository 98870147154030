import { ScoringMode } from "gx-npm-lib";
import { Fade } from "@mui/material";

import styles from "./scorecard-docked-category.module.scss";
import { Requirement } from "../../../../../../app.types";
import ScorecardRequirement from "../scorecard-requirements/scorecard-requirement/scorecard-requirement.component";
import classNames from "classnames";

type ScorecardDockedCategoryType = {
  width: number;
  requirement: Requirement;
  requirements: Requirement[];
  requirementsOpenIdxList: number[];
  displayProductsCount: number;
  index: number;
  isOwner: boolean;
  onClickExpandButton: (index: number) => void;
  onAvatarClick?: () => void;
  scoringMode: ScoringMode;
};

const ScorecardDockedCategory = ({
  width = 0,
  requirement,
  requirements,
  requirementsOpenIdxList,
  displayProductsCount,
  index,
  isOwner,
  onClickExpandButton,
  onAvatarClick,
  scoringMode,
}: ScorecardDockedCategoryType) => {
  const isRequirementClosed = requirementsOpenIdxList.indexOf(index) === -1;

  return (
    <Fade in={!isRequirementClosed}>
      <div
        style={{
          width: `${width}px`,
        }}
        className={classNames("docked-category", styles.dockedCategory)}
      >
        <div className={styles.rowWrapper}>
          <div className={styles.requirementName}>
            <ScorecardRequirement
              requirement={requirement}
              requirements={requirements}
              requirementsOpenIdxList={requirementsOpenIdxList}
              displayedProductsCount={displayProductsCount}
              index={index}
              isOwner={isOwner}
              onClickExpandButton={onClickExpandButton}
              onAvatarClick={onAvatarClick}
              scoringMode={scoringMode}
            />
          </div>
          <div>{/* TODO: add in score component from scorecard-products */}</div>
        </div>
      </div>
    </Fade>
  );
};

export default ScorecardDockedCategory;
