// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MiEU9I4eMOe9XVbYIlwQ{padding-right:80px}.MiEU9I4eMOe9XVbYIlwQ .VBrl4xGXScaRtYJYbzAv{align-items:center;display:flex;gap:15px;padding-bottom:12px}.MiEU9I4eMOe9XVbYIlwQ .vKcFamz1ivkjVuCik60w{margin-bottom:56px;width:400px}.MiEU9I4eMOe9XVbYIlwQ .KpS2m1f7F33z4p0sK5FY{background-color:var(--color-white);height:1px;margin-bottom:12px;opacity:.2}.MiEU9I4eMOe9XVbYIlwQ .C7gFmf5CXhXH7WMlUkqQ{margin-bottom:16px}.MiEU9I4eMOe9XVbYIlwQ .iBlTbbcywxBexcUM8SiZ{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/market-guide-section/market-guide-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kBAAA,CACA,WAAA,CAGF,4CACE,mCAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".marketRoot {\n  padding-right: 80px;\n\n  & .marketHeader {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .marketParagraph {\n    margin-bottom: 56px;\n    width: 400px;\n  }\n\n  & .marketDivider {\n    background-color: var(--color-white);\n    height: 1px;\n    margin-bottom: 12px;\n    opacity: 0.2;\n  }\n\n  & .marketLearnMore {\n    margin-bottom: 16px;\n  }\n\n  & .marketTextLink {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketRoot": `MiEU9I4eMOe9XVbYIlwQ`,
	"marketHeader": `VBrl4xGXScaRtYJYbzAv`,
	"marketParagraph": `vKcFamz1ivkjVuCik60w`,
	"marketDivider": `KpS2m1f7F33z4p0sK5FY`,
	"marketLearnMore": `C7gFmf5CXhXH7WMlUkqQ`,
	"marketTextLink": `iBlTbbcywxBexcUM8SiZ`
};
export default ___CSS_LOADER_EXPORT___;
