// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QZMYRIKgehGGukH80Xh9{padding-right:81px}.QZMYRIKgehGGukH80Xh9 .V82XO5kqFysa4oQ1QNfC{align-items:center;display:flex;gap:15px;padding-bottom:12px}.QZMYRIKgehGGukH80Xh9 .ZbcIxZkOtJeI71LJ4KJH{width:400px}.QZMYRIKgehGGukH80Xh9 .wjofs0Gv7pCetl6N_f02{margin-bottom:32px;padding-left:12px;width:388px}.QZMYRIKgehGGukH80Xh9 .wjofs0Gv7pCetl6N_f02 .cFoH_ZPwaK1TANdDBJTb{margin-bottom:12px}.QZMYRIKgehGGukH80Xh9 .wjofs0Gv7pCetl6N_f02 .cFoH_ZPwaK1TANdDBJTb::marker{color:var(--color-white)}.QZMYRIKgehGGukH80Xh9 .Dqk73pyxn_4JnkLKdp1V{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.QZMYRIKgehGGukH80Xh9 .OObc1kZn8zYatcW5uqAu{margin-bottom:16px}.QZMYRIKgehGGukH80Xh9 .KetwD2OS7FWpyhpvd1Sw{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/magic-quad-section/magic-quad-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".magicRoot {\n  padding-right: 81px;\n\n  & .magicHeader {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .magicDescription {\n    width: 400px;\n  }\n\n  & .magicList {\n    margin-bottom: 32px;\n    padding-left: 12px;\n    width: 388px;\n\n    & .magicListItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .magicDivider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n\n  & .magicLearnMore {\n    margin-bottom: 16px;\n  }\n\n  & .magicLinkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magicRoot": `QZMYRIKgehGGukH80Xh9`,
	"magicHeader": `V82XO5kqFysa4oQ1QNfC`,
	"magicDescription": `ZbcIxZkOtJeI71LJ4KJH`,
	"magicList": `wjofs0Gv7pCetl6N_f02`,
	"magicListItem": `cFoH_ZPwaK1TANdDBJTb`,
	"magicDivider": `Dqk73pyxn_4JnkLKdp1V`,
	"magicLearnMore": `OObc1kZn8zYatcW5uqAu`,
	"magicLinkText": `KetwD2OS7FWpyhpvd1Sw`
};
export default ___CSS_LOADER_EXPORT___;
