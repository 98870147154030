import { colorPalette } from "gx-npm-common-styles";
import { MQTemplateResearchIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import ResearchSectionComponent from "../research-section/research-section.component";

type MagicQuadSectionV2Props = { resId: number; templateName: string };
const MagicQuadSectionV2Component = ({ resId = -1, templateName = "" }: MagicQuadSectionV2Props) => {
  const { t } = useTranslation();
  return (
    <ResearchSectionComponent
      description={t(
        "Magic Quadrants (MQs) offer visual snapshots and in-depth analyses that provide " +
          "insight into a market's direction, maturity and participants. They help you:"
      )}
      href={`https://www.gartner.com/document/${resId}?ref=buysmartresearch`}
      icon={
        <MQTemplateResearchIcon
          fill={colorPalette.interactions.berry.hex}
          stroke={colorPalette.interactions.berry.hex}
        />
      }
      researchPoints={[
        t("Map provider strengths and challenges against specific business requirements."),
        t(
          "Gain fast insight into market imperatives and providers’ ability to deliver " +
            "on what you require today and in the future."
        ),
        t(
          "Understand how a market’s providers are competitively positioned, and " +
            "the strategies they are using to compete."
        ),
      ]}
      templateName={templateName}
      title={t("Magic Quadrant")}
    />
  );
};

export default MagicQuadSectionV2Component;
