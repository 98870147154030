import classNames from "classnames";
import {
  formatDate,
  getAsyncRequest,
  InitUserRole,
  useUserInitAccess,
  useUserState,
  useWebSocket,
  UUID,
} from "gx-npm-lib";
import { FreeTrialBannerComponent, Loader, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WebSocketMessageEvent } from "../../app.constants";
import { customEvents } from "../../lib";
import ActiveViewerAvatarsComponent from "./components/active-viewer-avatars/active-viewer-avatars.component";
import FileHubButtonComponent from "./components/file-hub-button/file-hub-button.component";
import InitResearchRibbonComponent from "./components/init-research-ribbon/init-research-ribbon.component";
import InitStateBannerComponent from "./components/init-state-banner/init-state-banner.component";
import InitTitleTextFieldV2Component from "./components/init-title-text-field-v2/init-title-text-field-v2.component";
import ReadOnlyBadgeV2Component from "./components/read-only-badge-v2/read-only-badge-v2.component";
import SaveIndicatorComponent from "./components/save-indicator/save-indicator.component";
import ShareButtonComponent from "./components/share-button/share-button.component";
import TabsBannerComponent from "./components/tabs-banner/tabs-banner.component";
import InitAccessRevokedDialogComponent from "./dialogs/init-access-revoked-dialog/init-access-revoked-dialog.component";
import InitDeletedDialogComponent from "./dialogs/init-deleted-dialog/init-deleted-dialog.component";
import { HeaderV2Context } from "./header-v2.context";
import { InitiativeApiResponse, WsInMsgInitReload } from "./header-v2.types";
import styles from "./header-content-v3.styles.module.scss";

const HeaderContentV3Component = () => {
  const [hasLoadError, setHasLoadError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    magicQuadResId,
    marketGuideResId,
    setInitName,
    setInitState,
    setInitStatus,
    setMagicQuadResId,
    setMarketGuideResId,
    setPiMarketSeoName,
    setStateUpdateDate,
    setTemplateName,
    templateName,
  } = useContext(HeaderV2Context);
  const [wsMessage] = useWebSocket<WsInMsgInitReload, unknown>();
  const { freeTrialDaysRemaining, freeTrialRole } = useUserState();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId);

  const fetchInitiativeData = useCallback(async () => {
    const url = `api/v2/initiatives/${initiativeId}/header`;
    const payload: InitiativeApiResponse = await getAsyncRequest(url);
    if (payload?.status !== 200 || typeof payload.data?.data !== "object") {
      setHasLoadError(true);
    } else {
      setInitName(payload.data.data.name || "");
      setInitState(payload.data.data.state || "");
      setInitStatus(payload.data.data.status || "");
      setMagicQuadResId(payload.data.data.magicQuadResId || -1);
      setMarketGuideResId(payload.data.data.marketGuideResId || -1);
      setPiMarketSeoName(payload.data.data.piMarketSeoName || "");
      setStateUpdateDate(payload.data.data.stateUpdateDate || "");
      setTemplateName(payload.data.data.templateName || "");
    }
  }, [
    initiativeId,
    setInitName,
    setInitState,
    setInitStatus,
    setMagicQuadResId,
    setMarketGuideResId,
    setPiMarketSeoName,
    setStateUpdateDate,
    setTemplateName,
  ]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    (async () => {
      setIsLoading(true);
      await fetchInitiativeData();
      setIsLoading(false);
    })();
  }, [fetchInitiativeData, initiativeId]);

  useEffect(() => {
    if (!initiativeId || !wsMessage || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    if (wsMessage.event !== WebSocketMessageEvent.RELOAD_HEADER_DATA) {
      return;
    }
    (async () => {
      await fetchInitiativeData();
    })();
  }, [fetchInitiativeData, initiativeId, wsMessage]);

  useEffect(() => {
    if (!initiativeId || !wsMessage || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    if (wsMessage.event !== WebSocketMessageEvent.UPDATE_INITIATIVE_STATE) {
      return;
    }
    setInitState(wsMessage.state);
    setStateUpdateDate(formatDate(new Date().toISOString(), "YYYY-MM-DD"));
  }, [initiativeId, setInitState, setStateUpdateDate, wsMessage]);

  useEffect(() => {
    const initStatusUpdateListener = (event: CustomEvent) => setInitStatus(event.detail.status);
    window.addEventListener(customEvents.initiativeStatusUpdate, initStatusUpdateListener);
    return () => {
      window.removeEventListener(customEvents.initiativeStatusUpdate, initStatusUpdateListener);
    };
  }, [setInitStatus]);

  return (
    <Fragment>
      <div className={styles.headerContainer}>
        <InitStateBannerComponent />
        <FreeTrialBannerComponent daysRemaining={freeTrialDaysRemaining} freeTrialRole={freeTrialRole} />
        <div className={styles.contentWrapper}>
          <div className={classNames(styles.contentNamesSection, isLoading && styles.loading)}>
            {isLoading && (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            )}
            {!isLoading && (
              <Fragment>
                <TypographyComponent color="iron" rootClassName={styles.templateNameContainer} styling="p3">
                  {templateName || ""}
                </TypographyComponent>
                <InitTitleTextFieldV2Component />
              </Fragment>
            )}
          </div>
          <div className={styles.contentTagsSection}>
            {hasLoadedAccess && (
              <div className={styles.badgeContainer}>
                {(role === InitUserRole.OWNER || role === InitUserRole.CONTRIBUTOR) && <SaveIndicatorComponent />}
                {role !== InitUserRole.OWNER && role !== InitUserRole.CONTRIBUTOR && (
                  <div className={styles.badgeReadOnlyWrapper}>
                    <ReadOnlyBadgeV2Component />
                  </div>
                )}
                <div className={styles.badgeSeparator} />
              </div>
            )}
            <div className={styles.fileButtonContainer}>
              <FileHubButtonComponent />
              <div className={styles.separator} />
            </div>
            <ShareButtonComponent />
            <ActiveViewerAvatarsComponent />
          </div>
        </div>
      </div>
      <TabsBannerComponent />
      <div className={styles.ribbonContainer}>
        {templateName && (magicQuadResId > 0 || marketGuideResId > 0) && <InitResearchRibbonComponent />}
      </div>
      <InitDeletedDialogComponent />
      <InitAccessRevokedDialogComponent />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasLoadError} setIsOpen={setHasLoadError} type="ERROR" />
    </Fragment>
  );
};

export default HeaderContentV3Component;
