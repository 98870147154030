import { ResearchRibbonV2Component, ResearchVariant, useFeatureFlag } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MarketProfileAppContext } from "../../../app.context";
import { GCOM_4297__emqUpdates } from "../../../lib/feature-flag";

const MarketResearchRibbonComponent = () => {
  const { t } = useTranslation();
  // todo pull mq type out of context once it is available
  const { name, gcomMgResId, gcomMqResId, piMarketSeoName } = useContext(MarketProfileAppContext);
  const [researchVariant, setResearchVariant] = useState<ResearchVariant>(ResearchVariant.NONE);
  const isEMQFFOn = useFeatureFlag(GCOM_4297__emqUpdates);
  const showAnimation = JSON.parse(localStorage.getItem("showRibbonAnimation") || "false");

  useEffect(() => {
    if (!isEMQFFOn) {
      return;
    }
    if (gcomMgResId > 0) {
      setResearchVariant(ResearchVariant.MARKET_GUIDE);
    } else if (gcomMqResId > 0 /* todo mq type check */) {
      setResearchVariant(ResearchVariant.MQ);
    } else if (gcomMgResId < 0 && gcomMqResId < 0 /* todo change  to be mq type check */) {
      setResearchVariant(ResearchVariant.EMQ);
    }
  }, [gcomMgResId, gcomMqResId, isEMQFFOn]);

  return (
    <ResearchRibbonV2Component
      autoExpand={showAnimation}
      backToText={t("MARKET")}
      magicQuadrantResId={gcomMqResId}
      marketGuideResId={gcomMgResId}
      piMarketSeoName={piMarketSeoName}
      researchVariant={researchVariant}
      templateName={name}
    />
  );
};

export default MarketResearchRibbonComponent;
