import styles from "./active-eval-notification.styles.module.scss";
import { IconMyInitiativesV2, InfoIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { MiniButton, TypographyComponent } from "gx-npm-ui";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import * as singleSpa from "single-spa";
import { ClientEvent } from "../../../app.constants";

type ActiveEvalNotificationProps = {
  isScrolling?: boolean;
  latestExistingInitId: UUID;
};
const ActiveEvalNotification = ({ isScrolling = false, latestExistingInitId = "" }: ActiveEvalNotificationProps) => {
  const { t } = useTranslation();
  const captureEvents = useCaptureEventsV2();
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const handleClick = () => {
    captureEvents([{ eventType: ClientEvent.MARKET_PRODUCT_VIEW_ACTIVE_EVAL_BUTTON_CLICKED }]);
    singleSpa.navigateToUrl(`/s/evaluation/${latestExistingInitId}/overview`);
  };
  return (
    <Fragment>
      {latestExistingInitId && (
        <div className={classNames(isScrolling && styles.viewEvalBannerBgScroll, styles.viewEvalBannerBg)}>
          <div className={styles.viewEvalBanner}>
            <div className={classNames(styles.infoAndButtonWrapper, isScrolling && styles.wrapperScrolled)}>
              <div className={styles.info}>
                <InfoIcon fillPath={colorPalette.neutrals.coal.hex} />
                <TypographyComponent styling={"p3"} color={"coal"} boldness={"medium"}>
                  {t("You are part of an active evaluation for this market.")}
                </TypographyComponent>
              </div>
              <div className={classNames(styles.buttonWrapper)}>
                <MiniButton
                  onMouseEnter={() => setIsButtonHovered(true)}
                  onMouseLeave={() => setIsButtonHovered(false)}
                  onClick={handleClick}
                  rootClassName={classNames(isScrolling && styles.viewEvalButtonScrolled, styles.viewEvalButton)}
                  ariaLabel="base template button"
                >
                  <IconMyInitiativesV2 fillColor={colorPalette.interactions.blueBerry.hex} />
                  <TypographyComponent
                    styling={"p3"}
                    color={isButtonHovered ? "hoverCta" : "defaultCta"}
                    boldness={"medium"}
                  >
                    {t("View Evaluation")}
                  </TypographyComponent>
                </MiniButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ActiveEvalNotification;
