// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hzI9zP3ZDAqdjXRI99wT .DxikT8qZmfHvMiiTVjFw{align-items:center;background:unset;border:unset;cursor:pointer;display:flex;justify-content:flex-start;margin-right:100px;max-width:600px;padding-left:0;padding-inline-end:0}.hzI9zP3ZDAqdjXRI99wT .DxikT8qZmfHvMiiTVjFw.mlbFJQ6FveV3pQIGc5DV img{filter:grayscale(100%)}@media(max-width: 1239px){.hzI9zP3ZDAqdjXRI99wT .DxikT8qZmfHvMiiTVjFw{margin-right:56px}}.hzI9zP3ZDAqdjXRI99wT .B_LubOtGjaas0kTnu4xL{color:var(--color-neutrals-iron);margin-left:48px}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-list-v2/product-list-row-v3/cells/product-name-cell/product-name-cell.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,kBAAA,CACA,gBAAA,CACA,YAAA,CACA,cAAA,CACA,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA,CACA,oBAAA,CACA,qEACE,sBAAA,CAEF,0BAdF,4CAeI,iBAAA,CAAA,CAGJ,4CACE,gCAAA,CACA,gBAAA","sourcesContent":[".productLinkRoot{\n  .productLink {\n    align-items: center;\n    background: unset;\n    border: unset;\n    cursor: pointer;\n    display: flex;\n    justify-content: flex-start;\n    margin-right:  100px;\n    max-width: 600px;\n    padding-left: 0;\n    padding-inline-end: 0;\n    &.screenedOut img {\n      filter: grayscale(100%);\n    }\n    @media (max-width: 1239px) {\n      margin-right:  56px;\n    }\n  }\n  & .screenedOutText {\n    color: var(--color-neutrals-iron);\n    margin-left: 48px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productLinkRoot": `hzI9zP3ZDAqdjXRI99wT`,
	"productLink": `DxikT8qZmfHvMiiTVjFw`,
	"screenedOut": `mlbFJQ6FveV3pQIGc5DV`,
	"screenedOutText": `B_LubOtGjaas0kTnu4xL`
};
export default ___CSS_LOADER_EXPORT___;
