import classNames from "classnames";
import { TypographyComponent } from "gx-npm-ui";
import { KeyboardEvent, MouseEvent, useEffect, useState } from "react";
import { TextLinkVariant } from "./text-link-v2.constants";
import { LinkTypographyColor, TextLinkV2Props } from "./text-link-v2.types";
import styles from "./text-link-v2.styles.module.scss";

const TextLinkV2Component = ({
  ariaLabel,
  disabled = false,
  href,
  onClick = (_e) => {},
  rootClassName = "",
  text = "",
  variant = TextLinkVariant.primary,
}: TextLinkV2Props) => {
  const [color, setColor] = useState<LinkTypographyColor>("defaultCta");
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (disabled) {
      setColor("iron");
    } else if (isFocused || isHovered) {
      setColor("hoverCta");
    } else if (variant === TextLinkVariant.primary) {
      setColor("defaultCta");
    }
  }, [disabled, isFocused, isHovered, variant]);

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      return;
    }
    onClick(e);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLAnchorElement>) => {
    if (disabled || e.key !== "Enter") {
      return;
    }
    onClick(e);
  };

  return (
    <a
      aria-label={ariaLabel}
      className={classNames(
        rootClassName,
        styles.root,
        disabled && styles.disabled,
        variant === TextLinkVariant.primary && styles.primary,
        variant === TextLinkVariant.secondary && styles.secondary,
        variant === TextLinkVariant.tertiary && styles.tertiary,
        variant === TextLinkVariant.dark && styles.dark
      )}
      href={href}
      onBlur={() => setIsFocused(false)}
      onClick={handleClick}
      onFocus={() => setIsFocused(true)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <TypographyComponent
        boldness="medium"
        color={color}
        element="span"
        rootClassName={styles.typography}
        styling="p3"
      >
        {text}
      </TypographyComponent>
    </a>
  );
};

export default TextLinkV2Component;
