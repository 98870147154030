// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cPcx5HxDXZrud2PVe6MZ{padding-right:81px}.cPcx5HxDXZrud2PVe6MZ .AOXhOH5tDmfpYld95hcc{align-items:center;display:flex;gap:15px;padding-bottom:12px}.cPcx5HxDXZrud2PVe6MZ .entSoqIITsW1bKw7ptt3{width:400px}.cPcx5HxDXZrud2PVe6MZ .qK_WfzELgLid7bFPAxFU{margin-bottom:32px;padding-left:12px;width:388px}.cPcx5HxDXZrud2PVe6MZ .qK_WfzELgLid7bFPAxFU .gkm93tAw3UsjTWdxyAwm{margin-bottom:12px}.cPcx5HxDXZrud2PVe6MZ .qK_WfzELgLid7bFPAxFU .gkm93tAw3UsjTWdxyAwm::marker{color:var(--color-white)}.cPcx5HxDXZrud2PVe6MZ .ODyXDxPTeC8FbPqVyrCv{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.cPcx5HxDXZrud2PVe6MZ ._y1PbxIzS9mM1TN62Cjd{margin-bottom:16px}.cPcx5HxDXZrud2PVe6MZ .tJ6g8C_OQpz1KYucn3yU{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/magic-quad-section/magic-quad-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".magicRoot {\n  padding-right: 81px;\n\n  & .magicHeader {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .magicDescription {\n    width: 400px;\n  }\n\n  & .magicList {\n    margin-bottom: 32px;\n    padding-left: 12px;\n    width: 388px;\n\n    & .magicListItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .magicDivider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n\n  & .magicLearnMore {\n    margin-bottom: 16px;\n  }\n\n  & .magicLinkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magicRoot": `cPcx5HxDXZrud2PVe6MZ`,
	"magicHeader": `AOXhOH5tDmfpYld95hcc`,
	"magicDescription": `entSoqIITsW1bKw7ptt3`,
	"magicList": `qK_WfzELgLid7bFPAxFU`,
	"magicListItem": `gkm93tAw3UsjTWdxyAwm`,
	"magicDivider": `ODyXDxPTeC8FbPqVyrCv`,
	"magicLearnMore": `_y1PbxIzS9mM1TN62Cjd`,
	"magicLinkText": `tJ6g8C_OQpz1KYucn3yU`
};
export default ___CSS_LOADER_EXPORT___;
