import React from "react";
import { makeStyles } from "@material-ui/core";
import { ProductLogo, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { additionalVendorV2Styles as styles } from "./additional-vendors-v2.styles";
import { ProductType } from "../../overview.types";
import { useTranslation } from "react-i18next";

type AdditionalVendorsV2Props = {
  onClickEvalLink: (name: string) => void;
  vendors: ProductType[];
};
const useStyles = makeStyles(() => styles);
const AdditionalVendorsV2 = ({ onClickEvalLink, vendors = [] }: AdditionalVendorsV2Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TypographyComponent boldness={"medium"} styling={"p3"} rootClassName={classes.additionalCount}>
        {`+${vendors.length} `}
        {t("more")}
      </TypographyComponent>

      {vendors.map((vendor) => (
        <TooltipV2 key={vendor.id} PopperProps={{ modifiers: { offset: { offset: "0, 12px" } } }} title={vendor.name}>
          <button
            aria-label={`navigate to ${vendor.name} scorecard`}
            className={classes.addBtnLogo}
            onClick={() => onClickEvalLink(vendor.id)}
          >
            <ProductLogo imageHeight="32px" imageWidth="32px" logo={vendor.imageLoc} name={vendor.name} />
          </button>
        </TooltipV2>
      ))}
    </div>
  );
};
export default AdditionalVendorsV2;
