import { InitUserRole, putAsyncRequest, truncateString, useUserInitAccess, UUID } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { SnackbarBanner, TextField, TooltipV2 } from "gx-npm-ui";
import { ChangeEvent, FocusEvent, Fragment, KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { HeaderV2Context } from "../../header-v2.context";
import styles from "./init-title-text-field-v2.styles.module.scss";

const CURRENT_INIT_STATE_NAME = "CURRENT_INITIATIVE";
const MAX_CHAR_LENGTH = 60;
const InitTitleTextFieldV2Component = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { t } = useTranslation();
  const [displayValue, setDisplayValue] = useState("");
  const [hasSaveError, setHasSaveError] = useState(false);
  const { initName, setInitName, setIsSavingInitName } = useContext(HeaderV2Context);
  const { role } = useUserInitAccess(initiativeId);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const truncatedName = truncateString(initName, MAX_CHAR_LENGTH, false);
    setDisplayValue(truncatedName);
  }, [initName]);

  useEffect(() => {
    updateStateSubject(CURRENT_INIT_STATE_NAME, { name: initName || t("Untitled evaluation") });
    return () => {
      updateStateSubject(CURRENT_INIT_STATE_NAME, { name: null });
    };
  }, [initName, t]);

  const handleBlur = async (event: FocusEvent<HTMLInputElement>) => {
    await handleSubmit(event.target.value || "");
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value || "";
    setDisplayValue(newValue);
  };

  const handleFocus = () => {
    setDisplayValue(initName);
    inputRef.current?.select();
  };

  const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    await handleSubmit(displayValue);
    (window.document?.activeElement as HTMLInputElement).blur();
  };

  const handleSubmit = async (newValue = "") => {
    if (newValue === initName) {
      return;
    }
    setInitName(newValue);
    setIsSavingInitName(true);
    try {
      const response = await putAsyncRequest(`api/v3/initiatives/${initiativeId}`, { name: newValue });
      if (response.status !== 200) {
        throw new Error();
      }
    } catch {
      setHasSaveError(true);
    } finally {
      setIsSavingInitName(false);
    }
  };

  return (
    <Fragment>
      <TooltipV2
        deactivate={role === InitUserRole.OWNER}
        placement="bottom"
        title={t("This action is only available to evaluation owners.")}
      >
        <div className={styles.textFieldWrapper}>
          <TextField
            disabled={role !== InitUserRole.OWNER}
            inputProps={{ maxLength: MAX_CHAR_LENGTH }}
            inputRef={inputRef}
            maxAllowedChars={MAX_CHAR_LENGTH}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            placeholder={t("Untitled evaluation")}
            rootClassName={styles.textFieldRoot}
            value={displayValue}
          />
        </div>
      </TooltipV2>
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasSaveError} setIsOpen={setHasSaveError} type="ERROR" />
    </Fragment>
  );
};

export default InitTitleTextFieldV2Component;
