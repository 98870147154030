import {
  FileTypeDocIcon,
  FileTypeDocxIcon,
  FileTypeJPegIcon,
  FileTypeMovIcon,
  FileTypeMP4Icon,
  FileTypePdfIcon,
  FileTypePNGIcon,
  FileTypePPTIcon,
  FileTypePPTxIcon,
  FileTypeTextIcon,
  FileTypeXlsIcon,
  FileTypeXlsxIcon,
} from "gx-npm-icons";
import styles from "./file-extension-icon-display.styles.module.scss";

type FileIconDisplayProps = { fileExt: string };
const FileExtensionIconDisplayComponent = ({ fileExt }: FileIconDisplayProps) => {
  return (
    <div className={styles.root}>
      {fileExt === "doc" && <FileTypeDocIcon />}
      {fileExt === "docx" && <FileTypeDocxIcon />}
      {(fileExt === "jpeg" || fileExt === "jpg") && <FileTypeJPegIcon />}
      {fileExt === "mov" && <FileTypeMovIcon />}
      {fileExt === "mp4" && <FileTypeMP4Icon />}
      {fileExt === "pdf" && <FileTypePdfIcon />}
      {fileExt === "png" && <FileTypePNGIcon />}
      {fileExt === "ppt" && <FileTypePPTIcon />}
      {fileExt === "pptx" && <FileTypePPTxIcon />}
      {fileExt === "txt" && <FileTypeTextIcon />}
      {fileExt === "xls" && <FileTypeXlsIcon />}
      {fileExt === "xlsx" && <FileTypeXlsxIcon />}
    </div>
  );
};

export default FileExtensionIconDisplayComponent;
