import { useTranslation } from "react-i18next";
import ResearchSectionComponent from "../research-section/research-section.component";
import EmqIcon from "./emq.icon";

type EmergingMarketQuadSectionProps = { resId: number; templateName: string };
const EmergingMarketQuadSectionComponent = ({ resId, templateName }: EmergingMarketQuadSectionProps) => {
  const { t } = useTranslation();
  return (
    <ResearchSectionComponent
      description={t(
        "Emerging Market Quadrants, provide a way to represent vendor " +
          "capabilities in a dynamic and fast-moving market. To do this:"
      )}
      href={`https://www.gartner.com/document/${resId}?ref=buysmartresearch`}
      icon={<EmqIcon />}
      researchPoints={[
        t(
          "We conduct ongoing information gathering about vendors via surveys and publicly " +
            "available information including vendor websites and software reviews. This is " +
            "designed to mimic the process that buyers would use when researching vendors."
        ),
        t(
          "We apply a consistent scoring methodology across all vendors and " +
            "position them in a familiar quadrant format based on the scores."
        ),
      ]}
      templateName={templateName}
      title={t("Emerging Market Quadrant")}
    />
  );
};

export default EmergingMarketQuadSectionComponent;
