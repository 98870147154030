import { UUID } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { ProductLinkComponent, TypographyComponent } from "gx-npm-ui";
import { useParams } from "react-router-dom";
import styles from "./product-name-cell.styles.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const ProductNameCellComponent = ({ imageLoc = "", initProdId = "", name = "", isScreenedOut = false }) => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { t } = useTranslation();

  const handleClick = () => {
    updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", { sourcePage: "vendor-list" });
  };

  return (
    <div className={classNames(styles.productLinkRoot)}>
      <ProductLinkComponent
        href={`/s/evaluation/${initiativeId}/product/${initProdId}/profile`}
        imageLoc={imageLoc}
        logoSize="32px"
        name={name}
        onClick={handleClick}
        rootClassName={classNames(styles.productLink, isScreenedOut && styles.screenedOut)}
        textBoldness={"medium"}
        textType="p1"
      />
      {isScreenedOut && (
        <TypographyComponent rootClassName={styles.screenedOutText} boldness="medium" color="coal" styling="p4">
          {t("Screened out")}
        </TypographyComponent>
      )}
    </div>
  );
};

export default ProductNameCellComponent;
