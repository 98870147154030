import { FeatureFlagsAvailableContainer } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import HelpCenterButtonComponent from "./help-center-button/help-center-button.component";
import VisitGartnerButtonComponent from "./visit-gartner-button/visit-gartner-button.component";
import UserLogoutSectionComponent from "./user-logout-section/user-logout-section.component";
import styles from "./footer-buttons-v2.styles.module.scss";
import React, { Dispatch, SetStateAction } from "react";

type FooterButtonsV2Props = { isExpanded?: boolean; setLogoutMenuOpen: Dispatch<SetStateAction<boolean>> };
const FooterButtonsV2Component = ({ isExpanded = false, setLogoutMenuOpen }: FooterButtonsV2Props) => {
  const { t } = useTranslation();

  return (
    <nav className={styles.navContainer}>
      <ul aria-label={t("footer button list")} className={styles.navButtonList}>
        <li className={styles.navButtonItem}>
          <VisitGartnerButtonComponent isExpanded={isExpanded} />
        </li>
        <li className={styles.navButtonItem}>
          <HelpCenterButtonComponent isExpanded={isExpanded} />
        </li>
        <li>
          <div className={styles.divider} />
        </li>
        <li>
          <FeatureFlagsAvailableContainer>
            <UserLogoutSectionComponent isExpanded={isExpanded} setLogoutMenuOpen={setLogoutMenuOpen} />
          </FeatureFlagsAvailableContainer>
        </li>
      </ul>
    </nav>
  );
};

export default FooterButtonsV2Component;
