// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MGYypZ40bHiDTzeGSFc1{padding-right:81px}.MGYypZ40bHiDTzeGSFc1 .NfBKM2FTZOawuuDvSs30{align-items:center;display:flex;gap:15px;padding-bottom:12px}.MGYypZ40bHiDTzeGSFc1 .hynPMYoHBWeUBPqQpDHs{width:400px}.MGYypZ40bHiDTzeGSFc1 .NYKyaAHHWPufOC84LR_M{margin-bottom:32px;padding-left:12px;width:388px}.MGYypZ40bHiDTzeGSFc1 .NYKyaAHHWPufOC84LR_M .Z_JwptHn0LfON4AMplGj{margin-bottom:12px}.MGYypZ40bHiDTzeGSFc1 .NYKyaAHHWPufOC84LR_M .Z_JwptHn0LfON4AMplGj::marker{color:var(--color-white)}.MGYypZ40bHiDTzeGSFc1 .u7thBFNv2GZBYEJ5NiaR{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.MGYypZ40bHiDTzeGSFc1 .V7t1UU2uUlOnvOpTc2rR{margin-bottom:16px}.MGYypZ40bHiDTzeGSFc1 .g0ThI5O5ejNrAdjEKUye{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/magic-quad-section/magic-quad-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".magicRoot {\n  padding-right: 81px;\n\n  & .magicHeader {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .magicDescription {\n    width: 400px;\n  }\n\n  & .magicList {\n    margin-bottom: 32px;\n    padding-left: 12px;\n    width: 388px;\n\n    & .magicListItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .magicDivider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n\n  & .magicLearnMore {\n    margin-bottom: 16px;\n  }\n\n  & .magicLinkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magicRoot": `MGYypZ40bHiDTzeGSFc1`,
	"magicHeader": `NfBKM2FTZOawuuDvSs30`,
	"magicDescription": `hynPMYoHBWeUBPqQpDHs`,
	"magicList": `NYKyaAHHWPufOC84LR_M`,
	"magicListItem": `Z_JwptHn0LfON4AMplGj`,
	"magicDivider": `u7thBFNv2GZBYEJ5NiaR`,
	"magicLearnMore": `V7t1UU2uUlOnvOpTc2rR`,
	"magicLinkText": `g0ThI5O5ejNrAdjEKUye`
};
export default ___CSS_LOADER_EXPORT___;
