import { HeaderV2ContextProvider } from "./header-v2.context";
import HeaderContentV3Component from "./header-content-v3.component";

const HeaderV2Component = () => {
  return (
    <HeaderV2ContextProvider>
      <HeaderContentV3Component />
    </HeaderV2ContextProvider>
  );
};

export default HeaderV2Component;
