import React, { useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { TypographyComponent } from "gx-npm-ui";
import { featureAndRankStyles as styles } from "./feature-and-rank.styles";
import { getRankText } from "./feature-and-rank.lib";
import MedalIcon from "./medal.icon";
import FeatureDescriptionPopper from "../feature-description-popper/feature-description-popper.component";
import { TopProduct } from "../../../../app.types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => styles);

type FeatureAndRankProps = {
  description: string;
  name: string;
  rank: number;
  topProducts: TopProduct[];
};

const FeatureAndRank: React.FC<FeatureAndRankProps> = ({ description, name, rank, topProducts }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleOpenFeatureDescriptionPopper = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    setIsPopperOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFeatureDescriptionPopper = () => {
    setIsPopperOpen(false);
    setAnchorEl(null);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleOpenFeatureDescriptionPopper(event);
    }
  };

  return (
    <div className={classes.container} role="listitem">
      <div
        className={classes.featureContainer}
        onClick={(event) => handleOpenFeatureDescriptionPopper(event)}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <TypographyComponent boldness={"medium"} rootClassName={classes.feature} type={"p3"}>
          {name}
        </TypographyComponent>

        {!isPopperOpen && (
          <TypographyComponent rootClassName={classes.viewDetails} boldness={"medium"} color={"coal"} styling={"p4"}>
            {t("View details")}
          </TypographyComponent>
        )}
      </div>
      <div className={classNames(classes.rankContainer, rank === 1 && "gx-medal-icon")}>
        <TypographyComponent boldness={"medium"} styling={"p2"}>
          {rank}
          {
            <sup>
              <TypographyComponent element={"span"} styling={"p4"} boldness={"medium"} rootClassName={classes.rankText}>
                {getRankText(rank)}
              </TypographyComponent>
            </sup>
          }
        </TypographyComponent>

        {rank === 1 && <MedalIcon />}
      </div>
      <FeatureDescriptionPopper
        anchorEl={anchorEl}
        description={description}
        isOpen={isPopperOpen}
        name={name}
        onClose={handleCloseFeatureDescriptionPopper}
        topProducts={topProducts}
      />
    </div>
  );
};

export default FeatureAndRank;
