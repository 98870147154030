import { makeStyles } from "@material-ui/core";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../app.constants";
import noSearchResults from "../../assets/images/no-search-results.svg";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { SearchResultType } from "../../app.types";
import { IconErrorData } from "../../icons";
import { moreFromGartnerStyles as styles } from "./more-from-gartner.styles";

const addDelimiter = (u: string) => (u.includes("?") ? "&" : "?");

const useStyles = makeStyles(() => styles);
const MoreFromGartner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initId, initProdId, isFromProductProfile, productName, productId, searchResults, hasSearchResults, isError } =
    useContext(ProductOverviewContext);
  const [searchForMoreURL, setSearchForMoreURL] = useState("");
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    const url = `https://www.gartner.com/mysearch/research/adv?${new URLSearchParams({
      termsIn: "0",
      researchStatus: "1",
      entitlement: "1",
      start: "0",
      contentType: "41,26,61,5,62,6,7,14,4,39,46,63,18,10",
      q: productName,
      ref: "buysmartdocs",
    })}`;
    setSearchForMoreURL(url);
  }, [productName]);

  const handleSearchResultClick = (event: MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    let eventType, metaData;
    if (isFromProductProfile) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED;
      metaData = { initiativeId: initId, initProductId: initProdId, productName };
    } else {
      eventType = ClientEvent.PRODUCT_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED;
      metaData = { productId: productId.toString(), productName };
    }
    captureEvents([{ eventType, metaData }]);
    window.open(url, "_blank", "noopener");
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <TypographyComponent styling={"p2"} boldness={"medium"} color={"carbon"}>
          {t("More from Gartner")}
        </TypographyComponent>
      </div>
      {isError && (
        <div className={classes.errorState}>
          <IconErrorData />
          <TypographyComponent styling={"p3"}>
            {t("Oops... we were unable to load this information.")}
          </TypographyComponent>
        </div>
      )}
      {!isError && !hasSearchResults && (
        <div className={classes.noResults}>
          <img src={noSearchResults} alt={t("no search results")} />
          <TypographyComponent styling={"p3"}>{t("No search results available.")}</TypographyComponent>
        </div>
      )}
      {!isError && hasSearchResults && (
        <>
          <div className={classes.results}>
            {searchResults.slice(0, 2).map((item: SearchResultType) => {
              const { title, summary, date, url: baseUrl } = item;
              const url = `${baseUrl}${addDelimiter(baseUrl)}ref=buysmartdocs`;
              return (
                <div className={classes.resultItem} key={item.title}>
                  <TextLink
                    href={url}
                    onClick={(event) => handleSearchResultClick(event, url)}
                    target={"_blank"}
                    text={title}
                  />
                  <TypographyComponent rootClassName={classes.dateText} styling={"p4"} boldness="medium">
                    {date}
                  </TypographyComponent>
                  <TypographyComponent rootClassName={classes.summaryText} styling={"p4"} boldness="regular">
                    {summary}
                  </TypographyComponent>
                </div>
              );
            })}
          </div>
          <div className={classes.divider} />
          <TextLink
            href={searchForMoreURL}
            onClick={(event) => handleSearchResultClick(event, searchForMoreURL)}
            target={"_blank"}
            text={t("Search for more on Gartner.com")}
            variant="secondary"
          />
        </>
      )}
    </div>
  );
};
export default MoreFromGartner;
