import classNames from "classnames";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { MQ } from "../../../../../../selection.types";
import { addQueryParam } from "../../gartner-insights.lib";
import QuadrantBox from "./components/quadrant-box/quadrant-box.component";
import styles from "./magic-quadrant.module.scss";

const MQ_POSITIONS = Object.freeze(["Challenger", "Leader", "Niche Player", "Visionary"]);
const NO_MQ_POSITION = "NONE";
type MagicQuadrantProps = { isExpanded: boolean; mq: MQ };
const MagicQuadrant = ({ mq, isExpanded }: MagicQuadrantProps) => {
  const { t } = useTranslation();
  const { qdPosition, docURL, qdDesc } = mq;

  if (qdPosition === "") {
    return null;
  }

  return (
    <div className={classNames(styles.root, isExpanded && styles.isExpanded)}>
      <div className={styles.section}>
        <TypographyComponent styling={"p3"} boldness={"medium"} rootClassName={styles.sectionTitle}>
          {t("Gartner Magic Quadrant")}
        </TypographyComponent>
      </div>
      <div className={styles.qdWrapper}>
        {qdPosition !== NO_MQ_POSITION && (
          <TypographyComponent styling={"h4"} boldness={"medium"} color={"gartnerBlue"}>
            {qdPosition}
          </TypographyComponent>
        )}
        {qdPosition === NO_MQ_POSITION && (
          <TypographyComponent styling={"p3"} rootClassName={styles.noMq}>
            {t("There is no Magic Quadrant for this technology.")}
          </TypographyComponent>
        )}
        <div className={styles.qdDetails}>
          <div className={styles.qdBox}>
            {qdPosition !== NO_MQ_POSITION &&
              MQ_POSITIONS.map((position) => (
                <QuadrantBox key={position} position={position} qdPosition={qdPosition} />
              ))}
          </div>
          <div className={styles.qdDescWrapper}>
            {qdDesc && (
              <TypographyComponent styling={"p4"} rootClassName={styles.qdDesc}>
                {qdDesc}
              </TypographyComponent>
            )}
            {docURL && (
              <TextLink
                href={addQueryParam(docURL, "ref=buysmartresearch")}
                target="_blank"
                text={t("Learn more about this Magic Quadrant")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagicQuadrant;
