import { useCaptureEventsV2 } from "gx-npm-lib";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { MouseEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ClientEvent } from "../../app.constants";
import { ProductOverviewParams } from "../../app.types";
import NoPeerReviewsSVG from "../../assets/images/no-peer-reviews.svg";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { IconErrorData } from "../../icons";
import ReviewItemComponent from "./review-item/review-item.component";
import ReviewStarsComponent from "./review-stars/review-stars.component";
import { ReviewCategory, StarSize } from "./reviews-v2.constants";
import styles from "./reviews-v2.styles.module.scss";

const addQueryParam = (url = "", queryParam: string) => {
  return url.includes("?") ? `${url}&${queryParam}` : `${url}?${queryParam}`;
};
const ReviewsV2Component = () => {
  const { t } = useTranslation();
  const captureEvents = useCaptureEventsV2();
  const [isHovered, setIsHovered] = useState(false);
  const { initiativeId = "", initProdId = "", productId = "" } = useParams<ProductOverviewParams>();
  const { hasReview, isError, isFromProductProfile, productName, review } = useContext(ProductOverviewContext);
  const { reviewCount, overallRating, reviewsURL } = review || {};
  const mostCriticalReview = review?.mostCriticalReview || {};
  const mostFavorableReview = review?.mostFavorableReview || {};

  const handleClickPoweredBy = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
    e.preventDefault();
    if (isFromProductProfile) {
      const eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_POWERED_BY_PEER_INSIGHTS_LINK_CLICKED;
      const metaData = { initiativeId, initProductId: initProdId, productName: productName };
      captureEvents([{ eventType, metaData }]);
    } else {
      const eventType = ClientEvent.PRODUCT_PROFILE_POWERED_BY_PEER_INSIGHTS_LINK_CLICKED;
      const metaData = { productId: productId.toString(), productName: productName };
      captureEvents([{ eventType, metaData }]);
    }
    window.open(url, "_blank", "noopener");
  };

  const handleClickReview = (event: MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    if (isFromProductProfile) {
      const eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_REVIEWS_LINK_CLICKED;
      const metaData = { initiativeId, initProductId: initProdId, productName: productName };
      captureEvents([{ eventType, metaData }]);
    } else {
      const eventType = ClientEvent.PRODUCT_PROFILE_REVIEWS_LINK_CLICKED;
      const metaData = { productId: productId.toString(), productName: productName };
      captureEvents([{ eventType, metaData }]);
    }
    window.open(url, "_blank", "noopener");
  };

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <TypographyComponent boldness="medium" color="carbon" rootClassName={styles.title} styling="p2">
          {t("Gartner peer rating")}
        </TypographyComponent>
        {reviewsURL && (
          <div className={styles.poweredByContainer}>
            <TypographyComponent rootClassName={styles.poweredByText} styling="p5">
              {t("Powered by")}
            </TypographyComponent>
            <a
              href={addQueryParam(reviewsURL, "ref=buysmartpi")}
              onClick={(e) => handleClickPoweredBy(e, addQueryParam(reviewsURL, "ref=buysmartpi"))}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              className={styles.poweredByLink}
            >
              <TypographyComponent boldness="medium" color={isHovered ? "hoverCta" : "defaultCta"} styling="p5">
                {t("Gartner Peer Insights")}
              </TypographyComponent>
            </a>
          </div>
        )}
      </div>
      {isError && (
        <div className={styles.error}>
          <IconErrorData />
          <TypographyComponent boldness="medium" color="coal" rootClassName={styles.errorText} styling="p3">
            {t("Oops... we were unable to load this information.")}
          </TypographyComponent>
        </div>
      )}
      {!isError && hasReview && (
        <div className={styles.reviews}>
          {overallRating && (
            <div className={styles.ratingsContainer}>
              <ReviewStarsComponent rating={overallRating} size={StarSize.MEDIUM} />
              <TypographyComponent
                boldness="medium"
                color="gartnerBlue"
                element="span"
                rootClassName={styles.ratingsText}
                styling="h4"
              >
                {!isNaN(overallRating) && overallRating.toFixed(1)}
              </TypographyComponent>
              <span className={styles.ratingsLink}>
                <TextLink
                  href={addQueryParam(reviewsURL, "ref=buysmartpi")}
                  onClick={(event) => handleClickReview(event, addQueryParam(reviewsURL, "ref=buysmartpi"))}
                  target="_blank"
                  text={`${t("See")} ${reviewCount} ${t("review(s)")}`}
                />
              </span>
            </div>
          )}
          <ReviewItemComponent
            category={ReviewCategory.FAVORABLE}
            date={mostFavorableReview.reviewDate}
            headline={mostFavorableReview.reviewHeadline}
            id={mostFavorableReview.reviewId}
            onClick={handleClickReview}
            rating={mostFavorableReview.reviewRating}
            summary={mostFavorableReview.reviewSummary}
            url={mostFavorableReview.reviewURL}
          />
          <ReviewItemComponent
            category={ReviewCategory.CRITICAL}
            date={mostCriticalReview.reviewDate}
            headline={mostCriticalReview.reviewHeadline}
            id={mostCriticalReview.reviewId}
            onClick={handleClickReview}
            rating={mostCriticalReview.reviewRating}
            summary={mostCriticalReview.reviewSummary}
            url={mostCriticalReview.reviewURL}
          />
        </div>
      )}
      {!isError && !hasReview && (
        <div className={styles.noReviews}>
          <img src={NoPeerReviewsSVG} alt={t("no peer reviews")} />
          <TypographyComponent styling={"p3"}>{t("No Peer Ratings are available.")}</TypographyComponent>
        </div>
      )}
    </div>
  );
};

export default ReviewsV2Component;
