import { createGenerateClassName, StylesProvider } from "@material-ui/core";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SnackbarContextProvider } from "./context/snack-bar-banner/snack-bar.context";
import { WorkspaceHeaderContextProvider } from "./context/workspace-header.context";
import HandleRedirectsV2Component from "./handle-redirects-v2.component";
import AppV2Component from "./app-v2.component";

const generateClassName = createGenerateClassName({ seed: "workspace-header-class" });
const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <WorkspaceHeaderContextProvider>
          <SnackbarContextProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  element={<HandleRedirectsV2Component />}
                  path="/s/evaluation/:initId/product/:initProdId/documents"
                />
                <Route element={<AppV2Component />} path="/s/evaluation/:initId/product/:initProdId/:tab" />
                <Route element={<HandleRedirectsV2Component />} path="*" />
              </Routes>
            </BrowserRouter>
          </SnackbarContextProvider>
        </WorkspaceHeaderContextProvider>
      </StylesProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
