import { StarV2Icon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { useTranslation } from "react-i18next";
import { TextLink } from "../../../textLink";
import { TypographyComponent } from "../../../typography/typography.component";
import styles from "./peer-insights-section.styles.module.scss";

type PeerInsightsSectionProps = { piMarketSeoName: string; templateName: string };
const PeerInsightsSectionComponent = ({ piMarketSeoName, templateName }: PeerInsightsSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <TypographyComponent color="berry" rootClassName={styles.header} styling="h5">
        <StarV2Icon fillPath={colorPalette.interactions.berry.hex} height="18px" width="19px" />
        {t("Peer Insights")}
      </TypographyComponent>
      <TypographyComponent color="quartz" rootClassName={styles.description} styling="p2">
        {t(
          "Peer Insights is Gartner's peer-driven platform where thousands of enterprise leaders connect " +
            "with each other to help guide technology and business decisions. You can access peer technology " +
            "rating and reviews by verified users, network, share knowledge, get advice, and stay on top of " +
            "current trends in this technology space."
        )}
      </TypographyComponent>
      <div className={styles.divider} />
      <TypographyComponent color="stone" element="p" rootClassName={styles.learnMore}>
        {t("Learn more")}
      </TypographyComponent>
      <div>
        <TextLink
          href={`https://www.gartner.com/reviews/market/${piMarketSeoName}?ref=buysmartpi`}
          rootClassName={styles.linkText}
          target="_blank"
          text={`${t("Peer Insights for")} ${templateName}`}
          variant="dark"
        />
      </div>
    </div>
  );
};

export default PeerInsightsSectionComponent;
