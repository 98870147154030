import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { Button, Loader, ProductLogo, TypographyComponent } from "gx-npm-ui";
import { getAsyncRequest } from "gx-npm-lib";
import styles from "./onboarding.styles.module.scss";

const OnboardingComponent = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const [productName, setProductName] = useState("");

  const [productImageLoc, setProductImageLoc] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [loading, setLoading] = useState(false);

  const pid = searchParams.get("pid");
  const tid = searchParams.get("tid");

  useEffect(() => {
    if (!tid) {
      navigateToUrl("/s/home");
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const response = await getAsyncRequest(
          `/api/v2/data/user-home/onboarding?templateId=${tid}${pid ? `&productId=${pid}` : ""}`
        );
        if (response.status !== 200 || !response.data?.data) {
          throw new Error();
        }
        setProductName(response.data.data.productName);
        setProductImageLoc(response.data.data.productImageLoc);
        setTemplateName(response.data.data.templateName);
      } catch (err) {
        navigateToUrl("/s/home");
      }
      setLoading(false);
    })();
  }, [pid, tid, setLoading]);

  const handleClose = () => {
    navigateToUrl("/s/home");
  };

  return (
    <div aria-label={t("onboarding")} className={styles.container}>
      <div className={styles.contentContainer}>
        {loading && <Loader size={100} />}
        <div className={styles.headingContainer}>
          <TypographyComponent color="carbon" styling="h1">
            {t("Welcome to your")}
          </TypographyComponent>
          <TypographyComponent color="yam" styling="h1">
            {t("BuySmart")}
          </TypographyComponent>
          <TypographyComponent color="carbon" styling="h1">
            {t("evaluation")}
          </TypographyComponent>
        </div>
        <TypographyComponent color="iron" styling="p1">
          {t(
            "A BuySmart evaluation will allow you to manage all of your evaluation activities involving this market in one convenient location."
          )}
        </TypographyComponent>
        <div className={styles.divider} />
        <div className={styles.marketInterestContainer}>
          <TypographyComponent boldness="medium" color="iron" rootClassName={styles.marketInterest} styling="p3">
            {t("Market of interest")}
          </TypographyComponent>
          <TypographyComponent color="carbon" styling="p1">
            {templateName}
          </TypographyComponent>
        </div>
        {productName && (
          <div className={styles.vendorInterestContainer}>
            <TypographyComponent boldness="medium" color="iron" rootClassName={styles.vendorInterest} styling="p3">
              {t("Vendor of interest")}
            </TypographyComponent>
            <div className={styles.productContainer}>
              <ProductLogo
                imageHeight="40"
                imageWidth="40"
                logo={productImageLoc}
                name={productName}
                rootClassName={styles.productLogo}
              />
              <TypographyComponent boldness="medium" color="carbon" styling="p1">
                {productName}
              </TypographyComponent>
            </div>
          </div>
        )}
        <div className={styles.actionContainer}>
          <Button rootClassName={classNames("btn-primary", styles.btnGetStarted)}>{t("Let's get started")}</Button>
          <Button onClick={handleClose}>{t("I'm not ready yet")}</Button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingComponent;
