// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l3s3IBdSHZzhNBzGkUQv .v0IlTyuOa3rVpEr5_jtk{align-items:center;display:flex;gap:15px;padding-bottom:12px}.l3s3IBdSHZzhNBzGkUQv .W78Z0zmWpPuJncJibnH8{padding-bottom:32px;width:400px}.l3s3IBdSHZzhNBzGkUQv .hmX1HsAH3CSsR3BC8P2p{background-color:var(--color-white);height:1px;margin-bottom:12px;opacity:.2}.l3s3IBdSHZzhNBzGkUQv .Nh0PX4mRlqq4GIoQc04A{margin-bottom:16px}.l3s3IBdSHZzhNBzGkUQv .zNUExEaO5uLKk0DJk2X9{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/peer-insights-section/peer-insights-section.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,mBAAA,CACA,WAAA,CAGF,4CACE,mCAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".root {\n  & .header {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .description {\n    padding-bottom: 32px;\n    width: 400px;\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 1px;\n    margin-bottom: 12px;\n    opacity: 0.2;\n  }\n\n  & .learnMore {\n    margin-bottom: 16px;\n  }\n\n  & .linkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `l3s3IBdSHZzhNBzGkUQv`,
	"header": `v0IlTyuOa3rVpEr5_jtk`,
	"description": `W78Z0zmWpPuJncJibnH8`,
	"divider": `hmX1HsAH3CSsR3BC8P2p`,
	"learnMore": `Nh0PX4mRlqq4GIoQc04A`,
	"linkText": `zNUExEaO5uLKk0DJk2X9`
};
export default ___CSS_LOADER_EXPORT___;
