import { Loader, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import styles from "./upload-processing.styles.module.scss";

const UploadProcessingComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.processingWrapper}>
      <Loader rootClassName={styles.processingLoader} />
      <TypographyComponent
        boldness="medium"
        color="carbon"
        element="h3"
        styling="p3"
        rootClassName={styles.processingText}
      >
        {t("Uploading your documents")}
      </TypographyComponent>
    </div>
  );
};

export default UploadProcessingComponent;
