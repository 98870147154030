import { createContext, ReactNode, useState } from "react";
import { AppContextValue } from "./app.types";

const AppContext = createContext<AppContextValue>({} as AppContextValue);

const AppContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [categoryDataList, setCategoryDataList] = useState([]);
  const [categoryDataTemplateCount, setCategoryDataTemplateCount] = useState(0);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [templateData, setTemplateData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isSearchData, setIsSearchData] = useState(false);
  const [selectedCategoryTemplates, setSelectedCategoryTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtherTemplateDisabled, setIsOtherTemplateDisabled] = useState(false);

  const contextValue = {
    isSearchData,
    categoryDataList,
    isLoading,
    categoryId,
    isError,
    isLoadingCategories,
    isLoadingTemplates,
    isSearching,
    searchTerm,
    templateData,
    categoryName,
    selectedCategoryTemplates,
    isOtherTemplateDisabled,
    categoryDataTemplateCount,
    setIsOtherTemplateDisabled,
    setSelectedCategoryTemplates,
    setCategoryId,
    setIsSearchData,
    setIsError,
    setIsLoadingCategories,
    setIsLoadingTemplates,
    setCategoryName,
    setIsSearching,
    setTemplateData,
    setSearchTerm,
    setIsLoading,
    setCategoryDataList,
    setCategoryDataTemplateCount,
  };

  return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
