import { Fade } from "@material-ui/core";
import classnames from "classnames";
import { FreeTrialRole, getAsyncRequest, useCaptureEventsV2, useUserState } from "gx-npm-lib";
import { FreeTrialBannerComponent, GartnerFooter, Loader, SnackbarBanner } from "gx-npm-ui";
import { useContext, useEffect } from "react";
import { navigateToUrl } from "single-spa";
import EvaluationsComponent from "./sections/evaluations/evaluations.component";
import FTUXBanner from "./sections/ftux-banner/ftux-banner.component";
import Header from "./sections/header/header.component";
import ResearchHistoryComponent from "./sections/research-history/research-history.component";
import Tasks from "./sections/tasks/tasks.component";
import { ClientEvent } from "./app.constants";
import { HomeAppContext } from "./app.context";
import styles from "./app.styles.module.scss";
import { useSearchParams } from "react-router-dom";

const App = () => {
  const [searchParams] = useSearchParams();
  const captureEvents = useCaptureEventsV2();
  const {
    initiatives,
    isLoading,
    saveDataErrorStatus,
    showSnackBar,
    snackbarMessage,
    setChecklistTasks,
    setError,
    setInitiatives,
    setLoading,
    setSaveDataErrorStatus,
    setRecentProductViews,
    setRecentTemplateViews,
    setShowSnackBar,
  } = useContext(HomeAppContext);
  const { freeTrialDaysRemaining, freeTrialRole, hasInitiatives, hasTasks, hasUserState, isEntitled } = useUserState();

  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);

  useEffect(() => {
    if (hasUserState && hasTasks && !hasInitiatives && !isEntitled) {
      navigateToUrl("/s/tasks-dashboard");
    }
  }, [hasUserState, hasTasks, hasInitiatives, isEntitled]);

  useEffect(() => {
    const gxtmSource = searchParams.get("gxtm_source") || "";
    const gxtmType = searchParams.get("gxtm_type") || "";
    const gxtmPage = searchParams.get("gxtm_page") || "";
    captureEvents([{ eventType: ClientEvent.HOME_PAGE_VIEWED, metaData: { gxtmSource, gxtmType, gxtmPage } }]);
  }, [captureEvents, searchParams]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await getAsyncRequest("/api/v2/user/home");
        if (response.status !== 200 || !response.data?.data) {
          throw new Error();
        }
        setChecklistTasks(response.data.data.checklistTasks);
        setInitiatives(response.data.data.initiatives);
        setRecentProductViews(response.data.data.recentProductViews);
        setRecentTemplateViews(response.data.data.recentTemplateViews);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    })();
  }, [setError, setLoading, setChecklistTasks, setInitiatives, setRecentProductViews, setRecentTemplateViews]);

  return (
    <section>
      {isLoading && <Loader size={100} />}
      <div className={classnames(styles.overlay, !isLoading && styles.open)}>
        {isTrialUser && (
          <div className={styles.trailBanner}>
            <FreeTrialBannerComponent daysRemaining={freeTrialDaysRemaining} freeTrialRole={freeTrialRole} />
          </div>
        )}
        <div className={styles.container}>
          <Header />
          <div className={styles.bodyContainer}>
            <div className={styles.main}>
              <div className={classnames(styles.ftux, !initiatives.length && !isLoading && styles.show)}>
                <Fade in={!initiatives.length && !isLoading} timeout={1300}>
                  <div>
                    <FTUXBanner />
                  </div>
                </Fade>
              </div>
              <EvaluationsComponent />
              <Tasks />
            </div>
            <div className={styles.side}>
              <ResearchHistoryComponent />
            </div>
          </div>
          <SnackbarBanner
            isDefaultErrorMessage={true}
            isOpen={saveDataErrorStatus}
            setIsOpen={setSaveDataErrorStatus}
          />
          <SnackbarBanner isOpen={showSnackBar} message={snackbarMessage} setIsOpen={setShowSnackBar} type="SUCCESS" />
        </div>
        <GartnerFooter />
      </div>
    </section>
  );
};

export default App;
