import React, { Fragment, useLayoutEffect, useRef } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { categoryListTop, templateSelectionStyles as styles } from "./template-selection.style";
import TemplateCards from "./template-cards/template-cards.component";
import TemplateCardsTitle from "./template-cards-title/template-cards-title.component";
import TemplateFilter from "./template-filter/template-filter.component";

const useStyles = makeStyles(() => styles);
const TemplateSelection = () => {
  const classes = useStyles();
  const categoryList = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const updateSize = () => {
      const elem = categoryList?.current;
      (elem as unknown as HTMLElement).setAttribute("style", `height: ${window.innerHeight - categoryListTop}px`);
    };
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <Fragment>
      <div className={classNames("gx-category-list", classes.categoryList)} ref={categoryList}>
        <TemplateFilter />
      </div>
      <div className={classNames(classes.templateCardDisplay)}>
        <TemplateCardsTitle />
        <TemplateCards />
      </div>
    </Fragment>
  );
};
export default TemplateSelection;
