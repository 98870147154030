import React, { useState } from "react";
import classNames from "classnames";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core";
import { KebabDoubleIcon } from "gx-npm-icons";
import { IconButton } from "gx-npm-ui";
import { listItemStyles } from "./drag-drop-list.styles";

type DragDropListItemProps = {
  draggable?: boolean;
  id: string;
  index: number;
  renderItem: (newState: { hover: boolean; setEdit: (value: React.SetStateAction<boolean>) => void }) => void;
  rootClassName?: string | null;
  transparentDragIcon?: boolean;
};

const DragDropListItem = ({
  draggable = true,
  id = "",
  index = -1,
  renderItem,
  rootClassName = "",
  transparentDragIcon = true,
}: DragDropListItemProps) => {
  const [edit, setEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const classes = makeStyles(() => listItemStyles())();

  const renderList = (provided?: DraggableProvided) => {
    return (
      <li
        aria-label={"drag and drop item" + (edit ? " edit" : "")}
        className={classNames("gx-dnd-item", classes.listItem, edit && "edit", hover && "hover", rootClassName)}
        onBlur={() => setHover(false)}
        onFocus={() => setHover(true)}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...(provided && { ref: provided.innerRef, ...provided.draggableProps, ...provided.dragHandleProps })}
        tabIndex={-1}
      >
        {provided && (
          <div
            aria-label="drag and drop icon"
            className={classNames("gx-dnd-icon", classes.draggable)}
            onMouseDown={() => {
              setEdit(false);
            }}
            role="button"
            tabIndex={0}
          >
            {hover && (
              <IconButton ariaLabel={"drag and drop icon button"} transparentBackground={transparentDragIcon}>
                <KebabDoubleIcon title="drag" />
              </IconButton>
            )}
          </div>
        )}
        {renderItem({ hover, setEdit })}
        <div style={{ clear: "both" }} />
      </li>
    );
  };

  if (!draggable) {
    return renderList();
  } else {
    return (
      <Draggable key={id} draggableId={id.toString()} index={index}>
        {(provided: DraggableProvided) => renderList(provided)}
      </Draggable>
    );
  }
};

export default DragDropListItem;
