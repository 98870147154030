// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VH_9T2DcDxMUc53mPKoj .hiDQtbqy56bhXVNrgx6P{padding-bottom:32px}.VH_9T2DcDxMUc53mPKoj .qqrp70IlXWBjKNP3m6KB{background-color:var(--color-white);height:2px;margin-bottom:56px;opacity:.2}.VH_9T2DcDxMUc53mPKoj .P2RL9_5AqDgtsA5hY9Ag{padding-bottom:12px}.VH_9T2DcDxMUc53mPKoj .dW22eqDgsMUb4IqKxl3r{margin-bottom:12px;width:600px}.VH_9T2DcDxMUc53mPKoj .loLQcwF451fhLK6Y5ALg{display:flex;padding-top:34px}@media(max-width: 1239px){.VH_9T2DcDxMUc53mPKoj .loLQcwF451fhLK6Y5ALg{flex-wrap:wrap;gap:64px}}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/research-slide-in-v2.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,mBAAA,CAGF,4CACE,mCAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,mBAAA,CAGF,4CACE,kBAAA,CACA,WAAA,CAGF,4CACE,YAAA,CACA,gBAAA,CAEA,0BAJF,4CAKI,cAAA,CACA,QAAA,CAAA","sourcesContent":[".root {\n  & .primaryHeader {\n    padding-bottom: 32px;\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 2px;\n    margin-bottom: 56px;\n    opacity: 0.2;\n  }\n\n  & .secondaryHeader {\n    padding-bottom: 12px;\n  }\n\n  & .paragraphs {\n    margin-bottom: 12px;\n    width: 600px;\n  }\n\n  & .researchContent {\n    display: flex;\n    padding-top: 34px;\n\n    @media (max-width: 1239px) {\n      flex-wrap: wrap;\n      gap: 64px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `VH_9T2DcDxMUc53mPKoj`,
	"primaryHeader": `hiDQtbqy56bhXVNrgx6P`,
	"divider": `qqrp70IlXWBjKNP3m6KB`,
	"secondaryHeader": `P2RL9_5AqDgtsA5hY9Ag`,
	"paragraphs": `dW22eqDgsMUb4IqKxl3r`,
	"researchContent": `loLQcwF451fhLK6Y5ALg`
};
export default ___CSS_LOADER_EXPORT___;
