import { weightPalette } from "gx-npm-common-styles";

const checklistTextAreaStyles = {
  root: {
    "&.checklist-title-field": {
      display: "inline-flex",
      width: "25%",
    },
    "&.checklist-title-field .gx-checklist-text-area": {
      width: "100%",
      marginLeft: 2,
      marginRight: 2,
    },
    "&.checklist-title-field .gx-checklist-text-area textarea": {
      fontVariationSettings: weightPalette.medium.variation,
      fontWeight: weightPalette.medium.amount,
    },
    "&.checklist-desc-field": {
      display: "inline-flex",
      flex: 1,
    },
    "&.checklist-desc-field .gx-checklist-text-area": {
      width: "100%",
      marginLeft: 1,
      marginRight: 2,
      "&:hover": {
        marginRight: 1,
      },
    },
    "&&.checklist-desc-field .gx-checklist-text-area textarea": {
      fontVariationSettings: weightPalette.medium.variation,
      fontWeight: weightPalette.medium.amount,
      "&:placeholder-shown": {
        fontVariationSettings: weightPalette.regular.variation,
        fontWeight: weightPalette.regular.amount,
      },
    },
    "&.checklist-title-field .gx-checklist-text-area textarea, &.checklist-desc-field .gx-checklist-text-area textarea":
      {
        fontVariationSettings: weightPalette.medium.variation,
        fontWeight: weightPalette.medium.amount,
        marginBottom: 0,
        marginTop: 1,
        marginRight: 5,
        padding: "13px 12px 12px 12px",
        width: "100%",
        backgroundColor: "transparent",
        "&:hover": {
          paddingLeft: 12,
        },
      },
    "& .gx-checklist-text-area.ff3438 textarea": {
      marginTop: 3,
      padding: 12,
    },
  },
};

export default checklistTextAreaStyles;
