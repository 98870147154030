import { colorPalette } from "gx-npm-common-styles";

const additionalVendorV2Styles = {
  root: {
    display: "flex",
    marginTop: "28px",
    "& $addBtnLogo": {
      marginRight: "4px",
      padding: "unset",
      "&:hover": {
        backgroundColor: "unset",
      },
    },
    "& .gx-p": {
      alignItems: "center",
      backgroundColor: colorPalette.neutrals.quartz.hex,
      borderRadius: "400px",
      color: colorPalette.neutrals.coal.hex,
      display: "inline-flex",
      marginRight: "13.5px",
      padding: "4px 16px",
    },
  },
  additionalCount: {
    marginRight: 10,
    backgroundColor: colorPalette.neutrals.quartz.hex,
    padding: "5px 16px",
    borderRadius: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colorPalette.neutrals.coal.hex,
  },
  addBtnLogo: {
    alignItems: "center",
    backgroundColor: "unset",
    border: "unset",
    cursor: "pointer",
    display: "inline-flex",
  },
  tooltip: {
    backgroundColor: colorPalette.neutrals.iron.hex,
    borderRadius: "0px",
    fontSize: "12px",
    padding: "12px 16px",
    "& .gx-p": {
      color: colorPalette.basic.white.hex,
    },
  },
  tooltipArrow: {
    backgroundColor: colorPalette.neutrals.iron.hex,
  },
};

export { additionalVendorV2Styles };
