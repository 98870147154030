// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClIZrxVlcJUIHi4o_2wv.MuiTooltip-arrow{left:60px !important}.vXTQF_NZCGberdZfcfnz{display:flex;cursor:pointer;gap:12px;max-height:48px;overflow:hidden}.u2X33sAl3YufmbtOYYUp:hover{color:var(--color-interactions-hover-cta);background-color:var(--color-interactions-mild-berry);border-bottom:var(--color-interactions-hover-cta)}.VkVAZuila2pP2JInnCQA:hover,.VkVAZuila2pP2JInnCQA:focus{background-color:rgba(0,0,0,0) !important;color:var(--color-interactions-hover-cta)}.qMbJb6UyZSwZ5I5U14Gz{height:58px}.SJk09JHprLhjVvzj2f59{display:flex;position:absolute;bottom:-1px;left:0;right:0;margin-left:auto;margin-right:auto}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/evaluation-scorecard/scorecard-products/scorecard-product-head/scorecard-product-head.module.scss"],"names":[],"mappings":"AACE,uCACE,oBAAA,CAIJ,sBACE,YAAA,CACA,cAAA,CACA,QAAA,CACA,eAAA,CACA,eAAA,CAIA,4BACE,yCAAA,CACA,qDAAA,CACA,iDAAA,CAIJ,wDACE,yCAAA,CACA,yCAAA,CAGF,sBACE,WAAA,CAGF,sBACE,YAAA,CACA,iBAAA,CACA,WAAA,CACA,MAAA,CACA,OAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".toolTip {\n  &:global(.MuiTooltip-arrow) {\n    left: 60px !important;\n  }\n}\n\n.vendorNameLogo {\n  display: flex;\n  cursor: pointer;\n  gap: 12px;\n  max-height: 48px;\n  overflow: hidden;\n}\n\n.paraVendorName {\n  &:hover {\n    color: var(--color-interactions-hover-cta);\n    background-color: var(--color-interactions-mild-berry);\n    border-bottom: var(--color-interactions-hover-cta);\n  }\n}\n\n.viewVendor:hover, .viewVendor:focus {\n  background-color: transparent !important;\n  color: var(--color-interactions-hover-cta);\n}\n\n.headerWrapper {\n  height: 58px;\n}\n\n.headerButtons {\n  display: flex;\n  position: absolute;\n  bottom: -1px;\n  left: 0;\n  right: 0;\n  margin-left: auto;\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolTip": `ClIZrxVlcJUIHi4o_2wv`,
	"vendorNameLogo": `vXTQF_NZCGberdZfcfnz`,
	"paraVendorName": `u2X33sAl3YufmbtOYYUp`,
	"viewVendor": `VkVAZuila2pP2JInnCQA`,
	"headerWrapper": `qMbJb6UyZSwZ5I5U14Gz`,
	"headerButtons": `SJk09JHprLhjVvzj2f59`
};
export default ___CSS_LOADER_EXPORT___;
