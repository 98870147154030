import { TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import ReadOnlyEyeV2Icon from "./read-only-eye-v2.icon";
import styles from "./read-only-badge-v2.styles.module.scss";

const ReadOnlyBadgeV2Component = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.badgeWrapper}>
      <ReadOnlyEyeV2Icon />
      <TypographyComponent boldness="medium" color="iron" element="span" rootClassName={styles.badgeText} styling="p3">
        {t("View only")}
      </TypographyComponent>
    </div>
  );
};

export default ReadOnlyBadgeV2Component;
