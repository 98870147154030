import { ClickAwayListener, makeStyles, Popper } from "@material-ui/core";
import { CloseIcon } from "gx-npm-icons";
import { ProductLogo, TypographyComponent } from "gx-npm-ui";
import React, { KeyboardEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { TopProduct } from "../../../../app.types";
import { ProductOverviewContext } from "../../../../context/product-overview.context";
import { featureDescriptionPopperStyles as styles } from "./feature-description-popper.styles";

type FeatureDescriptionPopperProps = {
  anchorEl: HTMLElement | null;
  description: string;
  isOpen: boolean;
  name: string;
  onClose: () => void;
  topProducts: TopProduct[];
};
const useStyles = makeStyles(() => styles);
const FeatureDescriptionPopper = ({
  anchorEl,
  description,
  isOpen,
  name,
  onClose,
  topProducts,
}: FeatureDescriptionPopperProps) => {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const { initId, isFromProductProfile, templateId } = useContext(ProductOverviewContext);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleRef = (ref: HTMLElement | null) => {
    setArrowRef(ref);
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onClose();
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    singleSpa.navigateToUrl(url);
  };
  return (
    <Popper
      anchorEl={anchorEl}
      className={classes.root}
      modifiers={{
        arrow: { enabled: true, element: arrowRef },
        flip: { enabled: true },
        preventOverflow: { enabled: true, boundariesElement: "scrollParent" },
      }}
      open={isOpen}
    >
      <ClickAwayListener onClickAway={onClose}>
        <section className={classes.sectionWrapper}>
          <div className={classes.nameWrapper}>
            <TypographyComponent color={"carbon"} styling={"p3"} boldness={"medium"}>
              {name}
            </TypographyComponent>
            <div
              aria-label={t("Close")}
              className={classes.closeBtn}
              onClick={onClose}
              onKeyDown={handleKeyDown}
              role="button"
              tabIndex={0}
            >
              <CloseIcon />
            </div>
          </div>
          <div className={classes.descriptionWrapper}>
            <TypographyComponent color={"coal"} styling={"p4"}>
              {description}
            </TypographyComponent>
          </div>
          <TypographyComponent styling={"p4"} color={"iron"} rootClassName={classes.topProductsTitle}>
            {t("Top ranked vendor(s) in")}
            {` ${name}`}
          </TypographyComponent>
          <ul className={classes.topProductList}>
            {topProducts.map((product) => {
              const url = isFromProductProfile
                ? `/s/evaluation/${initId}/product/${product.initProdId}/profile`
                : `/s/market/${templateId}/product/${product.id}`;

              return (
                <li className={classes.topProductItem} key={product.id}>
                  <a
                    aria-label={"product-link"}
                    className={classes.productLink}
                    href={url}
                    onClick={(event) => handleClick(event, url)}
                  >
                    <ProductLogo
                      imageHeight="24px"
                      imageWidth="24px"
                      logo={product.imageLoc}
                      name={product.name}
                      rootClassName={classes.productLogo}
                    />
                    <TypographyComponent
                      color={"carbon"}
                      boldness={"medium"}
                      rootClassName={classes.productName}
                      styling={"p3"}
                    >
                      {product.name}
                    </TypographyComponent>
                  </a>
                </li>
              );
            })}
          </ul>
          <div className={classes.listFade} />
        </section>
      </ClickAwayListener>
      <span className="gx-popper-arrow" ref={handleRef} />
    </Popper>
  );
};

export default FeatureDescriptionPopper;
