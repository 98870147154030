import { makeStyles } from "@material-ui/core";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { SlideInOverlay, SnackbarBanner } from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { EvaluationStateContext } from "./context";
import { setTemplateCategory } from "./context/actions/templateActions";
import { NAV_INITIATIVE } from "./lib/constants";
import Template from "./sections/template";
import { TemplateDetails } from "./types";
import { ClientEvent } from "./app.constants";
import styles from "./styles";

type TemplatePreviewProps = { isDrawer?: boolean; isEntitled?: boolean; templateDetails: TemplateDetails };
const useStyles = makeStyles(() => styles);
const App = (props: TemplatePreviewProps) => {
  const { t } = useTranslation();
  const [showErrorSnack, setShowErrorSnack] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [state, dispatch] = useContext(EvaluationStateContext);
  const { marketId, productId } = useParams();
  const [searchParams] = useSearchParams();
  const captureEvents = useCaptureEventsV2();

  if (!(props.templateDetails?.templateId > 0)) {
    props.templateDetails.templateId = parseInt(marketId as string);
  }

  useEffect(() => {
    if (props.templateDetails?.category) {
      setTemplateCategory(dispatch, { category: props.templateDetails.category });
    }
  }, [props.templateDetails?.category, dispatch]);

  useEffect(() => {
    const isError =
      !!state.template.overview.error ||
      !!state.template.requirements.error ||
      !!state.template.checklist.error ||
      !!state.template.products.error ||
      !!state.template.scorecard.error ||
      !!state.template.createInitiative.error;
    setShowErrorSnack(isError);
  }, [
    state.template.overview.error,
    state.template.requirements.error,
    state.template.checklist.error,
    state.template.products.error,
    state.template.scorecard.error,
    state.template.createInitiative.error,
  ]);

  useEffect(() => {
    const gxtmSource = searchParams.get("gxtm_source") || "";
    const gxtmType = searchParams.get("gxtm_type") || "";
    const gxtmPage = searchParams.get("gxtm_page") || "";
    captureEvents([
      { eventType: ClientEvent.USER_CLICKED_TEMPLATE_PREVIEW, metaData: { gxtmSource, gxtmType, gxtmPage } },
    ]);
  }, [captureEvents, searchParams]);

  const onClose = () => {
    setDrawerOpen(false);
    const url = productId ? `/s/market/${marketId}/product/${productId}` : `/s/market/${marketId}`;
    setTimeout(() => singleSpa.navigateToUrl(url), 1000);
  };

  useEffect(() => {
    if (state.template.createInitiative.data?.initiativeId) {
      const id = state.template.createInitiative.data.initiativeId;
      singleSpa.navigateToUrl(NAV_INITIATIVE + id + "/overview");
    }
  }, [state.template.createInitiative.data]);

  useEffect(() => {
    setDrawerOpen(true);
  }, []);

  const classes = useStyles();
  const renderContent = (
    <div className={classes.container}>
      <Template
        canUseTemplate={props.templateDetails?.canUseTemplate || props.isEntitled}
        categoryId={props.templateDetails?.categoryId}
        categoryName={props.templateDetails?.category}
        isSearchData={props.templateDetails?.isSearchData}
        searchResultsCount={props.templateDetails?.searchResultsCount}
        searchTerm={props.templateDetails?.searchTerm}
        templateId={props.templateDetails?.templateId}
        templateName={props.templateDetails?.templateName}
        userInitCount={props.templateDetails?.userInitCount}
      />
    </div>
  );
  return (
    <Fragment>
      <SnackbarBanner
        autoHideDuration={6000}
        isOpen={showErrorSnack}
        message={t("Oops... something went wrong. Please try again.")}
        type="ERROR"
        setIsOpen={() => setShowErrorSnack(false)}
      />
      <SlideInOverlay rootClassName={classes.root} onClose={onClose} isOpen={isDrawerOpen}>
        {renderContent}
      </SlideInOverlay>
    </Fragment>
  );
};

export default App;
