import React, { useContext, useRef } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import FilterLoader from "./filter-loader/filter-loader.component";
import { templateFilterStyles as styles } from "./template-filter.style";
import TemplateSearchInput from "./search/template-search-input/template-search-input.component";
import { AppContext } from "../../../app.context";
import FilterButton from "./filter-button/filter-button.component";
import FilterFooter from "./filter-footer/filter-footer.component";

const useStyles = makeStyles(() => styles);
const TemplatesFilter = () => {
  const filterFooterRef = useRef<HTMLDivElement>(null);
  const filterHeaderRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const additionalContainerStyles = { height: "unset" };
  const { categoryDataList, categoryDataTemplateCount, isLoadingCategories, isSearchData, categoryId } =
    useContext(AppContext);
  const finalCategories = [
    <FilterButton
      count={categoryDataTemplateCount || 0}
      id={0}
      isSelected={!categoryId && !!categoryDataTemplateCount && !isSearchData}
      key="all"
      name="All"
    />,
  ];

  //display category with count > 0
  const filterArr = categoryDataList?.filter((item) => item.templateCount > 0);
  filterArr?.forEach((filterData, index) => {
    if (filterData) {
      finalCategories.push(
        <FilterButton
          count={filterData.templateCount}
          id={filterData.id}
          iconLoc={filterData?.iconLoc}
          key={filterData.id || index}
          isSelected={categoryId === filterData.id && !isSearchData}
          name={filterData.name}
        />
      );
    }
  });

  const filterFooterHeight = filterFooterRef?.current?.offsetHeight || 0;
  const filterHeaderHeight = filterHeaderRef?.current?.offsetHeight || 0;

  additionalContainerStyles.height =
    filterFooterHeight || filterHeaderHeight
      ? `calc(100% - ${filterFooterHeight + (filterHeaderHeight + 40)}px)`
      : additionalContainerStyles.height;

  return (
    <React.Fragment>
      <TemplateSearchInput />
      <section
        className={classNames(classes.filterContainer, "gx-template-hub-filter-body-sticky")}
        style={additionalContainerStyles}
      >
        {!isLoadingCategories && finalCategories}
        {isLoadingCategories && <FilterLoader />}
      </section>
      <FilterFooter forwardedRef={filterFooterRef} />
    </React.Fragment>
  );
};

export default TemplatesFilter;
